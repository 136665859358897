<div class="container" *ngIf="this.data.operatorKey === this.OPERATOR.GHG || this.data.operatorKey === this.OPERATOR.LBAND">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para">
         Please enter the sitename (s).
      </p>

      <img style="margin-top: -36px; margin-left: 215px; padding-right: 3px;" width="20px" height="20" [src]="tooltipImagePath"
      matTooltip="A site name is a distinct name such as a known landfill name or a mine name."/>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="formGroup">
      <div class="formDetails" style="margin: 0 0 20px 0; width: 100%;">
        <label class="siteName">Site Id</label>
        <label class="siteId">Site Name</label>
        <div class="left scrollbar" id="scrollcustom">
          <div *ngFor="let point of pointArray; let i = index">
            <div class="input-container" fxLayout="row" fxLayoutGap="20px">
              <input type="text" [value]="i+1" readonly />
              <div style="display: flex;align-items: center;">
                <input type="name" placeholder="Your site name" [id]="'sitename-' + i"
                [formControlName]="'sitename-' + i"
                required (keydown)="Space($event)" maxlength="25" />
                <span style="padding-left: 10px;" [matTooltipClass]="'target-tooltip'"               
                matTooltip="{{point}}">
                  <img width="18px" height="18" [src]="tooltipImagePath">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;" *ngIf="this.data.operatorKey === this.OPERATOR.LBAND && recurrent">
        <p class="para" style="padding-top: 15px;">
          Please enter the number of collections required for the time of interest (TOI).
        </p>

        <mat-form-field appearance="outline" class="options">
          <input matInput formControlName="daysCount" placeholder="Number of collections" class="matOption matOptionNumber" type="number" #inputField/>
        </mat-form-field>
        <div class="error-message" style=" margin-top: 15px !important;margin-left: -63px;" *ngIf="fetchError && fetchMessage">
           {{this.fetchMessage}}
        </div>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button [disabled]="isSaveButtonDisabled() || fetchError" class="submit" (click)="save()">Save</button>
      </div>
    </form>
  </div>
</div>


<div class="container" *ngIf="this.data.operatorKey === this.OPERATOR.CAPELLA && !userCount && this.data?.type != 'imageWidth'">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para">
         Please enter the collection start time and end time for Capella SAR.
      </p>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical" fxLayout="column" fxLayoutGap="10px" [formGroup]="capellaFormGroup">
      <div class="formDetails" style="margin: 0 0 20px 0; width: 100%;">
        <mat-label class="card-label">Collection Time (24hrs)</mat-label>
        <div fxLayout="row" fxLayoutGap="11px">
          <mat-form-field appearance="outline" class="option">
            <input matInput formControlName="collectionStart" placeholder="0 hrs" class="matOptionDate" type="number" #inputField oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 2);"/>
          </mat-form-field>
          <span class="max-min" style="padding-top: 20px;">-</span>
          <mat-form-field appearance="outline" class="option">
            <input matInput formControlName="collectionEnd" placeholder="24 hrs" class="matOptionDate" type="number"
            [min]="firstInput + 1" #inputField (focus)="onInputFocus('end')" (blur)="onInputBlur('end')" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 2);"/>
          </mat-form-field>
        </div>
        <div class="error-container">
          <span class="error-message" *ngIf="capellaFormGroup.get('collectionStart').hasError('min') || capellaFormGroup.get('collectionStart').hasError('max') || capellaFormGroup.get('collectionStart').hasError('max') || this.maxValue">
            Collection start and end time is between 0 to 24
          </span>
          <span class="error-message" *ngIf="focusedInput === 'end' && error && (!capellaFormGroup.get('collectionStart').hasError('min') && !capellaFormGroup.get('collectionStart').hasError('max') && !capellaFormGroup.get('collectionEnd').hasError('max') && !this.maxValue)">
            Collection start time should not be greater than end time
          </span>
          <!-- <span class="error-message" *ngIf="this.wholeNumber && (!capellaFormGroup.get('collectionStart').hasError('min') && !capellaFormGroup.get('collectionStart').hasError('max') && !capellaFormGroup.get('collectionEnd').hasError('max') && !this.maxValue) && !error">
            Collection Time should be a whole number
          </span> -->
        </div>
      </div>
    </form>
  </div>

  <div class="btns" fxLayoutAlign="center center" fxLyout="row">
    <button class="custom-button" (click)="onNoClick()">Close</button>
    <button mat-stroked-button [disabled]="capellaFormGroup.invalid || this.maxValue" 
    class="submit" (click)="submit()">Save</button>
  </div>
</div>

<div class="container" *ngIf="this.data.operatorKey === this.OPERATOR.SATVU">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para">
         Please check the mode of capture.
      </p>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="satVuFormGroup">
      <div class="formDetails" style="margin: 0 0 20px 0; width: 100%;">
    
        <span>
          <mat-checkbox formControlName="dayMode" [(ngModel)]="dayMode">
            <div style="display: flex">
              <div>
                <span>Day</span>
              </div>
            </div>
          </mat-checkbox>
          <mat-checkbox style="margin-left: 30px;" formControlName="nightMode" [(ngModel)]="nightMode">
            <div style="display: flex;">
              <div>
                <span>Night</span>
              </div>
            </div>
          </mat-checkbox>
        </span>
      </div>
      <!-- Display validation message -->
      <div *ngIf="satVuFormGroup.get('dataCaptureMode')?.hasError('atLeastOneCheckboxSelected')">
        At least one checkbox must be selected.
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button [disabled]="satVuFormGroup.invalid"  class="submit" (click)="satVuSave()">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="this.userCount">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para" *ngIf="this.data.operatorKey === this.OPERATOR.CAPELLA || this.data.operatorKey === this.OPERATOR.GOKTURK">
        The cost of a tasking is dependent on the number of organisations having access to the tasked data.<br>
        Please choose one from the drop-down below. 
      </p>
      <p class="para" *ngIf="this.data.operatorKey != this.OPERATOR.CAPELLA && this.data.operatorKey != this.OPERATOR.GOKTURK">
        The cost of a tasking is dependent on the number of users having access to the tasked data.<br>
        Please choose one from the drop-down below. 
      </p>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="usersFormGroup">
      <div class="details" style="margin-bottom: 0 !important;">
        <mat-form-field appearance="outline" style="width: 345px;">
          <mat-select [disableOptionCentering]="true" panelClass="matopenpurposes" 
          [placeholder]="((this.data.operatorKey === this.OPERATOR.CAPELLA || this.data.operatorKey === this.OPERATOR.GOKTURK)) ? 'Number of organisations' : 'Number of users'"
            formControlName="users">
            <mat-select-trigger>{{selectedValue}}</mat-select-trigger>
            <mat-option *ngFor="let option of noOfUsers" [value]="option" (click)="selectValue(option)">
              <span style="display: flex;justify-content: space-between;">
                <span>
                  {{option.value}}
                </span>
                <span>
                  {{option.key}}x
                </span>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button class="submit" (click)="selectUsers()"
          [disabled]="!usersFormGroup.value.users">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="this.data.operatorKey === this.OPERATOR.SPIRE">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para">
        <span *ngIf="this.data?.hideTooltip">The cost of this sensor</span>
        <span *ngIf="!this.data?.hideTooltip">The cost of {{data?.selectedRow.displayName }} {{data?.selectedRow.sensor}}</span>
        tasking depends on the number of refreshes and
        historical fetches. Please enter a number per your requirement.
      </p>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="refreshFormGroup">
      <div class="details" style="margin-bottom: 0 !important;">
        <div style="display: flex; justify-content: center; align-items: center;">
          <mat-form-field appearance="outline" class="options">
            <input matInput formControlName="refreshCount" placeholder="Number of refreshes" class="matOption matOptionNumber" type="number" #inputField/>
          </mat-form-field>
        </div>
        <div style="display: flex; align-items: center; margin-top: 25; justify-content: center;">
          <span>
            <mat-checkbox
            color="primary"
            [checked]="isHistoricalChecked"
            color="primary"
            (change)="onCheckboxChange($event)"
            >
            </mat-checkbox>
          </span>
          <span class="para" style="margin-left: 10px">Add historical data</span>
          <span *ngIf="!this.data?.hideTooltip">
            <img
            style="margin-left: 10px; cursor: pointer;"
            width="14px"
            [src]="tooltipImagePath"
            [matTooltip]="'Cost of historical data = ' + convertCostToPoints(25) + ' per fetch'"
          />
          </span>
        </div>

        <div *ngIf="isHistoricalChecked" style="display: flex; justify-content: center; align-items: center;padding-top: 17px;">
          <mat-form-field appearance="outline" class="options">
            <input matInput formControlName="fetchCount" placeholder="Number of historical fetches" class="matOption matOptionNumber" type="number" #inputField/>
          </mat-form-field>
        </div>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button class="submit" (click)="selectRefreshes()"
          [disabled]="this.refreshError || (refreshFormGroup.value.refreshCount=== null) || (isHistoricalChecked && (!refreshFormGroup.value.fetchCount || fetchError))">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="this.data.operatorKey === this.OPERATOR.CLYDE">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="historyFormGroup"
    style="margin-top: -15px;">
      <div class="details" style="margin-bottom: 0 !important;">
        <div style="display: flex; align-items: center; margin-top: 25; justify-content: center;">
          <span>
            <mat-checkbox
            color="primary"
            [checked]="isHistoricalChecked"
            color="primary"
            (change)="onCheckboxChange($event)"
            >
            </mat-checkbox>
          </span>
          <span class="para" style="margin-left: 10px">Add historical data</span>
          <span *ngIf="!this.data?.hideTooltip">
            <img
            style="margin-left: 10px; cursor: pointer;"
            width="14px"
            [src]="tooltipImagePath"
            [matTooltip]="'Monthly subscription price = ' + convertCostToPoints(this.data.subscriptonAmount)"
          />
          </span>
        </div>

        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;" *ngIf="isHistoricalChecked">
          <p class="para" style="padding-top: 15px;">
            How far back do you need the historical data?
          </p>

          <mat-form-field appearance="outline" class="options">
            <input matInput formControlName="daysCount" placeholder="Number of days" class="matOption matOptionNumber" type="number" #inputField/>
          </mat-form-field>
        </div>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button class="submit" (click)="saveHistoricalDates()"
          [disabled]="(isHistoricalChecked && (!historyFormGroup.value.daysCount || fetchError))">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="this.data?.type === 'imageWidth'">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para">
        Please choose image width from the drop-down below. 
      </p>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="usersFormGroup">
      <div class="details" style="margin-bottom: 0 !important;">
        <mat-form-field appearance="outline" style="width: 345px;">
          <mat-select [disableOptionCentering]="true" panelClass="matopenpurposes" placeholder="Image width"
            formControlName="imageWidth">
            <mat-option *ngFor="let width of ImageWidth" [value]="width">
              <span style="display: flex;justify-content: space-between;">
                <span>
                  {{width}} km
                </span>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button class="submit" (click)="onSave()"
          [disabled]="!usersFormGroup.value.imageWidth">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="this.data.operatorKey === this.OPERATOR.WYVERN">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para">
        Please choose number of bands from the drop-down below. 
      </p>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="usersFormGroup">
      <div class="details" style="margin-bottom: 0 !important;">
        <mat-form-field appearance="outline" style="width: 345px;">
          <mat-select [disableOptionCentering]="true" panelClass="matopenpurposes" placeholder="Number of bands"
            formControlName="numOfBands">
            <mat-option *ngFor="let bands of data?.selectedRow?.bandOptions" [value]="bands">
              <span style="display: flex;justify-content: space-between;">
                <span>
                  {{bands}}
                </span>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button class="submit" (click)="saveBands()"
          [disabled]="!usersFormGroup.value.numOfBands">Save</button>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="this.data?.type === 'bandFilter'">
  <div class="card">
    <div class="task-head">
      <h4>Required!</h4>
    </div>

    <div class="icon">
      <p class="para">
        Which {{data?.sensorType}} band(s) sensors do you want to task?
      </p>
    </div>
  </div>
  <div class="popup-container">
    <form class="form-vertical1" fxLayout="column" fxLayoutGap="10px" [formGroup]="usersFormGroup">
      <div class="details" style="margin-bottom: 0 !important;">
        <mat-form-field appearance="outline" style="width: 345px;">
          <mat-select [disableOptionCentering]="true" panelClass="matopenpurposes" placeholder="Band type"
            formControlName="bandType">
            <mat-option *ngFor="let bands of data?.bands" [value]="bands">
              <span style="display: flex;justify-content: space-between;">
                <span>
                  {{bands}}
                </span>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onNoClick()">Close</button>
        <button mat-stroked-button class="submit" (click)="submitBands()"
          [disabled]="!usersFormGroup.value.bandType">Save</button>
      </div>
    </form>
  </div>
</div>