import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { StepperOrientation } from '@angular/material/stepper';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

import { AgreeComponent } from './agree/agree.component';
import { ActivatedRoute } from '@angular/router';
import { DebitsuccessComponent } from './debitsuccess/debitsuccess.component';
import { CancelpaylaterComponent } from './cancelpaylater/cancelpaylater.component';
import { DataService } from 'src/app/services/data.service';
import { PaymentNotificationComponent } from './payment-notification/payment-notification.component';
import { PopupService } from 'src/app/services/popup.service';
import { ThemeService } from 'src/app/services/theme.service';
import { WarningPopupComponent } from '../collections/warning-popup/warning-popup.component';
import { PAYPAL_FEE, STRIPE_FEE } from 'src/app/services/constants';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  isLoading = false;
  panelOpenState = false;
  payUrl: any; // <- YOUR URL
  @ViewChild('pg', { static: false }) public pgdiv: ElementRef;
  subscription: Subscription;
  currency = environment.payCurrency;
  currencySign = environment.payCurrencySign;
  currentUser: any;
  userDetails: any;
  taskId: any;
  taskparams: any;
  Area: any;
  TOI: any;
  SENSORS: any;
  TASKDATA: any;
  OPERATORS: any;
  success: boolean;
  successMsg: string;
  selectedSensors: any;
  selectedTask: any;
  totalCost: any;
  totalPoint: any;
  catlogCost: any;
  grandTotal: number = 0;
  total: any;
  payOption = 0;
  paynowSubmit = false;
  payOptions = this.apiService.PAYOPTIONS;
  orderId: string;
  isEditable = false;
  firstFormGroup = this._formBuilder.group({});
  secondFormGroup = this._formBuilder.group({});
  thirdFormGroup = this._formBuilder.group({});
  stepperOrientation: Observable<StepperOrientation>;
  pgContent: string;
  showEula = false;
  agreedTnC = false;
  operatorsEula: any = [];
  selectedEula = '';
  CARTDATA: any;
  account: boolean = false;
  clicked: boolean = false;
  debitButton: boolean = false;
  eulaClicked: boolean = false;
  taxType: string;
  storageOptions = [
    {
      label: 'Platform Storage',
      name: 'platformStorage',
      value: 0,
      checked: false,
    },
    { label: 'My Storage', name: 'userStorage', value: 1, checked: false },
  ];
  selectedItems: any[] = [];
  storage: boolean = true;
  selectedPaymentOption: string;
  demoView: boolean = false;
  eulaDateTime: any;
  methodSelected: boolean = true;
  operators: any;
  type: any;
  getSensorObj: any = [];
  isSufficient: number = 0;
  darkTheme: boolean;
  tooltipImagePath: string;
  isInIframe: any;
  totalTaskCost: number = 0;
  observationTasking: boolean = false;
  tippingSensorCost: number;
  totalPointWithGST: number = 0;
  totalCatlogPoint: number = 0;
  pointTotal: number = 0;
  point:number = 0;
  taskTotalNumber:number = 0;
  totalTasking:number = 0;
  valueAddedOptions:any [] = [];
  valueAddedOptionTotal:number = 0;
  valueAddedOptionPoint:number = 0;
  toggleStates: boolean[] = [];
  formattedvalueAddedOption:any []= [];
  onlineFees: number;
  dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  totalSRcost = 0;
  formattedSRDetails:any[] = [];
  valueAddedProducts: any[] = [];
  formattedIndices:any[] = [];
  totalSensorCost: number = 0;
  totalFeedCost: number = 0;
  garndTotalCost: any;
  GST: any;
  observationTaskCost: any;
  grand_point: any;
  gst_point: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private apiService: ApiService,
    private userService: UserService,
    public dialog: MatDialog,
    private dataService: DataService,
    private popupService: PopupService,
    private themeService: ThemeService,
    private utilsService: UtilsService
  ) {
    if (environment.demoView === 'true') {
      this.demoView = true;
    } else {
      this.demoView = false;
    }
    this.taskId = this.route.snapshot.params['id'];
    this.route.queryParams.subscribe((params) => {
      this.taskId = params['taskId'];
      this.type = params['type'];
    });
    localStorage.removeItem('taskData');
    localStorage.removeItem('storage');
    localStorage.removeItem('storageId');
    this.operators = this.dataService.getSelectedSensors();

    this.dataService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
    });

    this.isInIframe = this.themeService.isInIframe();
    this.toggleStates = new Array(this.valueAddedOptions.length).fill(false);
  }

  ngOnInit() {
    this.success = false;
    this.OPERATORS = [];
    this.authService.user.subscribe((user) => (this.currentUser = user));
    this.userService
      .personalInfo(this.currentUser?.id)
      .subscribe(async (res: any) => {
        this.currentUser = res;
        if (!res?.verified) {
          this.router.navigate(['login']);
          this.authService.logout();
        } else {
          this.userDetails = res;
          const today = new Date();
          var components = [
            today.getFullYear(),
            ('0' + (today.getMonth() + 1)).slice(-2),
            ('0' + today.getDate()).slice(-2),
            ('0' + today.getHours()).slice(-2),
            ('0' + today.getMinutes()).slice(-2),
            ('0' + today.getSeconds()).slice(-2),
          ];
          this.orderId = `EE${components.join('')}`;
          this.payUrl = undefined;
          this.getPaymentData();
        }
      });

    this.apiService.getAllocation().subscribe((res: any) => {
      if (res?.allocations[0]?.sensor.length > 0) {
        let tableData = res.allocations[0].sensor;
        const addedData = tableData.filter(data => data.isAllocated === true);
        const totalTasks = addedData.reduce((total, data) => total + data.totalTasks, 0);
        this.totalTasking = totalTasks;
      }
    });
  }

  balanceCheck() {
    let operatorData: any;

    this.authService.user.subscribe(user => this.currentUser = user);
    this.userService.personalInfo(this.currentUser?.id).subscribe(async (res: any) => {
      this.userDetails = res;
      let taskId = "";
      if (this.userDetails?.allocated) {
        (this.TASKDATA || []).forEach((task) => {
          operatorData = [];
          taskId = task.taskid;
          task.cartdata.forEach((operator, index) => {
            const operators = {
              operator: operator.name,
              sensor: operator.sensor,
              resolution: operator.resolution,
              cartAmount: operator.cost,
              latency: operator.latency,
              target: task?.target,
              taskNumber: operator?.taskNumber,
              originalResolution: operator?.originalResolution
            }
            operatorData.push(operators);
          });
        });

        this.apiService.allocationSufficientCheck(operatorData, taskId).subscribe((res: any) => {
          const allocatedData = res.filter(data => data.sufficient === false);
          this.isSufficient = allocatedData.length;
        });
      }
    });
  }

  OpenPopup(taskId: any) {
    const dialogRef = this.dialog.open(DebitsuccessComponent, {
      width: '50%',
      backdropClass: 'blurr',
      disableClose: true,
    });
    backdropClass: 'backdropBack'; // This is the "wanted" line
  }
  agreepopup() {
    this.dialog.open(AgreeComponent, { width: '100%', backdropClass: 'blurr' });
    backdropClass: 'backdropBack'; // This is the "wanted" line
  }
  debitAcount() {
    this.success = false;
    this.debitButton = true;
    let orderBody: any, taskBody: any;
    let updatedBalance = 0;
    this.TASKDATA.forEach((task) => {
      const orderdetail: any = [],
        operators: any = [];
      let taskcost = 0;
      task.cartdata.forEach((operator, index) => {
        const cloudcover = operator.cloudcover;
        orderdetail.push(
          JSON.stringify({
            operator: operator.name,
            sensor: operator.sensor,
            obfuscateName: operator?.obfuscateName,
            resolution: operator.resolution,
            latency: operator.latency,
            unit: operator.unit,
            cost: operator.cost,
            point: this.getPoints(operator.cost),
            otaskid: operator.taskid,
            uuid: operator?.uuid,
            type: operator?.type,
            operatorKey: operator?.operatorKey,
            operatorEmail: operator?.operatorEmail,
            eulaAcceptedTime: this.eulaDateTime,
            resolutionTooltip: operator?.resolutionTooltip,
            sensorType: operator?.sensortype,
            taskNumber: operator?.taskNumber,
            originalResolution: operator?.originalResolution,
            taskedArea: operator.isPoint? operator.minimumArea :(!operator?.isPoint && operator.isCalculatedAreaSmaller ? this.formatTaskArea(operator.areacalc,operator.minimumArea)  :operator.areacalc),
            gst_data: operator?.gst_data,
            total: operator?.total,
            tnqSensorType: operator?.tnqSensorType,
          })
        );
        taskcost += operator.cost;
        operators.push({
          operator: operator.name,
          taskid: operator.taskid,
          sensor: operator.sensor,
          resolution: operator.resolution,
          uuid: operator?.uuid,
          latency: operator.latency,
          type: operator?.type,
          operatorKey: operator?.operatorKey,
          operatorEmail: operator?.operatorEmail,
          resolutionTooltip: operator?.resolutionTooltip,
          opportunities: operator?.opportunities,
        });
        //  }
      });
      // const orderDetail = JSON.stringify(orderdetail);
      // taskcost = 1.0;
      orderBody = {
        id: this.orderId,
        customerid: task.customerid,
        taskid: task.taskid,
        orderamount: this.total,
        orderPoint: this.getPoints(this.total),
        currency: environment.payCurrency,
        orderdetail,
        payoption: 'Debit account',
        taskname: task.taskname,
        projectname: task.project,
        targetDelivery: this.selectedItems,
        eulaCheck: this.agreedTnC,
        eulaCheckTime: this.eulaDateTime,
        target: task.target,
        mlTasks: task.mlTasks,
        sensorISId: task.mlTasks.length > 0 ? task.cartdata[0].uuid : '',
        taskStrategy: task?.taskStrategy,
        totalTaskNumber: this.taskTotalNumber,
        valueAddedOption: this.formattedvalueAddedOption,
        indices: this.formattedIndices,
        super_resolution: this.formattedSRDetails,
        totalSensorCost: this.totalSensorCost,
        feed_cost: this.totalFeedCost,
        taskTotalNumber: this.taskTotalNumber,
        grand_gst_total: this.grandTotal,
        vap_cost: this.valueAddedOptionTotal,
        totalPointWithGST: this.totalPointWithGST,
        totalPoint: this.totalPoint,
        pointTotal: this.pointTotal,
        grand_total: this.garndTotalCost,
        grand_gst: this.GST,
        observationTaskCost: this.observationTaskCost,
        grand_point: this.grand_point,
        gst_point: this.gst_point
      };
      taskBody = { taskid: task.taskid, operators, orderBody };
      
      try {
        if (!this.userDetails?.allocated) {
            this.apiService
              .debitOrder(orderBody, this.payOption)
              .subscribe((res: any) => {
                localStorage.setItem('taskId', res?.taskId);
                localStorage.setItem('orderID', res?.orderId);
                localStorage.setItem('keyType', 'paynow');
                if (res) {
                  this.apiService
                    .taskIdResponse('taskaccept', taskBody)
                    .subscribe((data: any) => {
                      if (data) {
                        this.apiService
                          .updatePaymentStatus('update payment')
                          .subscribe((res: any) => {
                            this.OpenPopup(orderBody.taskid);
                          });
                      }
                    });
                }
              });
      } else {
        // updating account blance for allocated user
        const userRes = this.userService
        .updateBalance(this.userDetails, orderBody)
        .subscribe((response: any) => {
          if (response.message == 'success') {
            this.apiService.debitOrder(orderBody, this.payOption).subscribe((res: any) => {
              localStorage.setItem('taskId', res?.taskId);
              localStorage.setItem('orderID', res?.orderId);
              localStorage.setItem('keyType', 'paynow');
                if (res) {
                  this.apiService
                  .taskIdResponse('taskaccept', taskBody)
                  .subscribe((data: any) => {
                    if (data) {
                      this.apiService
                        .updatePaymentStatus('update payment')
                        .subscribe((res: any) => {
                          this.OpenPopup(orderBody.taskid);
                        });
                    }
                  });
                }
              });
          }
        });
      }

      } catch (err) {
        // console.log(err, "error")
      }
    });
  }
  checkEula() {
    let acceptedNum = 0;
    this.agreedTnC = false;
    this.eulaDateTime = '';
    this.operatorsEula.forEach((item, index) => {
      if (this.operatorsEula[index].checked) acceptedNum += 1;
    });
    // if (acceptedNum > 0) this.agreedTnC = true;
    if (acceptedNum === this.operatorsEula.length) {
      this.agreedTnC = true;
      this.eulaDateTime = new Date();
    }
  }

  uploadDone() {
    // const pgDiv: any = document.getElementById('pg');
    // this.pgContent = 'Loading'
    // // const cancelUrl: any = document.getElementsByClassName('pg.intentFooter');
    // console.log(this.isLoading, pgDiv);
    // cancelUrl.style.display = 'none';
  }
  submit() {
    this.paynowSubmit = true;
    this.success = false;
    this.clicked = true;
    let orderBody: any, taskBody: any;
    this.TASKDATA.forEach((task) => {
      const orderdetail: any = [],
        operators: any = [];
      let taskcost = 0;
      task.cartdata.forEach((operator, index) => {
        const cloudcover = operator.cloudcover;
        orderdetail.push(
          JSON.stringify({
            operator: operator.name,
            sensor: operator.sensor,
            obfuscateName: operator?.obfuscateName,
            resolution: operator.resolution,
            latency: operator.latency,
            unit: operator.unit,
            cost: operator.cost,
            point: this.getPoints(operator.cost),
            otaskid: operator.taskid,
            uuid: operator?.uuid,
            type: operator?.type,
            operatorKey: operator?.operatorKey,
            operatorEmail: operator?.operatorEmail,
            eulaAcceptedTime: this.eulaDateTime,
            resolutionTooltip: operator?.resolutionTooltip,
            sensorType: operator?.sensortype,
            taskNumber: operator?.taskNumber,
            originalResolution: operator?.originalResolution,
            taskedArea: operator.isPoint? operator.minimumArea :(!operator?.isPoint && operator.isCalculatedAreaSmaller ? this.formatTaskArea(operator.areacalc,operator.minimumArea)  :operator.areacalc),
            gst_data: operator?.gst_data,
            total: operator?.total,
            tnqSensorType: operator?.tnqSensorType,
          })
        );
        taskcost += operator.cost;
        operators.push({
          operator: operator.name,
          taskid: operator.taskid,
          sensor: operator.sensor,
          resolution: operator.resolution,
          uuid: operator?.uuid,
          latency: operator.latency,
          type: operator?.type,
          operatorKey: operator?.operatorKey,
          operatorEmail: operator?.operatorEmail,
          resolutionTooltip: operator?.resolutionTooltip,
          opportunities: operator?.opportunities,
        });
        //}
      });
      // const orderDetail = JSON.stringify(orderdetail);
      // taskcost = 1.0;
      orderBody = {
        id: this.orderId,
        customerid: task.customerid,
        taskid: task.taskid,
        orderamount: this.total,
        orderPoint: this.getPoints(this.total),
        currency: environment.payCurrency,
        orderdetail,
        payoption: this.payOptions[this.payOption].name,
        taskname: task.taskname,
        projectname: task.project,
        targetDelivery: this.selectedItems,
        eulaCheck: this.agreedTnC,
        eulaCheckTime: this.eulaDateTime,
        paymentMethod: this.selectedPaymentOption,
        mlTasks: task.mlTasks,
        sensorISId: task.mlTasks.length > 0 ? task.cartdata[0].uuid : '',
        taskStrategy: task?.taskStrategy,
        closeWindow: task.closeWindow,
        openWindow: task.openWindow,
        target: task.target,
        frequency: task.taskFrequency,
        repeatFrequency: task.repeatFrequency,
        noOfRepeats: task.noOfRepeats,
        subDailyCount: task.subDailyCount,
        projectid: task.projectid,
        sensors: task.cartdata,
        feasibilityTask: task.feasibilityTask,
        totalTaskNumber: this.taskTotalNumber,
        valueAddedOption: this.formattedvalueAddedOption,
        indices: this.formattedIndices,
        super_resolution: this.formattedSRDetails,
        totalSensorCost: this.totalSensorCost,
        feed_cost: this.totalFeedCost,
        taskTotalNumber: this.taskTotalNumber,
        grand_gst_total: this.grandTotal,
        vap_cost: this.valueAddedOptionTotal,
        totalPointWithGST: this.totalPointWithGST,
        totalPoint: this.totalPoint,
        pointTotal: this.pointTotal,
        grand_total: this.garndTotalCost,
        grand_gst: this.GST,
        grand_point: this.grand_point,
        gst_point: this.gst_point
      };

      taskBody = { taskid: task.taskid, operators, payOption: this.payOption, orderBody };
      if (this.payOption === 0) {
        const taskBodyData = JSON.stringify(taskBody);
        localStorage.setItem('taskBodyData', taskBodyData);
        localStorage.setItem('paymentMethod', this.selectedPaymentOption);
        this.createOrder(orderBody, this.payOption);
      } else {
        try {
          this.apiService
            .taskIdResponse('taskaccept', taskBody)
            .subscribe((res: any) => {
              if (res) {
                this.createOrder(orderBody, this.payOption);
              }
            });
        } catch (err) {
          // console.log(err, "error")
        }
      }
    });
  }

  createOrder(orderBody: any, option: any) {
    this.apiService.addOrder(orderBody, option).subscribe((res: any) => {
      if (option === 0) {
        this.payUrl = res.paypalUrl;
        window.open(this.payUrl, '_self');
        localStorage.setItem('taskId', res?.body?.order?.taskid);
        localStorage.setItem('orderID', res?.body?.order?.id);
        localStorage.setItem('keyType', 'paynow');
        localStorage.setItem('stripeId', res?.stripeId);
        this.paynowSubmit = false;
      } else {
        this.success = true;
        this.successMsg = res.Msg;
        if (res.orderId) this.orderId = res.orderId;

        const dialogRef = this.dialog.open(OpenConfirmDialog, {
          data: { orderRes: 'res', user: this.userDetails },
          width: '50%',
          backdropClass: 'blurr',
          disableClose: true,
        });
      }
    })
  }

  taskCost() {
    this.router.navigate(['tasks/taskscost']);
  }

  chngPayOption(idx: any) {
    if (this.userDetails?.paylater === false && idx === 'Pay later') {
      this.cancelpaylater();
    } else {
      this.payOption = idx;
      this.payOptions.forEach((option, i) => {
        option.selected = false;
        if (idx === option.name) {
          option.selected = true;
          this.payOption = i;
        }
      });
    }
  }

  openEulaCheck(indx: any, checked: any) {
    if (checked && !this.operatorsEula[indx].eulaClicked) {
      const dialogRef = this.dialog.open(AgreeComponent, {
        width: '65%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { operator: this.operatorsEula[indx], user: this.currentUser },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.includes('Ok')) {
          this.operatorsEula[indx].enabled = true;
        }
      });
    }
  }

  openEula(indx: any) {
    this.operatorsEula[indx].eulaClicked = true;
    const dialogRef = this.dialog.open(AgreeComponent, {
      width: '65%',
      backdropClass: 'blurred',
      disableClose: true,
      data: { operator: this.operatorsEula[indx], user: this.currentUser },
    });
  }
  cancelpaylater(): void {
    const dialogRef = this.dialog.open(CancelpaylaterComponent, {
      width: '50%',
      backdropClass: 'blurred',
      disableClose: true,
      data: { satellogic: false },
    });
  }
  deletefromCart(taskid: any) {
    const id = this.CARTDATA.findIndex((ele) => ele.taskid === taskid);
    if (id > -1) {
      this.CARTDATA.splice(id, 1);
      // this.CARTDATA.reverse();
      this.apiService.cartBS.next(this.CARTDATA);
      this.apiService.delCartdata(taskid).subscribe((res: any) => { });
    }
  }
  pdfGen(action: string) {
    const customerName = 'Rama';
    let docDefinition = {
      header: {
        text: 'Header - Invoice',
        alignment: 'center',
        style: 'sectionTitle',
      },
      content: [
        {
          text: 'Invoice Details',
          style: 'sectionHeader',
        },
        //   {
        //     columns: [
        //         [{ qr: `${customerName}`, fit: '50' }],
        //         [{ text: 'Signature', alignment: 'right', italics: true }],
        //     ]
        // }
      ],
      styles: {
        sectionTitle: {
          margin: [20, 0, 0, 10],
          fontSize: 24,
        },
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15],
        },
      },
    };
  }

  addMoney() {
    if (this.currentUser?.userType === 'Super user') {
      localStorage.setItem('taskData', this.taskId);
      this.router.navigate(['/profile']);
    } else this.adminSupport();
  }

  insufficientNotification(isSufficient) {
    const message = `You do not have sufficient taskings to use the chosen ${isSufficient > 1 ? 'sensors' : 'sensor'}. Please contact your administrator.`;
    this.popupService.openPopup(PaymentNotificationComponent, message, '', '40%');
  }

  onCheckboxChange(event: any, item: any) {
    this.storage = true;
    if (event.checked) {
      if (item.value === 1 && (this.currentUser.storageLocation === '' || !this.currentUser.storageLocation)) {
        localStorage.setItem('storage', item.value);
        localStorage.setItem('storageId', this.taskId);
        this.router.navigate(['/profile']);
      } else {
        this.selectedItems.push(item?.name);
      }
    } else {
      const index = this.selectedItems.findIndex(
        (selectedItem) => selectedItem === item
      );
      this.selectedItems.splice(index, 1);
    }
    if (this.selectedItems?.length > 0) {
      this.storage = false;
    }
  }

  select() {
    this.methodSelected = false;
    if (this.selectedPaymentOption === 'PayPal') this.onlineFees = PAYPAL_FEE;
    else if (this.selectedPaymentOption === 'Stripe') this.onlineFees = STRIPE_FEE;

    let grandTotalWithCharges = (this.grandTotal / this.onlineFees);
    let additionalCharge = (grandTotalWithCharges - this.grandTotal);
    let onlineCharges = this.dollarUS.format(additionalCharge);

    if(this.currentUser.pointsEligible) {
      additionalCharge = this.utilsService.convertCostToPoints(additionalCharge,this.currentUser.sensorConversionFactor);
      onlineCharges = `${additionalCharge} Points`
    }

    if (this.selectedPaymentOption != 'Bank Transfer') {
      const additionalChargeFormatted = `<span style="color: #3f9aff; font-weight: 600">${onlineCharges}</span>`;
      const message = `The ${this.selectedPaymentOption} charge for this transaction is ${additionalChargeFormatted}.`;
      const type = 'pleaseNote'
      this.popupService.openPopup(WarningPopupComponent, message, type, '40%');  
    }
  }

  // remove information from the cart
  removeService(item, task) {
    let infoData = {
      userId: this.currentUser.id,
      informationId: item._id,
      taskId: task.taskid,
      sensorId: item?.sensorId
    };       

    this.apiService
      .deleteInformationService(infoData)
      .subscribe((result: any) => {
        if (result.msg === 'Success') {
         this.getPaymentData();
        }
      });
  }

  isDebitDisable() {
    if(this.currentUser.allocated) {
      return (this.isSufficient > 0 || !this.agreedTnC || this.debitButton || this.storage || this.demoView);
    }

    let noMoney;
    if(!this.currentUser?.pointsEligible){
      noMoney = this.totalSensorCost > this.userDetails?.acBalance || this.grandTotal > this.userDetails?.acBalance || this.totalTaskCost > this.userDetails?.acBalance;
    }
    else {
      noMoney = this.totalPoint > this.userDetails?.acPoint || this.totalPointWithGST > this.userDetails?.acPoint;
    }
    return (noMoney || this.isSufficient > 0 || !this.agreedTnC || this.debitButton || this.storage || this.demoView);
  }

  getPoints (cost: any) {
    if (this.TASKDATA) {
    if (this.currentUser?.pointsEligible && this.TASKDATA[0]?.conversionFactor) {
      let convertFactor = this.TASKDATA[0]?.conversionFactor.split(":");
      const conversionFactor = convertFactor[1];
      return Math.round(cost * conversionFactor);                    
    }
  }
    return 0;
  }

  formatTaskArea(x: number, y: number): string {
    // return `${x} (${y})`;
    if (x > y) {
      return `${x}`;
    } else {
      return `${x} (${y})`;
    }
  }
  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  toggleVisibility(index: number): void {
    this.toggleStates[index] = !this.toggleStates[index];
  }

  adminSupport() {
    const message = `
    You have an insufficient balance to pay for this task. Please contact 
    <a href="mailto:${this.currentUser?.superUserEmail}">${this.currentUser?.superUserEmail}</a> 
    to top up before proceeding.
  `;   
  this.popupService.openPopup(PaymentNotificationComponent, message, '', '40%');
  }
  
  getPaymentData() {
    let action: any;
    this.valueAddedProducts = [];
    if(this.type === 'cart') action = 'cart';
    else action = 'task';
    let taskObj = {
      userId: this.userDetails.id,
      taskId: this.taskId,
      selectedSensors:this.operators,
      type: action
    } 
      
    this.apiService.getCartData(taskObj).subscribe((result: any) => {
      this.TASKDATA = [];
      let data = result.result;
      data.cartdata = JSON.parse(data.cartdata);
      this.TASKDATA.push(data);
      this.setCosts(result.result);
      this.observationTasking = this.TASKDATA && (this.TASKDATA[0]?.taskStrategy === 'Tip-and-Cue' || this.TASKDATA[0]?.taskStrategy === 'Tip-and-Cue-Outcome') && this.TASKDATA[0].tippingSensorCost && this.TASKDATA[0].tippingSensorCost > 0;
      this.observationTaskCost = this.TASKDATA[0]?.totalTaskCost;

      if (this.TASKDATA && this.currentUser?.allocated) {
        this.balanceCheck();
      }

      this.taxType = '';
      if (
        this.currentUser?.channelPriceId === undefined &&
        this.currentUser?.country === 'Singapore'
      ) {
        this.taxType = 'VAT';
      } else if (this.currentUser?.channelPriceId != undefined) {
        this.taxType = this.currentUser?.tax[0]?.type;
      }
      this.chngPayOption(this.payOption);
      if (this.grandTotal > this.userDetails?.acBalance) {
        this.account = true;
      }
      this.selectedTask = this.TASKDATA[0];
      this.getEula();
      this.setVadp();
    });
  }

    setCosts(costData) {
      this.totalSensorCost = costData?.totalSensorCost;
      this.totalFeedCost = costData?.feed_cost;
      this.taskTotalNumber = costData?.totalTaskNumber;
      this.grandTotal = costData?.grand_gst_total;
      this.valueAddedOptionTotal = costData?.vap_cost;
      this.totalPointWithGST = costData?.totalPointWithGST;
      this.totalPoint = costData?.totalPoint;
      this.pointTotal = costData?.pointTotal;
      this.garndTotalCost = costData?.grand_total;
      this.total = this.grandTotal;
      this.GST = costData?.grand_gst;
      this.totalTaskCost = this.grandTotal;
      this.grand_point = costData?.grand_point;
      this.gst_point = costData?.gst_point;
      this.tippingSensorCost = this.TASKDATA[0]?.tippingSensorCost;
  }

  setVadp() {
    (this.TASKDATA || []).forEach(task => {
      (task.cartdata || []).forEach(item => {
        if (item?.demTotalPrice) {
          item.sensor_name = item.sensor;
          this.valueAddedOptions.push(item);
        }

        if (item?.indices) {
          item?.indices.forEach((row) => {
            row.sensorId = item.uuid;
            row.name = item.name;
            row.sensor_name = item.sensor;
            row.resolution = item.resolution;
            this.formattedIndices.push(row);
          });
        }

        if (item?.super_resolution) {
          item?.super_resolution.forEach((row) => {
            row.sensorId = item.uuid;
            row.name = item.name;
            row.sensor_name = item.sensor;
            row.resolution = item.resolution;
            this.formattedSRDetails.push(row);
          });
        }

        // Initialize the items array
        let items: any[] = [];
        let totalCost = 0;
        let totalPoint = 0;

        // Merge valueAddedOption items into the items array
        if (item.valueAddedOption) {
          Object.keys(item.valueAddedOption).forEach(key => {
            items = items.concat(item.valueAddedOption[key]);
          });
        }

        // Merge indices array into the items array
        if (item?.indices) {
          items = items.concat(item.indices);
        }

        // Merge super_resolution array into the items array
        if (item?.super_resolution) {
          items = items.concat(item.super_resolution);
        }

        items.forEach((i: any) => {
          totalCost += (i.totalareacost ?? 0);
          totalPoint += (i.totalareapoints ?? 0);
        });

        // Push the merged items into valueAddedProducts
        if (items?.length > 0) {
          this.valueAddedProducts.push({
            sensor: item.sensor,
            name: item.name,
            resolution: item.resolution,
            total: totalCost,
            items: items,
            obfuscateName: item?.obfuscateName,
            totalPoint: totalPoint,
          });
        }
      });
    });

    this.formattedvalueAddedOption = this.valueAddedOptions.flatMap(item =>
      Object.entries(item.valueAddedOption).flatMap(([key, values]) =>
        (values as any[]).map(addedValue => ({
          name: item.name,
          sensor: item.sensor,
          sensor_name: item?.sensor_name,
          sensorType: item.sensortype,
          sensorId: item.uuid,
          resolution: item.resolution,
          ...addedValue
        }))
      )
    );
  }

  getEula() {
    if (this.selectedTask) {
      this.selectedTask.cartdata.forEach((operator) => {        
          if (this.operatorsEula.length > 0) {
            const id = this.operatorsEula.findIndex(
              (el) => el.name === operator.name
            );
            if (id < 0)
              this.operatorsEula.push({
                name: operator.name,
                checked: false,
                operatorKey: operator?.operatorKey,
                type: operator?.type,
                resolution: operator?.resolution,
                resolutionTooltip: operator?.resolutionTooltip,
                startDate: this.selectedTask?.openWindow,
                endDate: this.selectedTask?.closeWindow,
                purpose: this.selectedTask?.purpose,
                obfuscateName: operator?.obfuscateName
              });
          } else {
            this.operatorsEula.push({
              name: operator.name,
              checked: false,
              enabled: false,
              operatorKey: operator?.operatorKey,
              type: operator?.type,
              resolution: operator?.resolution,
              resolutionTooltip: operator?.resolutionTooltip,
              startDate: this.selectedTask?.openWindow,
              endDate: this.selectedTask?.closeWindow,
              purpose: this.selectedTask?.purpose,
              obfuscateName: operator?.obfuscateName
            });
          }
      });
    }
  }
}

@Component({
  selector: 'OpenConfirmDialog',
  templateUrl: 'openconfirmdialog.html',
})
export class OpenConfirmDialog {
  title = '';
  targetArea: any;
  currentUser: any;
  operator: any;
  order: any;
  constructor(
    private apiService: ApiService,
    private router: Router,
    public dialogRef: MatDialogRef<OpenConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { item: any; user: any }
  ) {
    this.operator = data['operator'];

    if (this.operator) {
    } else {
      this.currentUser = data.user;
      this.order = data.item;
    }
  }

  onKeyPress(e: any, option: number) {
    let result: any = undefined;
    if (option == 1) result = ' Ok';
    this.dialogRef.close(result);
  }
  onNoClickor(): void {
    this.dialogRef.close(' Ok');
    this.router.navigate(['/orders']);
  }

  onNoClick(): void {
    this.dialogRef.close(' Ok');
    this.router.navigate(['/cart']);
  }
}
