import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserloginComponent } from './components/userlogin/userlogin.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { UserregisterComponent } from './components/userregister/userregister.component';
import { MyTaskComponent } from './components/my-task/my-task.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OrdersComponent } from './components/orders/orders.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { BgSliderComponent } from './components/shared/bg-slider/bg-slider.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { NgMaterialModule } from './ng-material/ng-material.module';
import { ForgotComponent } from './components/forgot/forgot.component';
import { MatButtonModule } from '@angular/material/button';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatIconModule } from '@angular/material/icon';
import { SignupComponent } from './components/signup/signup.component';
import { OtpComponent } from './components/otp/otp.component';
import { NewpassComponent } from './components/newpass/newpass.component';
import { SliderComponent } from './components/slider/slider.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopbarComponent } from './components/topbar/topbar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NotaskComponent } from './notask/notask.component';
import { PreviescreenComponent } from './components/previescreen/previescreen.component';
import { ActionComponent } from './components/action/action.component';
import { NewtaskComponent } from './components/newtask/newtask.component';
import { TaskcostComponent } from './components/taskcost/taskcost.component';
import { PaymentComponent } from './components/payment/payment.component';
import { CartComponent } from './components/cart/cart.component';
import { NotificationComponent } from './components/notification/notification.component';
import { AddbalanceComponent } from './components/profile/addbalance/addbalance.component';
import { DebitsuccessComponent } from './components/payment/debitsuccess/debitsuccess.component';
import { UploadphotoComponent } from './components/profile/uploadphoto/uploadphoto.component';
import { HowDoesItWorkComponent } from './components/shared/footer/how-does-it-work/how-does-it-work.component';
import { ContactsComponent } from './components/shared/footer/contacts/contacts.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgImageSliderModule } from 'ng-image-slider';
import { SwiperModule } from 'swiper/angular';
import { CarouselModule } from 'ng-carousel-cdk';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CancelsensorComponent } from './components/cancelsensor/cancelsensor.component';
import { AuthService } from './services/auth.service';
import { ObserversModule } from '@angular/cdk/observers';
import { InnerTaskComponent } from './components/my-task/inner-task/inner-task.component';
import { ResetpasswordComponent } from './components/forgot/resetpassword/resetpassword.component';
import { DeleteComponent } from './components/cart/delete/delete.component';
import { GlobeComponent } from './globe/globe.component';
import { ValidationService } from './services/validation.service';
import { SuccessComponent } from './components/forgot/resetpassword/success/success.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmDialog } from './components/taskcost/confirmdialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { SafePipe } from 'src/app/services/safe.service';
import { PaypalsuccessComponent } from './components/paypalsuccess/paypalsuccess.component';
import { ErrorComponent } from './components/error/error.component';
import { PaymentsuccessComponent } from './components/paypalsuccess/paymentsuccess/paymentsuccess.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { NoCartComponent } from './components/cart/no-cart/no-cart.component';
import { RequestDemoComponent } from './components/request-demo/request-demo.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import {MatMenuModule} from '@angular/material/menu';
import { FootersuccessComponent } from './components/shared/footer/footersuccess/footersuccess.component';
import { OpenconfirmwarningComponent } from './components/newtask/openconfirmwarning/openconfirmwarning.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { RegistersuccessComponent } from './components/userregister/registersuccess/registersuccess.component';
import { DatePipe } from '@angular/common';
import { CountrycheckComponent } from './components/taskcost/countrycheck/countrycheck.component';
import { AgreeComponent } from './components/payment/agree/agree.component';
import { CancelinvoiceComponent } from './components/orders/cancelinvoice/cancelinvoice.component';
import { CancelpaylaterComponent } from './components/payment/cancelpaylater/cancelpaylater.component';
import { JwtInterceptor } from './services/JwtInterceptor ';
import { AuthInterceptor } from './services/AuthInterceptor';
import { SatellogicWarningComponent } from './components/taskcost/satellogic-warning/satellogic-warning.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { WarningPopup } from './components/taskcost/warning-popup/warning-popup';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { CollectionPopupComponent } from './components/collections/collection-popup/collection-popup.component';
import { DesktopVersionComponent } from './components/my-task/desktop-version/desktop-version.component';
import { DepositsComponent } from './components/deposits/deposits.component';
import { SensorpreviewComponent } from './components/taskcost/sensorpreview/sensorpreview.component';
import { AdvanceSettingsComponent } from './components/taskcost/advance-settings/advance-settings.component';
import { UslEulaComponent } from './components/payment/agree/usl-eula/usl-eula.component';
import { ToiWarningPopupComponent } from './components/my-task/toi-warning-popup/toi-warning-popup.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FeasibilityComponent } from './components/my-task/feasibility/feasibility.component';
import { FeasibilityPreviewComponent } from './components/my-task/feasibility/feasibility-preview/feasibility-preview.component';
import { OperatorPopupComponent } from './components/taskcost/operator-popup/operator-popup.component';
import { CloudCoverComponent } from './components/cloud-cover/cloud-cover.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ConfirmPopupComponent } from './components/my-task/confirm-popup/confirm-popup.component';
import { CatalogsPopupComponent } from './components/my-task/catalogs-popup/catalogs-popup.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { ReturnRefundComponent } from './components/collections/return-refund/return-refund.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { FundAllocationComponent } from './components/fund-allocation/fund-allocation.component';
import { PaymentNotificationComponent } from './components/payment/payment-notification/payment-notification.component';
import { AllocationNotificationComponent } from './components/fund-allocation/allocation-notification/allocation-notification.component';
import { ScrollTrackerDirective } from './components/shared/scroll-tracker.directive';
import { StarRatingComponent } from './components/my-task/star-rating/star-rating.component';
import { FilterPopupComponent } from './components/collections/filter-popup/filter-popup.component';
import { BudgetsComponent } from './components/budgets/budgets.component';
import { BudgetpopupComponent } from './components/newtask/budgetpopup/budgetpopup.component';
import { WarningPopupComponent } from './components/collections/warning-popup/warning-popup.component';
import { OpportunityComponent } from './components/my-task/opportunity/opportunity.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { APP_DATE_FORMATS } from './custom-date-adapter/my-date-formats';
import { CreateTaskConfirmDialog } from './components/collections/confirm-dialog/confirmdialog'
import { QuotationPopupComponent } from './components/userregister/quotation-popup/quotation-popup.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { CustomDateAdapter } from './custom-date-adapter/custom-date-adapter';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { DecimalPipe } from '@angular/common';
import { CustomTooltipDirective } from './components/my-task/custom-tooltip/custom-tooltip.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WhatsNewComponent } from './components/shared/footer/whats-new/whats-new.component';
import { RequiredPopupComponent } from './components/taskcost/required-popup/required-popup.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { TaskcostSensorPreviewComponent } from './components/newtask/taskcost-sensor-preview/taskcost-sensor-preview.component';
@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    AppLayoutComponent,
    UserloginComponent,
    UserregisterComponent,
    MyTaskComponent,
    ProfileComponent,
    OrdersComponent,
    CollectionsComponent,
    FooterComponent,
    BgSliderComponent,
    ForgotComponent,
    SidebarComponent,
    SignupComponent,
    OtpComponent,
    NewpassComponent,
    SliderComponent,
    TopbarComponent,
    NotaskComponent,

    PreviescreenComponent,
    ActionComponent,
    NewtaskComponent,
    TaskcostComponent,
    PaymentComponent,
    CartComponent,
    NotificationComponent,
    AddbalanceComponent,
    DebitsuccessComponent,
    UploadphotoComponent,
    HowDoesItWorkComponent,
    ContactsComponent,
    CancelsensorComponent,
    InnerTaskComponent,
    ResetpasswordComponent,
    DeleteComponent,
    GlobeComponent,
    SuccessComponent,
    ConfirmDialog,
    SafePipe,
    PaypalsuccessComponent,
    ErrorComponent,
    PaymentsuccessComponent,
    NoCartComponent,
    RequestDemoComponent,
    FootersuccessComponent,
    OpenconfirmwarningComponent,
    RegistersuccessComponent,
    CountrycheckComponent,
    AgreeComponent,
    CancelinvoiceComponent,
    CancelpaylaterComponent,
    SatellogicWarningComponent,
    WarningPopup,
    CollectionPopupComponent,
    DesktopVersionComponent,
    DepositsComponent,
    SensorpreviewComponent,
    AdvanceSettingsComponent,
    UslEulaComponent,
    ToiWarningPopupComponent,
    NotFoundComponent,
    FeasibilityComponent,
    FeasibilityPreviewComponent,
    OperatorPopupComponent,
    CloudCoverComponent,
    ConfirmPopupComponent,
    CatalogsPopupComponent,
    CustomTooltipComponent,
    SubscriptionComponent,
    ReturnRefundComponent,
    FundAllocationComponent,
    PaymentNotificationComponent,
    AllocationNotificationComponent,
    ScrollTrackerDirective,
    StarRatingComponent,
    FilterPopupComponent,
    WarningPopupComponent,
    OpportunityComponent,
    BudgetsComponent,
    BudgetpopupComponent,
    CreateTaskConfirmDialog,
    QuotationPopupComponent,
    QuotesComponent,
    NumberFormatPipe,
    CustomTooltipDirective,
    WhatsNewComponent,
    RequiredPopupComponent,
    UserManagementComponent,
    TaskcostSensorPreviewComponent
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    MatNativeDateModule,
    NgxMatNativeDateModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatTimepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    BrowserModule,
    AppRoutingModule,
    NgMaterialModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ImageCropperModule,
    NgxDropzoneModule,
    NgImageSliderModule,
    CarouselModule,
    HttpClientModule,
    ObserversModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    MatCardModule,
    MatProgressBarModule,
    MatGridListModule,
    MatMenuModule,
    NgxMatDatetimePickerModule,
    RecaptchaV3Module,
    NgxOtpInputModule,
    MatAutocompleteModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    NgxMaterialTimepickerModule,
    MatTooltipModule
  ],

  providers: [
    { provide: AuthService,},
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    ValidationService, DatePipe, DecimalPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
