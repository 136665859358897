import { Component, OnChanges, OnInit, SimpleChanges, HostListener  } from '@angular/core';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { CollectionsComponent } from 'src/app/components/collections/collections.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TaskService } from 'src/app/services/task.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { DownloadService } from 'src/app/services/download.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  private inactivityTimeout: any;
  private footerRefreshTimeout: any;
  private readonly INACTIVITY_PERIOD = 3600000 //1hr
  private readonly FOOTER_REFRESH_PERIOD = 3600000 //1hr
  private lastActivityTime: number;
  isDownloading: boolean = false; 

  title = '';
  currentUser: any;

  userid: any;
  show: boolean = true;
  userDetails: any;
  photo: string;
  progress: any = 0;
  progressSubscription: Subscription;
  freeze: boolean = false;
  darkMode: boolean = false;
  isDarkMode: boolean;
  darkTheme: boolean;
  subscribe: boolean;
  isInIframe: boolean;
  feedback: string;
  demoView: boolean = false;
  user: any;
  pointUser: boolean = true;

  constructor(private router: Router, private authService: AuthService, private taskService: TaskService, private userService: UserService, private downloadService: DownloadService,
    private localStorageService: DataService,
    private themeService: ThemeService
    ) {
    const darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'true') {
      this.themeService.setDarkMode(true);
      this.localStorageService.updateTheme(true);
    }
    else {
      this.localStorageService.updateTheme(false);
      this.themeService.setDarkMode(false);
    } 

    this.resetInactivityTimer();
    this.resetFooterRefreshTimer();
    this.setupUnloadListener();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event?.url.includes('/task')) {
          this.title = 'Dashboard'
        }
        else if (event?.url === '/deposit') {
          this.title = 'Deposit'
          this.show = true;
        }
        else if (event?.url.includes('/cart')) {
          this.title = 'Cart'
          this.show = true;
        }
        else if (event?.url.includes('/collections')) {
          this.title = 'Collections'
          this.show = true;
        }
        else if (event?.url.includes('/orders')) {
          this.title = 'Orders'
          this.show = true;
        }
        else if (event?.url.includes('/deposits')) {
          this.title = 'Deposits'
          this.show = true;
        }
        else if (event?.url.includes('/budget')) {
          this.title = 'Budgets'
          this.show = true;
        }
        else if (event?.url.includes('/quotes')) {
          this.title = 'Quotes'
          this.show = true;
        }
        else if (event?.url.includes('/user-management')) {
          this.title = 'User Management'
          this.show = true;
        }
        else if (event?.url.includes('/newbudget')) {
          this.title = 'New Budget'
          this.show = true;
        }
        else if (event?.url.includes('/payment')) {
          this.title = 'Payment'
          this.show = true;
        }
        else if (event?.url.includes('/success')) {
          this.title = 'Payment'
          this.show = true;
        }
        else if (event?.url.includes('/profile')) {
          this.title = 'Profile'
          this.show = true;
        }
        else if (event?.url.includes('/newtask')) {
          this.title = 'New Task'
          this.show = true;
        }
        else if (event?.url.includes('/retask')) {
          this.title = 'Re-Task'
          this.show = true;
        }
        else if (event?.url.includes('/requestdemo')) {
          this.title = 'Request A Demo'
          this.show = false;
        }
        else if (event?.url.includes('/howitworks')) {
          this.title = 'How It Works'
          this.show = false;
        }
        else if (event?.url.includes('/support')) {
          this.title = 'Contact Us'
          this.show = false;
        }
        else if (event?.url.includes('/privacypolicy')) {
          this.title = 'Eartheye Privacy Policy'
          this.show = false;
        }
        else if (event?.url.includes('/disclaimer')) {
          this.title = 'Disclaimer'
          this.show = false;
        }
        else if (event?.url.includes('/allocation')) {
          this.title = 'Fund Allocation'
          this.show = true;
        }
        else if (event?.url.includes('/whats-new')) {
          this.title = 'Product Updates'
          this.show = false;
        }

      }

    });

    this.progressSubscription = this.downloadService.getProgressObservable().subscribe(
      progress => {
        this.progress = progress?.progress;
        if (progress?.downloadClick && progress.progress < 100) {
          this.freeze = true;
        } else {
          this.freeze = false;
        }
      }
    );
  }
  resetInactivityTimer(): void {
    clearTimeout(this.inactivityTimeout);
    if (!this.isDownloading) {
      this.inactivityTimeout = setTimeout(() => {
        this.logoutAndReset();
      }, this.INACTIVITY_PERIOD);
    }
  }

  resetFooterRefreshTimer(): void {
    clearTimeout(this.footerRefreshTimeout);
    if (!this.isDownloading) {
    this.footerRefreshTimeout = setTimeout(() => {
      this.authService.logout();
      this.router.navigate(['login']);
    }, this.FOOTER_REFRESH_PERIOD);
   }
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  resetTimer(): void {
    clearTimeout(this.inactivityTimeout);
    this.resetInactivityTimer();
  }
  // resetTimer(): void {
  //   this.lastActivityTime = Date.now();
  //   clearTimeout(this.inactivityTimeout);
  //   clearTimeout(this.footerRefreshTimeout);
  //   this.resetInactivityTimer();
  //   this.resetFooterRefreshTimer();
  // }

  setupUnloadListener(): void {
    window.addEventListener('unload', () => {
      clearTimeout(this.inactivityTimeout);
      clearTimeout(this.footerRefreshTimeout);
    });
  }

  ngOnInit(): void {
    this.isInIframe = this.themeService.isInIframe();
    
    this.localStorageService.subscribedUser.subscribe((newValue) => {
      this.subscribe = newValue;
    });

    this.localStorageService.darkTheme.subscribe((newValue) => {      
      this.darkTheme = newValue;
      this.themeService.setDarkMode(this.darkTheme);
      this.feedback = this.darkTheme ? '../../../assets/images/icon2.svg' : '../../../assets/images/icon1.svg';

    });

    if(environment.demoView === 'true') {
      this.demoView = true;
    }
    else {
      this.demoView = false;
    }

    // Subscribe to the downloading status from the DownloadService
    this.downloadService.isDownloading$.subscribe((isDownloading) => {
      this.isDownloading = isDownloading;
      this.resetInactivityTimer();
    });

    this.getJwtData();
    
    this.authService.getCurrentUser();
    const castUser = this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;
      if (this.currentUser?.id) {
        this.userService.personalInfo(this.currentUser?.id).subscribe(async (res: any) => {
          this.userDetails = await res;
          this.darkTheme = this.userDetails?.darkMode;
          this.isDarkMode = this.userDetails?.darkMode;
          this.themeService.setDarkMode(this.isDarkMode);
          this.localStorageService.updateTheme(this.isDarkMode);
          localStorage.setItem('darkMode', JSON.stringify(this.isDarkMode));

          if (this.userDetails.photo !== undefined && !this.userDetails.photo.includes('eeusersapi'))
            this.photo = environment.usersServer + "/data/" + this.userDetails.photo;
        });
        this.userid = this.currentUser.id;
        if (this.currentUser.userType === 'admin')
          this.userid = this.userid + 'admin';
      } else if (this.show) {
        sessionStorage.removeItem('jwttoken');
        localStorage.removeItem('key');
        this.authService.logout();
        this.router.navigate(['login']);
      }
      castUser.unsubscribe();
    });

    this.localStorageService.refreshUserDetails$.subscribe((userId: number) => {
      this.updateUserDetails(userId);
    });
  }

  getJwtData() {
    let tokenStr: any = sessionStorage.getItem('jwttoken')!;
    if (tokenStr) {
      if (tokenStr.includes('Msg')) return tokenStr;
      const decodedToken: any = new JwtHelperService().decodeToken(tokenStr);
      if (decodedToken) {
        if (decodedToken?.id) {
          this.userService.personalInfo(decodedToken?.id).subscribe((result: any) => {
            this.user = result;
            this.pointUser = this.user.pointsEligible;
          });
        }
      }
    }
  }

  logoutAndReset(): void {
    this.authService.logout(); // Logout first
    this.router.navigate(['login']).then(() => {
      window.location.reload(); // Reload after navigation
    });
  }
  manualLogout(): void {
    // Call the logout() method without triggering automatic logout and refresh.
    this.authService.logout();
    this.router.navigateByUrl('/logout', { skipLocationChange: true });
  }

   ngOnDestroy(): void {
    clearTimeout(this.inactivityTimeout);
    clearTimeout(this.footerRefreshTimeout);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
    this.localStorageService.updateTheme(false);
    this.themeService.setDarkMode(false);
    localStorage.removeItem('darkMode');
    // if (!this.router.url.includes('/logout')) {
    //   this.router.navigateByUrl('/logout', { skipLocationChange: true });
    // }
  }

  toggleTheme() {
    this.isDarkMode = !this.isDarkMode;
    this.themeService.setDarkMode(this.isDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(this.isDarkMode));
    this.localStorageService.updateTheme(this.isDarkMode);
    this.userService.updateTheme(this.currentUser?.id,this.isDarkMode).subscribe(async (res: any) => {
    });
  }

  updateUserDetails (userId) {
    this.userService.personalInfo(userId).subscribe(async (res: any) => {
      this.userDetails = await res;
    });
  }
}
