import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { CONFIG_VALUES, OPERATORS, polarization, SENSOR_BAND_DATA } from 'src/app/services/constants';
import { DataService } from 'src/app/services/data.service';
import { PopupService } from 'src/app/services/popup.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment.prod';
import { WarningPopup } from '../../taskcost/warning-popup/warning-popup';
import { MatTableDataSource } from '@angular/material/table';
import { AgreeComponent } from '../../payment/agree/agree.component';
import { ConfirmPopupComponent } from '../../my-task/confirm-popup/confirm-popup.component';
import { SensorpreviewComponent } from '../../taskcost/sensorpreview/sensorpreview.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AdvanceSettingsComponent } from '../../taskcost/advance-settings/advance-settings.component';


@Component({
  selector: 'app-taskcost-sensor-preview',
  templateUrl: './taskcost-sensor-preview.component.html',
  styleUrls: ['./taskcost-sensor-preview.component.scss']
})
export class TaskcostSensorPreviewComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('mySelects') mySelects: MatSelect;
  sensors: any;
  SensorsTypes: any;
  totalAmount: number;
  currency: any;
  currencySign: any;
  currentUser: any;
  subscription: any;
  SENSORS: any[];
  AVAILABLESENSORS: any = [];
  FILTEREDSENSORS: any[];
  selectedSensorTypes: string;
  dataSource: any;
  displayedColumns: string[] = [
    'operator',
    'band',
    'preview',
    'mode',
    'resolution',
    'area_calculated',
    'cloudcovers',
    'ONA',
    'unitcost',
    'holdback',
    'advance',
    'cost',
    'opportunity',
    'footprint',
    'clientInfo',
    'eula',
  ];
  selectedSensors: any[] = [];
  selectedLatency: any = [];
  tooltip: any[];
  totalAOI: any;
  isAllSensors = false;
  isNightSensor = false;
  enable: boolean = false;
  modeName: string;
  cloudFactor = 1;
  ONAFactor = 1;
  holdBack = 1;
  userDetails: any;
  tooltipValue: any;
  resolutionTooltip: boolean = false;
  tooltipDisabled: boolean = true;
  detailsText: any = '';
  satCapeEnable: boolean;
  capellaEnable: boolean;
  selectedItems: any[] = [];
  selectedLatencyKey: any = [];
  demoView: boolean = false;
  OPERATOR: any;
  showColumn: boolean = true;
  selectedSensorList: any[] = [];
  nameONA: string = 'ONA/Look/Grazing Angle';
  spot: boolean = false;
  strip: boolean = false;
  uniqueNamesData: any = [];
  observationSensorTypeOption: any = [];
  tnqSensorDisabled: boolean = true;
  selectedTnqSensorType: any;
  getSensorObj: any = [];
  darkTheme: boolean = false;
  tooltipImagePath: string;
  qualitySticker: string;
  orbitImage: string;
  maskImage: string;
  cpcAndAllocated: boolean = false;
  operatorsEula: any;
  showLooks: boolean = false;
  totalTaskNumber = 0;
  dem_calculation = true;
  lband: boolean = false;
  lbandOnly: boolean = false;
  singleCircle: any;
  initialSelection: any[] = [];
  rfSignals: boolean = false;
  isDurationChanging: boolean = false;
  columnResolution: string = 'Resolution';
  aisOnly: boolean = true;
  resolutionColumnTooltip: string = 'Image pixel resolution';
  selectedItemsByGroup: { [key: string]: any[] } = {};
  actionProviders = ['DEM', 'DSM', 'DTM'];
  enable_indices_calculation = false;
  @ViewChild('vadpselect') vadpSelect!: MatSelect;
  polarization: any;
  onaTooltip: string;
  superResolutions: any = [];
  enable_sr_calculation = true;
  private originalClose: (() => void) | null = null; // Correctly typed function
  bandInfoTooltip: any;
  selectedOperator: any;
  target: any;
  advanceSettingsImage: string;

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService,
    private userService: UserService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private popupService: PopupService,
    private localStorageService: DataService,
    public dialogRef: MatDialogRef<any>
  ) {
    this.OPERATOR = OPERATORS;
    this.polarization = polarization;
    this.authService.user.subscribe((user) => (this.currentUser = user));
    if (this.currentUser?.id) {
      this.userDetails = this.currentUser;
      this.cpcAndAllocated =
        this.userDetails?.cpc && this.userDetails?.allocated;
    }
    let userSubs = this.userDetails?.subscriptionType || 'standard';
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.tooltipImagePath = this.darkTheme
        ? '../../../assets/images/union-dark.svg'
        : '../../../assets/images/union.svg';
      this.orbitImage = this.darkTheme
        ? '../../../assets/images/orbit-dark.png'
        : '../../../assets/images/orbit.png';
      this.maskImage = this.darkTheme
        ? '../../../assets/images/mask-dark.png'
        : '../../../assets/images/mask.png';
      this.advanceSettingsImage = this.darkTheme
      ? '../../../assets/images/advance-dark.png'
      : '../../../assets/images/advance.png';
      this.qualitySticker = this.darkTheme ? '../../../assets/images/qualitySticker.svg' : '../../../assets/images/qualitySticker.svg';
    });

    let intent = 'internalUse';

    this.subscription = this.userService
      .getSensors(userSubs, this.currentUser?.id, intent, this.getSensorObj)
      .subscribe(async (result: any) => {
        this.rfSignals = false;
        if (result) {
          if (result[0].operators) {
            const allocatedSensorTypes = Array.from(new Set(result[0].operators.map(item => item.sensortype)));
            this.SensorsTypes = this.apiService.SENSORSTYPE.filter(sensor => allocatedSensorTypes.includes(sensor.value));
            if (this.currentUser.allocated) {
              this.SensorsTypes = this.SensorsTypes.filter(sensor => sensor.name !== 'AIS');
            }
            this.SENSORS = result[0].operators;
            let selectedIndx = -1;
            this.SensorsTypes.forEach((type, index) => {
              if (this.selectedSensorTypes) {
                if (this.selectedSensorTypes.includes(type.value)) {
                  selectedIndx = index;
                  this.SensorsTypes[index].selected = true;
                }
              } else {
                this.SensorsTypes[index].selected = false;
              }
            });
            this.dataSource = undefined;
            this.FILTEREDSENSORS = [];
            if (this.SENSORS) {
              this.SENSORS.forEach((sensor) => {
                this.FILTEREDSENSORS.push(sensor);
              });
            }
            this.dataSource = undefined;
            this.AVAILABLESENSORS = [];
            this.selectedSensors = [];
            if (selectedIndx > -1)
              this.updateAllComplete(
                this.SensorsTypes[selectedIndx],
                selectedIndx
              );
            if (this.FILTEREDSENSORS.length > 0) this.initializeData();
          }
        }
      });
  }

  ngOndestroy() {
    this.elementRef.nativeElement.remove();
  }

  ngOnInit(): void {
    this.currency = environment.payCurrency;
    this.currencySign = environment.payCurrencySign;
    this.observationSensorTypeOption = this.apiService.observationSensorType;
    this.totalAmount = 0;
    if (environment.demoView === 'true') {
      this.demoView = true;
    } else {
      this.demoView = false;
    }
  }

  async initializeData() {

    let i = 0,
      areacalc = 0,
      minArea = 0;
    this.tooltip = [];
    const targetArea = 0;
    let selectedTaskingPrice: any, selectedHistoricalPrice: any;

    this.FILTEREDSENSORS.forEach(async (sensor: any, i) => {
      const target = targetArea === 0 ? 'POI' : 'AOI';

      if (sensor?.key === OPERATORS.SATELLOGIC) {
        let priceArray = sensor?.pricing;

        if (priceArray) {
          // Filter out "rush" from latency array if target is "AOI"
          if (target === "AOI" && !this.currentUser.allocated) {
            sensor.latency = sensor.latency.filter(item => item.key !== "rush");
          }
        }
      }

      this.selectedLatency[i] = sensor?.latency[0]?.name;
      this.selectedLatencyKey[i] = sensor?.latency[0];
      if (sensor.name) {
        this.totalAOI = sensor.minareaspot;
        minArea = sensor.minareaspot

        if (sensor.areacalc) {
          areacalc = sensor.areacalc;
        } else {
          areacalc = 0;
        }
        const cloudC: any = [];
        for (var key in sensor.cloudcover) {
          cloudC.push({ key, value: sensor.cloudcover[key] });
        }

        let cloudcovers: any,
          selectedModes: any = [],
          modeSelected: any = [];
        let SZA: any,
          AC: any,
          selectedHoldBackOption: any,
          selectedCC: any,
          cloudcover: any;
        let defaultAzimuthAngleMax: any,
          defaultAzimuthAngleMin: any,
          defaultPolarization: any;

        if (sensor?.cloudcover) {
          cloudcovers = cloudC;
          cloudcover = cloudC[0].key;
          selectedCC = cloudC[0].key;
        }

        let cloudcovernew: any, defaultMinTooltip: any, defaultMinArea: any;
        if (sensor?.cloudcover) cloudcovernew = sensor.cloudcover;
        let collectmodes: any;
        if (sensor.collectmodes) collectmodes = sensor.collectmodes;
        if (sensor?.resolution) {
          var selectedResolution = sensor?.resolution[0];

          if ((sensor?.key === OPERATORS.AT21 || sensor?.key === OPERATORS.GHG || sensor?.key === OPERATORS.ECURS) && sensor.resolution?.length > 1) {
            sensor.resolution.forEach((res, index) => {
              res.disable = index !== 0; // Set disable = true for all except the first element
            });
          }

          if ((sensor?.key === OPERATORS.SYNSPECTIVE && sensor.resolution?.length > 1 && sensor?.sensor === 'SAR')) {
            sensor.resolution[0].disable = true;
            selectedResolution = sensor.resolution[1];
            sensor.resolution[1].disable = false;
          }

        }
        if (sensor?.collection_mode?.length > 0) {
          selectedModes.push(sensor?.collection_mode[0]);
          modeSelected.push(sensor?.collection_mode[0]);
          if (
            sensor?.key === OPERATORS.BLACKSKY &&
            selectedModes[0].sceneSize
          ) {
            minArea = selectedModes[0].sceneSize;
          }

          defaultMinArea = sensor?.collection_mode[0]?.value;
          defaultMinTooltip = sensor?.collection_mode[0]?.minTooltip;
        }

        cloudcover = '';
        selectedCC = '';

        if (sensor?.ONA?.length > 0 || sensor?.onaWithResolution?.length > 0) {

          //  var selectedONAOption = sensor?.onaWithResolution ?  sensor?.onaWithResolution[0]:sensor?.ONA[0];
          let filteredSensors = sensor?.onaWithResolution ? sensor?.onaWithResolution?.filter((item: any) => item.default === true) : sensor?.ONA;

          var selectedONAOption = sensor?.onaWithResolution ? filteredSensors[0] : sensor?.ONA[0];
          var uniqueOnaArray = sensor?.onaWithResolution ? this.getUniqueOnaArray(sensor?.onaWithResolution) : sensor.ONA;

          if (selectedONAOption && !selectedONAOption?.key && sensor?.onaWithResolution) {
            selectedONAOption.key = selectedONAOption?.ona
            sensor.selectedONA = selectedONAOption.ona ? selectedONAOption?.ona : selectedONAOption?.key
            selectedONAOption.value = selectedONAOption.onaUplift ? selectedONAOption?.onaUplift : selectedONAOption?.value;
            sensor.selectedONAOption = selectedONAOption;
            sensor.ONAFactor = selectedONAOption.value;

            if (sensor.resolution.length < 2 && sensor?.onaWithResolution)
              if (selectedONAOption?.resolution) {

                let newResoution: any = { key: "0", value: selectedONAOption.resolution, originalResolution: selectedONAOption.forResolution }
                if (selectedONAOption?.mode_value) {
                  newResoution.mode_value = selectedONAOption?.mode_value;
                }
                sensor.resolution = [newResoution];
                sensor.selectedResolution = newResoution
              }
            if (sensor.resolution.length > 1 && sensor?.onaWithResolution) {
              const resolutionArray = this.createResolutionArray(sensor?.onaWithResolution, sensor.selectedONA, sensor.resolution);
              sensor.resolution = resolutionArray;
              selectedResolution = resolutionArray[0];
            }
          }

        }

        if (sensor?.SZA?.length > 0) {
          SZA = sensor?.SZA[0];
        }
        if (sensor?.AC?.length > 0) {
          AC = sensor?.AC[0];
        }

        if (sensor?.holdback?.length > 0) {
          selectedHoldBackOption = sensor?.holdback[0];
        }

        if (sensor?.key === OPERATORS.UMBRA) {
          defaultAzimuthAngleMax = sensor?.targetAzimuthAngleMax;
          defaultAzimuthAngleMin = sensor?.targetAzimuthAngleMin;
          defaultPolarization = sensor?.sarPolarization[0].value;
        }

        if (sensor?.key === OPERATORS.LBAND) {
          for (let i = 0; i < sensor.signal_of_interest.length; i++) {
            sensor.signal_of_interest[i].isAudio = false;
          }
        }

        if (sensor?.key === OPERATORS.WYVERN) {
          sensor.numberOfBands = sensor.band_options[0].no_of_bands;
          sensor.tooltip = sensor.band_options[0].tooltip;
        }

        const deliveytime_stand = new Date().setDate(
          new Date().getDate() + sensor.latency_standard
        );
        const defaultLatency = [],
          latencySTE: any = [],
          latency: any = [];
        let unitcost = 0;

        if (sensor?.key === OPERATORS.CLYDE) {
          let totalAOIInMillion = targetArea / 1000000;

          let selectedKey = Object.keys(sensor?.pricing).find(
            (key) => totalAOIInMillion <= Number(key)
          );
          if (!selectedKey) {
            selectedKey = Object.keys(sensor.pricing).sort(
              (a: any, b: any) => b - a
            )[0];
          }

          let selectedhistoricalKey = Object.keys(sensor?.historicalPricing).find(
            (key) => totalAOIInMillion <= Number(key)
          );
          if (!selectedhistoricalKey) {
            selectedhistoricalKey = Object.keys(sensor.historicalPricing).sort(
              (a: any, b: any) => b - a
            )[0];
          }

          selectedTaskingPrice = {
            data: {
              selectedKey,
              price: sensor.pricing[selectedKey],
            },
          };

          selectedHistoricalPrice = {
            data: {
              selectedhistoricalKey,
              price: sensor.historicalPricing[selectedhistoricalKey],
            },
          };
        }

        for (let j = 0; j < sensor.latency.length; j++) {
          let selected = false;
          if (j === 0) selected = true;

          var calCost = sensor.latency[j]?.unitcost;
          var unitCost = sensor.latency[j]?.unitcost;
          if (sensor?.key === OPERATORS.UMBRA) {
            if (sensor.resolution) {
              var calCost = sensor.resolution[0]?.cost;
              var unitCost = sensor.resolution[0]?.cost;
            }
          }

          if (sensor?.key === OPERATORS.CLYDE) {
            var calCost = selectedTaskingPrice.data.price;
            var unitCost = selectedTaskingPrice.data.price;
          }

          if (sensor?.key === OPERATORS.AXELSPACE && this.currentUser.id != CONFIG_VALUES.AxelCustomerId) {
            let unitPrice: any = this.getAxelPrice(targetArea, sensor.pricing);
            var calCost = unitPrice;
            var unitCost = unitPrice;
          }

          if (sensor.latency[j]?.mode) var mode = sensor.latency[j]?.mode;
          else var mode: any = '<=';
          if (this.currentUser?.channelPrice) {
            var calCost = sensor.channelPrice[sensor.latency[j]]?.unitcost;
            var unitCost = sensor.channelPrice[sensor.latency[j]]?.unitcost;
          }

          latency.push({
            name: sensor.latency[j]?.name,
            unitcost: unitCost,
            cost: calCost,
            latency: sensor.latency[j]?.latency,
            selected: selected,
            mode: mode,
            sensor: sensor.name,
            taskingwindow: sensor.latency[j]?.taskingwindow,
            key: sensor.latency[j]?.key,
          });
        }

        let STElatency: any = [];
        if (sensor?.key === OPERATORS.STE) {
          latencySTE.push(latency);
          STElatency = JSON.parse(JSON.stringify(latencySTE));
        }

        if (sensor?.key === OPERATORS.OSK) {
          let area = '<50';

          if (sensor.pricing_unit) {
            sensor.pricingUnit = sensor.pricing_unit[area];
          }
        }

        let taskcost;
        const existId = this.AVAILABLESENSORS
          .filter(function (el) {
            return sensor?.sensorId === el?.sensorId;
          })
        const satIds = sensor?.satIds ? sensor?.satIds.map(obj => obj.id) : [];

        if (existId.length === 0) {
          if (this.selectedSensorTypes && sensor?.name != 'TripleSat') {
            if (sensor.enabled) {
              const tooltip = sensor;
              this.AVAILABLESENSORS.push({
                name: sensor.name,
                resolution: sensor.resolution,
                unit: sensor.costunit,
                pricingUnit: sensor.pricingUnit ? sensor.pricingUnit : sensor.costunit,
                type: sensor?.type,
                unitcost: unitcost,
                sensor: sensor.sensor,
                sensortype: sensor.sensortype,
                minarea: minArea,
                areacalc: areacalc,
                area_calculated: this.totalAOI,
                tooltip: tooltip,
                cloudcovers: cloudcovers,
                cloudcover: cloudcover,
                cloudnew: cloudcovernew,
                collectmodes: collectmodes,
                defaultLatency: defaultLatency,
                latency: latency,
                selectedCost: taskcost,
                selectedCC: selectedCC,
                selectedLatency: this.selectedLatency[i],
                selectedLatencyKey: this.selectedLatencyKey[i],
                cost: taskcost,
                enabled: sensor.enabled,
                ONA: uniqueOnaArray ? uniqueOnaArray : sensor.ONA,
                holdback: sensor.holdback,
                collection_mode: sensor.collection_mode,
                country: sensor.country,
                selectedModes: selectedModes,
                selectedONAOption: selectedONAOption,
                sensorTooltip: sensor.tooltip,
                sensorDesc: sensor?.sensordesc,
                rushCloudCover: false,
                key: sensor?.key,
                operatorEmail: sensor?.operatorEmail,
                modeType: sensor?.multiplemode,
                modeSelected: modeSelected,
                satIds: satIds,
                SZA: SZA,
                AC: AC,
                selectedResolution: selectedResolution,
                sarPolarization: sensor?.sarPolarization,
                sarOrbitDirection: sensor?.sarOrbitDirection,
                productAnciallary: sensor?.productAnciallary,
                satelliteName: sensor?.satelliteName,
                collectionCost: sensor?.collection_cost,
                selectedHoldBackOption: selectedHoldBackOption,
                feasibility: sensor?.feasibility,
                tooltipValue: defaultMinTooltip,
                selectedAnciallary: '',
                selectedOrbitDirection: '',
                selectedPolarization: '',
                siteData: sensor?.siteData,
                collectionunit: sensor?.collectionunit,
                selectedTnqSensorType: '',
                tnqSensorDisabled: true,
                minimumArea: defaultMinArea,
                captureMode: '',
                STElatency: STElatency,
                defaultPolarization: defaultPolarization,
                defaultAzimuthAngleMax: defaultAzimuthAngleMax,
                defaultAzimuthAngleMin: defaultAzimuthAngleMin,
                pricing: sensor?.pricing,
                cancelPolicy: sensor?.cancel_policy,
                onaWithResolution: sensor?.onaWithResolution,
                imageryname: sensor?.imageryname,
                selectedTaskingPrice: selectedTaskingPrice,
                selectedHistoricalPrice: selectedHistoricalPrice,
                sensorId: sensor?.sensorId,
                valAddedArray: sensor.valueAddedOption,
                valueAddedAction: this.formatvalueAddedOption(sensor, selectedModes, selectedResolution),
                signal_of_interest: this.setSignalOfInterest(sensor?.signal_of_interest),
                collection_duration: sensor?.collection_duration,
                updateFrequency: sensor?.updateFrequency,
                indicesArray: this.setIndices(sensor, selectedResolution),
                superResolutions: this.setSuperResolutions(sensor),
                no_imagery_country: sensor?.no_imagery_country,
                obfuscateName: sensor?.obfuscateName,
                displayName: (this.currentUser.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor.name,
                sarOrbitType: sensor?.sarOrbitType,
                bitDepth: sensor?.bitDepth,
                sensorBands: sensor?.bands,
                bandOptions: sensor?.band_options,
                numberOfBands: sensor?.numberOfBands
              });

            } else {
              const tooltip = {
                name: sensor.name,
                resolution: sensor.resolution,
                unit: sensor.costunit,
                type: sensor?.type,
                unitcost: sensor.unitcost,
                sensor: sensor.sensor,
                sensortype: sensor.sensortype,
                sensorTooltip: sensor.tooltip,
                sensorDesc: sensor?.sensordesc,
              };
              this.AVAILABLESENSORS.push({
                name: sensor.name,
                resolution: sensor.resolution,
                sensor: sensor.sensor,
                sensortype: sensor.sensortype,
                tooltip: tooltip,
                enabled: sensor.enabled,
                sensorTooltip: sensor.tooltip,
                sensorDesc: sensor?.sensordesc,
                selectedModes: selectedModes,
                collection_mode: sensor.collection_mode,
                selectedONAOption: selectedONAOption,
                type: sensor?.type,
                satIds: satIds,
                key: sensor?.key,
                operatorEmail: sensor?.operatorEmail,
                modeType: sensor?.multiplemode,
                selectedLatency: this.selectedLatency[i],
                selectedLatencyKey: this.selectedLatencyKey[i],
                modeSelected: modeSelected,
                SZA: SZA,
                AC: AC,
                selectedResolution: selectedResolution,
                sarPolarization: sensor?.sarPolarization,
                sarOrbitDirection: sensor?.sarOrbitDirection,
                productAnciallary: sensor?.productAnciallary,
                satelliteName: sensor?.satelliteName,
                selectedHoldBackOption: selectedHoldBackOption,
                feasibility: sensor?.feasibility,
                tooltipValue: defaultMinTooltip,
                selectedAnciallary: '',
                selectedOrbitDirection: '',
                selectedPolarization: '',
                siteData: sensor?.siteData,
                collectionunit: sensor?.collectionunit,
                minimumArea: defaultMinArea,
                STElatency: STElatency,
                defaultPolarization: defaultPolarization,
                defaultAzimuthAngleMax: defaultAzimuthAngleMax,
                defaultAzimuthAngleMin: defaultAzimuthAngleMin,
                pricing: sensor?.pricing,
                cancelPolicy: sensor?.cancel_policy,
                imageryname: sensor?.imageryname,
                selectedTaskingPrice: selectedTaskingPrice,
                selectedHistoricalPrice: selectedHistoricalPrice,
                sensorId: sensor?.sensorId,
                valAddedArray: sensor.valueAddedOption,
                valueAddedAction: this.formatvalueAddedOption(sensor, selectedModes, selectedResolution),
                updateFrequency: sensor?.updateFrequency,
                superResolutions: this.setSuperResolutions(sensor),
                no_imagery_country: sensor?.no_imagery_country,
                obfuscateName: sensor?.obfuscateName,
                displayName: (this.currentUser.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor.name,
                sarOrbitType: sensor?.sarOrbitType,
                sensorBands: sensor?.bands,
                bandOptions: sensor?.band_options,
                numberOfBands: sensor?.numberOfBands
              });
            }
          }
        }
        this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
        this.dataSource.sort = this.sort;

        for (const [sensorIndex, sensors] of this.AVAILABLESENSORS.entries()) {
          if (sensors?.key === OPERATORS.GHG) {
            sensors.latency = sensors.latency.map(latencyItem => {
              const matchingMode = sensors.selectedModes[0].cost.find(mode => mode[latencyItem.key] !== undefined);
              return {
                ...latencyItem,
                cost: matchingMode ? matchingMode[latencyItem.key] : null,
                unitcost: matchingMode ? matchingMode[latencyItem.key] : null,
              };
            });
          }
          if (sensors?.key === OPERATORS.CAPELLA || sensors?.key === OPERATORS.GOKTURK || sensor?.key === OPERATORS.BLACKSKY || (sensors?.key === OPERATORS.STE && sensors?.sensor === 'MSI'
            && sensors.selectedResolution.value.includes('Bundle')
          ) || sensors?.key === OPERATORS.ECURS) {
            let pricingArray = sensors.selectedModes[0]?.pricing;
            for (const latencyItem of sensors.latency) {
              const key = latencyItem.key;
              if (pricingArray && pricingArray.hasOwnProperty(key)) {
                const newCost = Number(pricingArray[key]);
                latencyItem.cost = newCost;
                latencyItem.unitcost = newCost;
              }
            }
          }

          if (sensors?.key === OPERATORS.USL) {
            let priceArray = sensor?.collection_cost;
            let subdailyCount = 1;
            if (priceArray) {
              for (const latencyItem of sensors.latency) {
                for (const pricingItem of priceArray) {
                  if (
                    latencyItem.key === pricingItem.latency &&
                    pricingItem.unit === subdailyCount
                  ) {
                    latencyItem.cost = Number(pricingItem.cost);
                    latencyItem.unitcost = Number(pricingItem.cost);
                    break;
                  }
                }
              }
            }
          }

          if (sensors?.key === OPERATORS.SATELLOGIC) {
            let priceArray = sensor?.pricing;

            if (priceArray) {
              for (const latencyItem of sensors.latency) {
                const key = latencyItem.key;
                if (priceArray[target] !== undefined) {
                  if (priceArray[target][key] !== undefined) {
                    latencyItem.cost = priceArray[target][key];
                    latencyItem.unitcost = priceArray[target][key];
                  }
                }
              }
            }
          }

          if (sensors?.key === OPERATORS.ESPER) {
            let priceArray = sensors?.pricing;
            let bands = 50;

            if (priceArray) {
              for (const latencyItem of sensors.latency) {
                const key = latencyItem.key;
                latencyItem.cost = priceArray[key][bands];
                latencyItem.unitcost = priceArray[key][bands];
              }
            }
          }
          this.taskingOption(sensors.selectedLatency, sensorIndex, sensors, 1);
        }
      }
    });

  }
  getTextLength(captureMode: string, productLevel: string): number {
    const captureModeLength = captureMode ? captureMode.length : 0;
    const productLevelLength = productLevel ? productLevel.length : 0;
    return captureModeLength + (productLevel ? productLevelLength + 3 : 0); // Adding 3 for ' ()'
  }

  createResolutionArray(data, selectedOna, resolution) {
    // Initialize the result array and a counter for the auto-increment key
    let result: any = [];
    let counter = 1;

    // Loop through the original data
    data?.forEach((item, index) => {
      // Check if the current item's 'ona' matches the selected 'ona'
      if (item.ona === selectedOna) {
        const matchingResolution = resolution.find(
          (res) => (res.value === item.forResolution || res?.originalResolution === item.forResolution)
        );

        // Push the new object with auto-increment key and forResolution value to the result array
        result.push({
          key: counter, value: item.resolution, order: item.order, originalResolution: item.forResolution, onaUplift: item.onaUplift,
          bands: item?.bands, multiplier: matchingResolution?.multiplier, combinedResolution: item?.combinedResolution ? item?.combinedResolution : matchingResolution?.combinedResolution
        });
        // Increment the counter
        counter++;
      }
    });

    result.sort((a, b) => a.order - b.order);
    if (result?.length > 1) {
      result[1].disable = true;
    }
    return result;
  }
  getUniqueOnaArray(data: any) {
    // Initialize an empty Set to keep track of unique 'ona' values
    const uniqueOna = new Set();
    // Initialize the result array
    const result: any = [];

    // Loop through the original data
    data.forEach(item => {
      // If the 'ona' value is not in the Set, add it to the Set and the result array
      if (!uniqueOna.has(item.ona)) {
        uniqueOna.add(item.ona);
        result.push(item);
      }
    });
    result.sort((a, b) => b.default - a.default);
    return result;
  }

  updateAllComplete(e: any, i: number) {
    this.nameONA = 'ONA/Look/Grazing Angle';
    this.showColumn = true;
    this.lbandOnly = false;
    this.columnResolution = 'Resolution';
    this.aisOnly = true;
    this.resolutionColumnTooltip = 'Image pixel resolution';
    this.onaTooltip = 'ONA: Off Nadir Angle is the degree or range to which the camera onboard a satellite can be tilted while imaging to capture a large area.' + '\n' +
      'Look Angle: The angle between the sensor’s line of sight and the Earth’s surface’s vertical direction (nadir).' + '\n' +
      'Grazing Angle: The angle between the sensor beam’s contact angle and the Earth’s surface.'
      ;

    this.displayedColumns = [
      'operator',
      'band',
      'preview',
      'mode',
      'resolution',
      'area_calculated',
      'cloudcovers',
      'ONA',
      'unitcost',
      'holdback',
      'advance',
      'cost',
      'opportunity',
      'footprint',
      'clientInfo',
      'eula',
    ];

    if (e.selected) {
      this.selectedSensorList.push(e?.name);
    } else {
      const index = this.selectedSensorList.findIndex(
        (selectedItem) => selectedItem === e.name
      );
      this.selectedSensorList.splice(index, 1);
    }

    if (
      this.selectedSensorList?.length === 1 &&
      this.selectedSensorList[0] === 'MSI'
    ) {
      this.nameONA = 'ONA';
      this.onaTooltip = 'Off Nadir Angle is the degree or range to which the camera onboard a satellite can be tilted while imaging to capture a large area.';
    }

    if (
      this.selectedSensorList?.length === 1 &&
      (this.selectedSensorList.includes('SAR') ||
        this.selectedSensorList.includes('InSAR'))
    ) {
      this.nameONA = 'Look/Grazing Angle';
      this.onaTooltip = 'Look Angle: The angle between the sensor’s line of sight and the Earth’s surface’s vertical direction (nadir).' + '\n' +
        'Grazing Angle: The angle between the sensor beam’s contact angle and the Earth’s surface.';
      this.showColumn = false;

      this.displayedColumns = ['operator', 'band', 'preview', 'mode', 'resolution', 'area_calculated',
        'ONA', 'unitcost', 'holdback', 'advance', 'cost', 'opportunity', 'footprint', 'clientInfo', 'eula'];

    } else if (
      this.selectedSensorList?.length === 2 &&
      this.selectedSensorList.includes('SAR') &&
      this.selectedSensorList.includes('InSAR')
    ) {
      this.nameONA = 'Look/Grazing Angle';
      this.onaTooltip = 'Look Angle: The angle between the sensor’s line of sight and the Earth’s surface’s vertical direction (nadir).' + '\n' +
        'Grazing Angle: The angle between the sensor beam’s contact angle and the Earth’s surface.';
      this.showColumn = false;

      this.displayedColumns = ['operator', 'band', 'preview', 'mode', 'resolution', 'area_calculated',
        'ONA', 'unitcost', 'holdback', 'advance', 'cost', 'opportunity', 'footprint', 'clientInfo', 'eula'];

    }
    else {
      this.nameONA = 'ONA';
      this.onaTooltip = 'Off Nadir Angle is the degree or range to which the camera onboard a satellite can be tilted while imaging to capture a large area.';
    }

    if (((this.selectedSensorList.includes('Stereo'))
      || this.selectedSensorList.includes('MSI'))
      && (!this.currentUser.allocated)) {

      const modifiedColumns = [...this.displayedColumns];
      const modeIndex = modifiedColumns.indexOf('mode');
      modifiedColumns.splice(modeIndex + 1, 0, 'dem');
      this.displayedColumns = modifiedColumns;
    }

    if (this.selectedSensorList.includes('SAR')) {
      this.showLooks = true;
      const resolutionIndex = this.displayedColumns.indexOf('resolution');
      if (resolutionIndex !== -1) {
        this.displayedColumns.splice(resolutionIndex + 1, 0, 'looks');
      }
    }

    if (this.selectedSensorList.includes('RF- comms')) {
      this.lband = true;
      const resolutionIndex = this.displayedColumns.indexOf('resolution');
      if (resolutionIndex !== -1) {
        this.displayedColumns.splice(resolutionIndex + 1, 0, 'signalOfInterest');
      }
    }

    if (this.selectedSensorList.includes('AIS')) {
      if (this.selectedSensorList?.length === 1) {
        this.columnResolution = 'Update Frequency';
        this.resolutionColumnTooltip = 'Signal update frequency';
      }
      else {
        this.aisOnly = false;
        this.columnResolution = 'Resolution/Update Frequency';
        this.resolutionColumnTooltip = 'Image pixel resolution/ signal update frequency';
      }
    }

    if (this.selectedSensorList.includes('HSI')) {

      const modifiedColumns = [...this.displayedColumns];
      const modeIndex = modifiedColumns.indexOf('operator');
      modifiedColumns.splice(modeIndex + 1, 0, 'bandOption');
      this.displayedColumns = modifiedColumns;
    }

    this.selectedSensorTypes = '';
    this.FILTEREDSENSORS = [];

    this.SensorsTypes.forEach((type, index) => {
      if (this.SensorsTypes[index].selected === true) {
        this.selectedSensorTypes += type.value + '/';
        this.SensorsTypes[i].selected = e.selected;

        this.SENSORS.forEach((sensor) => {
          if (type.value === sensor.sensortype) {
            const existId = this.FILTEREDSENSORS.findIndex(
              (ele) => JSON.stringify(ele) === JSON.stringify(sensor)
            );
            if (existId === -1) {
              if (this.isAllSensors) {
                this.FILTEREDSENSORS.push(sensor);
              } else {
                if (
                  sensor.sensortype === 'MSI' &&
                  sensor.enabled
                ) {
                  this.FILTEREDSENSORS.push(sensor);
                } else if (sensor.enabled && sensor.sensortype != 'MSI')
                  this.FILTEREDSENSORS.push(sensor);
              }
            }
          }
        });
      }
    });
    this.initializeData();
    if (!e.selected) {
      const AVAILABLESENSORSFilter = this.AVAILABLESENSORS.filter(
        (el) => e.value !== el.sensortype
      );
      this.AVAILABLESENSORS = AVAILABLESENSORSFilter;
      this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
      this.dataSource.sort = this.sort;
      this.selectedSensors = [];
      this.AVAILABLESENSORS.forEach((operator) => {
        if (operator.checked) this.selectedSensors.push(operator);
      });
    }

    if (this.FILTEREDSENSORS.length === 0) {
      this.dataSource = undefined;

      // this.dataSource = new MatTableDataSource([]);
    } else {
      this.FILTEREDSENSORS.sort(function (a, b) {
        return -(a.enabled - b.enabled);
      });
    }
  }
  toggleAll() {
    this.FILTEREDSENSORS = [];
    this.SensorsTypes.forEach((type, index) => {
      if (this.SensorsTypes[index].selected === true) {
        this.selectedSensorTypes += type.value + '/';
        this.SENSORS.forEach((sensor) => {
          if (type.value === sensor.sensortype) {
            if (!this.isAllSensors) {
              if (sensor.sensortype === 'MSI') {
                this.FILTEREDSENSORS.push(sensor);
              } else if (sensor.sensortype != 'MSI')
                this.FILTEREDSENSORS.push(sensor);
            } else {
              if (
                sensor.sensortype === 'MSI' &&
                sensor.enabled
              ) {
                this.FILTEREDSENSORS.push(sensor);
              } else if (sensor.enabled && sensor.sensortype != 'MSI')
                this.FILTEREDSENSORS.push(sensor);
            }
          }
        });
      }
    });
    this.FILTEREDSENSORS.sort(function (a, b) {
      return -(a.enabled - b.enabled);
    });
    this.initializeData();
    if (this.isAllSensors) {
      const AVAILABLESENSORSFilter = this.AVAILABLESENSORS.filter(
        (el) => el.enabled === true
      );
      this.AVAILABLESENSORS = AVAILABLESENSORSFilter;
      this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
      this.dataSource.sort = this.sort;
    }
  }

  toggleNightSensors() {
    this.resetSorting();
    this.FILTEREDSENSORS = [];
    this.AVAILABLESENSORS = [];
    this.selectedSensors = [];
    this.selectedSensorTypes = '';
    this.SENSORS.forEach((sensor) => {
      if (sensor.nightvision) {
        this.FILTEREDSENSORS.push(sensor);
      }
    });
    this.SensorsTypes.forEach((type, index) => {
      if (this.SensorsTypes[index].selected === true) {
        this.SensorsTypes[index].selected = false;
      }
    });
    if (!this.isNightSensor) {
      this.selectedSensorTypes += '/';
    }
    this.initializeData();
  }

  async ccChange(cCover: any, indx: number, row: any) {
    if (cCover) {
      const ccFactor = this.AVAILABLESENSORS[indx]?.cloudnew[cCover];
      this.cloudFactor = ccFactor;
      this.AVAILABLESENSORS[indx].selectedCC = cCover;
      this.AVAILABLESENSORS[indx].cloudFactor = ccFactor;
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx, idx);
      }
    }
  }

  async ONAOption(cCover: any, indx: number, row: any, type: any) {
    if (cCover) {
      if (!cCover?.key) {
        cCover.key = cCover.ona ? cCover.ona : cCover.key;
        cCover.value = cCover.value ? cCover.value : cCover.onaUplift;
      }
      if (cCover?.ona && type === 1) {
        if (this.AVAILABLESENSORS[indx].resolution.length < 2) {

          let newResoution: any = { key: "0", value: cCover.resolution, originalResolution: cCover.forResolution }
          if (cCover?.mode_value) {
            newResoution.mode_value = cCover?.mode_value;
          }
          this.AVAILABLESENSORS[indx].resolution = [newResoution]
          this.AVAILABLESENSORS[indx].selectedResolution = newResoution

        }
        if (this.AVAILABLESENSORS[indx].resolution.length > 1) {

          const resolutionArray = this.createResolutionArray(this.AVAILABLESENSORS[indx]?.onaWithResolution, cCover?.ona, this.AVAILABLESENSORS[indx].resolution);
          this.AVAILABLESENSORS[indx].resolution = resolutionArray;
          this.AVAILABLESENSORS[indx].selectedResolution = resolutionArray[0];
          this.AVAILABLESENSORS[indx].selectedResolutionValue = resolutionArray[0]?.value;
          this.AVAILABLESENSORS[indx].modeResetValue = null;
          this.AVAILABLESENSORS[indx].selectedModes = [row?.collection_mode[0]];
          this.AVAILABLESENSORS[indx].modeSelected = [row?.collection_mode[0]];

          if ((row?.key === OPERATORS.STE && row?.sensor === 'MSI')) {
            this.resetCost(row, this.AVAILABLESENSORS[indx].selectedResolution, indx);
          }
        }

      }

      const ccFactor = cCover.value;
      this.ONAFactor = ccFactor;
      this.AVAILABLESENSORS[indx].selectedONA = cCover.ona ? cCover.ona : cCover.key;
      this.AVAILABLESENSORS[indx].selectedONAOption = cCover;
      this.AVAILABLESENSORS[indx].ONAFactor = ccFactor;
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx, idx);
      }
    }

    if ((row.key === OPERATORS.HEAD) || row.key === OPERATORS.GOKTURK || (row.key === OPERATORS.AT21)) {
      this.resetResolution(this.AVAILABLESENSORS[indx].selectedModes[0], indx, row?.key);
    }

    if (this.AVAILABLESENSORS[indx].selectedResolution && this.AVAILABLESENSORS[indx].selectedResolution?.bands) {
      this.AVAILABLESENSORS[indx].sensorTooltip = this.AVAILABLESENSORS[indx].selectedResolution.bands;
    }
  }

  async toggleSelect(checked: boolean) {
    this.SENSORS.forEach((sensor, indx) => {
      if (sensor.enabled) {
        this.SENSORS[indx].checked = checked;
      }
    });
  }
  async taskingOption(latency: any, rowIndx: number, row: any, type: number) {
    if (this.AVAILABLESENSORS[rowIndx].prevent === true) {
      this.AVAILABLESENSORS[rowIndx].prevent = false;
      this.AVAILABLESENSORS[rowIndx].checked = false;
    }
    const latencyFilter = row?.latency.filter((el: any) => el.name === latency);
    let latencyKey = latencyFilter[0];

    const keysToInclude = ['key', 'name', 'latency'];
    const latencyArray = latencyFilter.map((item) => {
      const newItem: any = {};
      keysToInclude.forEach((key) => (newItem[key] = item[key]));
      return newItem;
    });

    if (
      latencyKey.key === 'rush' ||
      latencyKey.key === 'RR' ||
      latencyKey.key === 'emergency' ||
      (latencyKey.key === 'priority' && (row?.key === OPERATORS.STE && row.sensor != 'SAR'))
    ) {
      this.AVAILABLESENSORS[rowIndx].rushCloudCover = true;
      this.AVAILABLESENSORS[rowIndx].selectedCC = '';
    }
    else {
      if (latencyKey.key != 'standard' && type === 0 && this.AVAILABLESENSORS[rowIndx]?.cloudcovers) {
        if (row.key === OPERATORS.HEAD || (row.key === OPERATORS.STE && row.sensor === 'MSI') || row.key === OPERATORS.AXELSPACE || row.key === OPERATORS.WYVERN) {
          this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[4]?.key;
          this.AVAILABLESENSORS[rowIndx].cloudValue = null;
        } else if (row.key === OPERATORS.KOMPSAT || row.key === OPERATORS.ISI || row.key === OPERATORS.GOKTURK) {
          this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[5]?.key;
          this.AVAILABLESENSORS[rowIndx].cloudValue = null;
        }

      }
      else if (this.AVAILABLESENSORS[rowIndx]?.cloudcovers && type === 0) {
        this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[0]?.key;
        this.AVAILABLESENSORS[rowIndx].cloudValue = null;
      }
      this.AVAILABLESENSORS[rowIndx].rushCloudCover = false;
      this.ccChange(this.AVAILABLESENSORS[rowIndx].selectedCC, rowIndx, 'row');
    }

    if (latencyKey.key === 'priority' && row.key === OPERATORS.BLACKSKY) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = '100%';
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    } else if (latencyKey.key != 'standard' && row.key === OPERATORS.GOKTURK) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[5]?.key;
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    } else if (latencyKey.key != 'standard' && type != 0 && (row.key === OPERATORS.STE && row.sensor === 'MSI')) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[4]?.key;
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    } else if (latencyKey.key === 'priority' && row.key === OPERATORS.WYVERN) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[4]?.key;
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    }

    if (this.FILTEREDSENSORS[rowIndx]?.checked)
      this.FILTEREDSENSORS[rowIndx].checked = true;

    this.AVAILABLESENSORS[rowIndx].selectedLatency = latency;
    this.AVAILABLESENSORS[rowIndx].selectedLatencyKey = latencyArray[0];
    this.AVAILABLESENSORS[rowIndx].latency.forEach((elem, i) => {
      if (elem.name === latency) {
        this.AVAILABLESENSORS[rowIndx].cost =
          this.AVAILABLESENSORS[rowIndx].latency[i].unitcost;
        this.AVAILABLESENSORS[rowIndx].unitcost =
          this.AVAILABLESENSORS[rowIndx].latency[i].unitcost;
        //if(this.AVAILABLESENSORS[rowIndx].key !== OPERATORS.SPIRE) {
        this.AVAILABLESENSORS[rowIndx].selectedCost =
          this.AVAILABLESENSORS[rowIndx].latency[i].unitcost;
        //}
        this.AVAILABLESENSORS[rowIndx].latency[i].selected = true;
      } else this.AVAILABLESENSORS[rowIndx].latency[i].selected = false;
    });

    this.holdBackOption(
      this.AVAILABLESENSORS[rowIndx].selectedHoldBackOption,
      rowIndx,
      row
    );
    this.ONAOption(
      this.AVAILABLESENSORS[rowIndx].selectedONAOption,
      rowIndx,
      row,
      0
    );

    const idx = this.AVAILABLESENSORS[rowIndx].latency.findIndex(
      (elem) => elem.name === this.AVAILABLESENSORS[rowIndx].selectedLatency
    );

    if (idx > -1) {
      this.calculateUnitcost(rowIndx, idx);
    }
  }

  async holdBackOption(cCover: any, indx: number, row: any) {
    if (cCover) {
      const ccFactor = cCover?.value;
      this.holdBack = ccFactor;
      this.AVAILABLESENSORS[indx].selectedHoldBack = cCover?.key;
      this.AVAILABLESENSORS[indx].selectedHoldBackOption = cCover;
      this.AVAILABLESENSORS[indx].ccFactor = ccFactor;
      this.AVAILABLESENSORS[indx].holdbackFactor = ccFactor;
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx, idx);
      }
    }
  }

  async modeOption(productMode: any, indx: number, row: any) {
    this.resolutionTooltip = true;
    this.AVAILABLESENSORS[indx].selectedModes = [];
    if (productMode?.isCaptureMode && !productMode?.isProductLevel) {
      this.AVAILABLESENSORS[indx].selectedMode = productMode.captureMode;
    } else if (productMode?.isProductLevel && !productMode?.isCaptureMode) {
      this.AVAILABLESENSORS[indx].selectedMode = productMode.productLevel;
    } else if (productMode?.isProductLevel && productMode?.isCaptureMode) {
      this.AVAILABLESENSORS[indx].selectedMode =
        productMode.captureMode + ' (' + productMode.productLevel + ')';
    }

    if (
      row?.key === OPERATORS.AT21
    ) {
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (productMode?.productLevel === 'L1') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Bundle')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.productLevel === 'L4'
      ) {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Pan-sharpened')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Both')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      }

      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx, idx);
      }
    }

    else if (
      (row?.key === OPERATORS.STE && row?.sensor === 'SAR')
    ) {
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (productMode?.mode === 'PSPOT') {
        this.AVAILABLESENSORS[indx].resolution[1].disable = true;
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      } else if (
        productMode?.mode === 'PSTRIP'
      ) {
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].resolution[0].disable = true;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[1];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[1].value;
      } else {
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      }
    }

    else if (
      (row?.key === OPERATORS.SYNSPECTIVE && row?.sensor === 'SAR')
    ) {
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (
        productMode?.key === 'Area'
      ) {
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].resolution[1].disable = true;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      } else {
        this.AVAILABLESENSORS[indx].resolution[0].disable = true;
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[1];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[1].value;
      }
    }

    else if (
      (row?.key === OPERATORS.ISI && row?.sensor === 'Stereo')
    ) {
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (
        productMode?.key != 'Bundle'
      ) {
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].resolution[1].disable = true;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      } else {
        this.AVAILABLESENSORS[indx].resolution[0].disable = true;
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[1];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[1].value;
      }
    }

    if (
      row?.key === OPERATORS.CAPELLA
    ) {
      if (productMode?.mode_type === 'spotlight_ultra') {
        this.AVAILABLESENSORS[indx].selectedResolutionData = null;
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key === 1) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }

      } else if (productMode?.mode_type === 'spotlight') {
        this.AVAILABLESENSORS[indx].selectedResolutionData = null;

        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key === 2) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      }
    }

    this.AVAILABLESENSORS[indx].selectedModes = productMode;
    this.getTooltip(productMode, indx, row);
    const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
      (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
    );
    if (productMode?.length) {
      this.spot = false;
      this.strip = false;
      if (productMode[0]?.key.includes('Spot') && row?.key === OPERATORS.STE) {
        this.spot = true;
      } else if (row?.key === OPERATORS.STE) {
        this.strip = true;
      }

      this.AVAILABLESENSORS[indx].selectedMode = productMode[0].key;

      if (productMode[0]?.isCaptureMode && !productMode[0]?.isProductLevel) {
        this.AVAILABLESENSORS[indx].selectedMode = productMode[0].captureMode;
      } else if (
        productMode[0]?.isProductLevel &&
        !productMode[0]?.isCaptureMode
      ) {
        this.AVAILABLESENSORS[indx].selectedMode = productMode[0].productLevel;
      } else if (
        productMode[0]?.isProductLevel &&
        productMode[0]?.isCaptureMode
      ) {
        this.AVAILABLESENSORS[indx].selectedMode =
          productMode[0].captureMode + ' (' + productMode[0].productLevel + ')';
      }

      this.getTooltip(productMode[0], indx, row);
    } else if (productMode.length === 0) {
      this.spot = false;
      this.strip = false;
      this.getTooltip(row?.collection_mode[0], indx, row);
      this.AVAILABLESENSORS[indx].selectedModes = [];
      this.AVAILABLESENSORS[indx].selectedModes =
        this.AVAILABLESENSORS[indx].modeSelected;
    } else {
      this.AVAILABLESENSORS[indx].selectedModes = [];
      this.AVAILABLESENSORS[indx].selectedModes.push(productMode);
    }

    if (row?.key === OPERATORS.UMBRA) {
      let looks: any;
      this.AVAILABLESENSORS[indx].selectedLooks = null;
      this.AVAILABLESENSORS[indx].selectedONA =
        this.AVAILABLESENSORS[indx].ONA[0].key;
      this.AVAILABLESENSORS[indx].selectedONAOption =
        this.AVAILABLESENSORS[indx].ONA[0];
      this.AVAILABLESENSORS[indx].selectedItem = null;
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      this.AVAILABLESENSORS[indx].selectedResolution =
        this.AVAILABLESENSORS[indx].resolution[0];
      this.AVAILABLESENSORS[indx].selectedResolutionValue =
        this.AVAILABLESENSORS[indx].resolution[0].value;
      this.AVAILABLESENSORS[indx].selectedLooksValue = null;
      looks = this.AVAILABLESENSORS[indx].selectedResolution.numberOfLooks[0];

      if (productMode?.value === '5km x 10km') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].ONA.entries()) {
          if (index > 2) {
            items.disabled = true;
          } else items.disabled = false;
        }
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          items.disabled = false;
        }
      } else if ((productMode?.value === '10km x 10km') || (productMode?.value === '10km x 10km(CPHD)')) {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].ONA.entries()) {
          if (index > 0) {
            items.disabled = true;
          } else items.disabled = false;
        }

        if (
          !this.AVAILABLESENSORS[indx].resolution[0].value.includes('looks')
        ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key != 3 && items.key != 4) {
              items.disabled = true;
            } else items.disabled = false;
          }

          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 3 || items.key === 4) {
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value;
              break;
            }
          }
        }
      } else if (productMode?.value === 'SAR Video') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key != 4) {
            items.disabled = true;
          } else {
            items.disabled = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value;
            looks = this.AVAILABLESENSORS[indx].selectedResolution.numberOfLooks[4];
            for (const [index, item] of this.AVAILABLESENSORS[
              indx
            ].selectedResolution.numberOfLooks.entries()) {
              if (item === 'x5') {
                this.AVAILABLESENSORS[indx].selectedLooks = item;
                this.AVAILABLESENSORS[indx].numberOfLooks = item;
              }
            }
          }
        }
      }
      else {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].ONA.entries()) {
          items.disabled = false;
        }
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          items.disabled = false;
        }
      }

      let sceneSize = this.AVAILABLESENSORS[indx].selectedModes[0].value;
      this.AVAILABLESENSORS[indx].numberOfLooks = looks;

      row.latency[0].cost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      row.latency[0].unitcost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[0].name, indx, row, 1);
    }

    if (row?.key === OPERATORS.BLACKSKY) {
      if (productMode.sceneSize)
        this.AVAILABLESENSORS[indx].minarea = productMode.sceneSize;
    }

    if (row?.key === OPERATORS.GHG) {
      const selectedIndex = this.AVAILABLESENSORS[indx].latency.findIndex(
        (item) => item.selected === true
      );
      row.latency = row.latency.map(latencyItem => {
        const matchingMode = row.selectedModes[0].cost.find(mode => mode[latencyItem.key] !== undefined);
        return {
          ...latencyItem,
          cost: matchingMode ? matchingMode[latencyItem.key] : null,
          unitcost: matchingMode ? matchingMode[latencyItem.key] : null,
        };
      });
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    }

    if (row?.key === OPERATORS.STE && row?.sensor === 'MSI') {
      this.resetCost(row, this.AVAILABLESENSORS[indx].selectedResolution, indx)
    }

    if (row?.key === OPERATORS.CAPELLA || row?.key === OPERATORS.BLACKSKY || row?.key === OPERATORS.GOKTURK
      || row?.key === OPERATORS.ECURS
    ) {
      let pricingArray = row.selectedModes[0]?.pricing;
      const selectedIndex = this.AVAILABLESENSORS[indx].latency.findIndex(
        (item) => item.selected === true
      );
      for (const latencyItem of this.AVAILABLESENSORS[indx].latency) {
        const key = latencyItem.key;
        if (pricingArray.hasOwnProperty(key)) {
          const newCost = Number(pricingArray[key]);
          latencyItem.cost = newCost;
          latencyItem.unitcost = newCost;
        }
      }

      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    }

    let minimumArea: any;
    if ((row.unit === 'scene' || row.unit === 'week' || row.unit === 'day') && row.key != OPERATORS.BLACKSKY) {
      if (row.tooltipValue) {
        minimumArea = this.extractAndCalculateValues(row.tooltipValue);
        this.AVAILABLESENSORS[indx].minimumAreaSize = minimumArea;
      } else if (row.minimumArea) {
        minimumArea = this.extractAndCalculateValues(row.minimumArea);
        this.AVAILABLESENSORS[indx].minimumAreaSize = minimumArea;
      }
    } else {
      minimumArea = row.minarea;
      this.AVAILABLESENSORS[indx].minimumAreaSize = minimumArea;
    }

    if ((row?.key === OPERATORS.HEAD) || row?.key === OPERATORS.GOKTURK || row?.key === OPERATORS.GHG || row?.key === OPERATORS.ECURS) {
      this.resetResolution(productMode, indx, row?.key);
    }

    if (this.AVAILABLESENSORS[indx].selectedResolution && this.AVAILABLESENSORS[indx].selectedResolution?.bands) {
      this.AVAILABLESENSORS[indx].sensorTooltip = this.AVAILABLESENSORS[indx].selectedResolution.bands;
    }

    this.resetValueAddedDropdown(indx, row);
  }

  resetResolution(productMode, indx, operatorKey) {
    this.AVAILABLESENSORS[indx].selectedResolutionData = null;
    let item = this.AVAILABLESENSORS[indx];
    if ((productMode?.key === 'L3C' && operatorKey === OPERATORS.HEAD && item.sensor != 'HSI') ||
      (productMode?.productLevel === 'L2B' && operatorKey === OPERATORS.GOKTURK && productMode?.key.includes('pan_sharpened'))) {
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('Pan-sharpened')) {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = items;
          this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
        } else {
          items.disable = true;
        }
      }
    } else if ((productMode?.key === 'L3D' && operatorKey === OPERATORS.HEAD)) {
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('TCI')) {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = items;
          this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
        } else {
          items.disable = true;
        }
      }
    } else if ((productMode?.key != 'L3D' && operatorKey === OPERATORS.HEAD && item.sensor === 'HSI')) {
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('TCI')) {
          items.disable = true;
        } else {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = item.resolution[1];
          this.AVAILABLESENSORS[indx].selectedResolutionValue = item.resolution[1].value
        }
      }
    } else if (operatorKey === OPERATORS.AT21) {
      if (productMode?.productLevel === 'L1') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Bundle')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.productLevel === 'L4'
      ) {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Pan-sharpened')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Both')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      }
    } else if (operatorKey === OPERATORS.ECURS) {
      if (productMode.resolution) {
        const productResolutions = productMode.resolution;
        for (const [index, items] of this.AVAILABLESENSORS[indx].resolution.entries()) {
          // Check if the resolution exactly matches any value in productMode.resolution
          let isMatched = false;

          for (const res of productResolutions) {
            if (items.value === res) {
              isMatched = true;
              break;
            }
          }

          if (isMatched) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value;
          } else {
            items.disable = true;
          }
        }
      }
      
      else if (productMode?.mode === 'SL') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key === 1) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.mode === 'SM'
      ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 2) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }  
      } else if (
        productMode?.mode === 'NSS'
      ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 3) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }  
      } else {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 4) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }
      }
    } else if (operatorKey === OPERATORS.GHG) {
      if (productMode?.productLevel.includes('Onshore')) {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value === '25m') {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.productLevel.includes('Offshore')
      ) {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value === '30-80m') {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      }
    } else {
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('Bundle')) {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = items;
          this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
        } else {
          items.disable = true;
        }
      }
    }
  }

  async resolutionChange(resolution: any, indx: number, row: any) {
    if (row?.key === OPERATORS.UMBRA) {
      this.AVAILABLESENSORS[indx].selectedLooks = null;
      let sceneSize = this.AVAILABLESENSORS[indx].selectedModes[0].value;
      let looks = resolution.numberOfLooks[0];
      this.AVAILABLESENSORS[indx].numberOfLooks = looks;

      if (sceneSize === 'SAR Video') {
        for (const [index, item] of this.AVAILABLESENSORS[
          indx
        ].selectedResolution.numberOfLooks.entries()) {
          if (item === 'x5') {
            this.AVAILABLESENSORS[indx].selectedLooks = item;
            this.AVAILABLESENSORS[indx].numberOfLooks = item;
            looks = item;
          }
        }
      }

      row.latency[0].cost =
        resolution?.sceneSizePricing[sceneSize][looks];
      row.latency[0].unitcost =
        resolution?.sceneSizePricing[sceneSize][looks];
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[0].name, indx, row, 1);
    }

    if ((row?.key === OPERATORS.STE && row?.sensor === 'MSI')) {
      this.resetCost(row, resolution, indx);
    }

    this.AVAILABLESENSORS[indx].selectedResolution = resolution;
    this.AVAILABLESENSORS[indx].selectedResolutionValue = resolution.value;

    if (resolution?.bands) {
      this.AVAILABLESENSORS[indx].sensorTooltip = resolution.bands;
    }

    if (row?.key === OPERATORS.AXELSPACE || row?.key === OPERATORS.AT21) {
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx, idx);
      }
    }

    this.resetValueAddedDropdown(indx, row);
  }

  resetCost(row, resolution, indx) {
    const selectedIndex = this.AVAILABLESENSORS[indx].latency.findIndex(
      (item) => item.selected === true
    );

    if ((row.key === OPERATORS.STE && resolution.value.includes('Bundle')
    )) {
      let pricingArray = this.AVAILABLESENSORS[indx].selectedModes[0]?.pricing;
      for (const latencyItem of this.AVAILABLESENSORS[indx].latency) {
        const key = latencyItem.key;
        if (pricingArray.hasOwnProperty(key)) {
          const newCost = Number(pricingArray[key]);
          latencyItem.cost = newCost;
          latencyItem.unitcost = newCost;
        }
      }
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    } else if (row?.key === OPERATORS.STE && row?.sensor === 'MSI'
      && resolution.value.includes('Pan-sharpened')) {
      let costSTE = this.AVAILABLESENSORS[indx].STElatency[0];
      row.latency[0].cost = costSTE[0].cost;
      row.latency[0].unitcost = costSTE[0].unitcost;
      row.latency[1].cost = costSTE[1].cost;
      row.latency[1].unitcost = costSTE[1].unitcost;
      this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    // Convert values to comparable types
    const aValue = typeof a === 'string' ? parseFloat(a) : a;
    const bValue = typeof b === 'string' ? parseFloat(b) : b;

    // Handle cases where one value is NaN (string couldn't be converted to number)
    if (isNaN(aValue) && !isNaN(bValue)) {
      return isAsc ? 1 : -1; // Strings come after numbers
    }
    if (!isNaN(aValue) && isNaN(bValue)) {
      return isAsc ? -1 : 1; // Numbers come before strings
    }

    // Both values are either numbers or strings, compare them
    return (aValue < bValue ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareArrays(
    array1: any[],
    array2: any[],
    property: string,
    isAsc: boolean
  ): number | string {
    // Compare the arrays by the specified property
    for (let i = 0; i < Math.min(array1.length, array2.length); i++) {
      const value1 = array1[i][property];
      const value2 = array2[i][property];

      const comparisonResult =
        parseFloat(value1.match(/\d+(\.\d+)?/g)[0]) -
        parseFloat(value2.match(/\d+(\.\d+)?/g)[0]);

      if (comparisonResult !== 0) {
        return comparisonResult * (isAsc ? 1 : -1);
      }
    }

    // If all compared elements are equal, compare the lengths of the arrays
    return (array1.length - array2.length) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.AVAILABLESENSORS.slice();
    if (!sort.active || sort.direction === '') {
      this.AVAILABLESENSORS = data;
      return;
    }

    this.AVAILABLESENSORS = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'operator':
          return this.compare(a.name, b.name, isAsc);
        case 'resolution':
          return this.compareArrays(a.resolution, b.resolution, 'value', isAsc);
        case 'area_calculated':
          return this.compare(a.minarea, b.minarea, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
  }

  extractAndCalculateValues(inputString: string): number {
    // Match decimal or integer numbers
    const numbers = inputString.match(/\d+(\.\d+)?/g);

    if (numbers && numbers.length >= 2) {
      const firstNumber = parseFloat(numbers[0]);
      const secondNumber = parseFloat(numbers[1]);
      return firstNumber * secondNumber;
    }
    return 0;
  }


  getPlaceHolder(row: any, indx: any) {
    if (
      row.collection_mode[0]?.isCaptureMode &&
      !row.collection_mode[0]?.isProductLevel
    ) {
      return row.collection_mode[0].captureMode;
    } else if (
      row.collection_mode[0]?.isProductLevel &&
      !row.collection_mode[0]?.isCaptureMode
    ) {
      return row.collection_mode[0].productLevel;
    } else return `${row.collection_mode[0].captureMode} (${row.collection_mode[0].productLevel})`
  }

  getTooltip(row: any, indx: any, sensor: any) {
    this.AVAILABLESENSORS[indx].tooltipValue = row?.minTooltip;
    this.AVAILABLESENSORS[indx].minimumArea = row?.value;
  }

  onMouseOut() {
    this.tooltipDisabled = true;
    this.detailsText = '';
  }

  OpenPopup(row: any) {
    let sensorName = row?.displayName + ' ' + row.sensor;
    let resolution: any, name: any;
    if (row?.resolution) {
      if (row?.resolution[0]?.previewvalue) {
        resolution = row?.resolution[0]?.previewvalue;
      } else resolution = row?.resolution[0]?.value;
      name = row?.imageryname;
    }
    let mode: any;
    if (row?.collection_mode) {
      if (row?.collection_mode[0]?.previewmode) {
        mode = row?.collection_mode[0].previewmode;
      } else if (row?.collection_mode[0]?.tooltip) {
        mode = row?.collection_mode[0].tooltip;
      } else mode = row?.collection_mode[0].captureMode;
    }
    let height: any, width: any;
    if (row.sensor === 'Video') {
      height = '75%';
      width = '65%';
    }
    else {
      height = '85%';
      width = '60%';
    }

    this.dialog.open(SensorpreviewComponent, {
      width: width,
      height: height,
      data: {
        sensor: sensorName,
        resolution: resolution,
        imageName: name,
        mode: mode,
        operatorKey: row.key,
        sensorType: row.sensor
      },
      backdropClass: 'blurred',
      disableClose: true,
    });
  }

  shouldDisableTier(elem: any, row: any) {
    const subDailyRecurrent = this.capellaEnable && row.sensor != 'SAR';
    const subdailyEnable = this.satCapeEnable && row.sensor != 'SAR' && row?.key != this.OPERATOR.SATELLOGIC && row?.key != this.OPERATOR.STE && row?.key != this.OPERATOR.USL && row?.key != this.OPERATOR.BLACKSKY;

    return elem.cost == 0.00 || (row.prevent && row?.key != OPERATORS.AT21) || subDailyRecurrent || subdailyEnable;
  }

  shouldDisableResolution(item: any, row: any, index: number) {
    const disableAT21 = item.disable && row?.key === OPERATORS.AT21;
    const disableSTE =
      item.disable &&
      row?.key === OPERATORS.STE &&
      row.resolution?.length >= 1 &&
      row?.sensor === 'SAR';
    const umbra = row?.key === OPERATORS.UMBRA && item.disabled;
    const disableSynspective = item.disable && row?.key === OPERATORS.SYNSPECTIVE;
    const isi = row?.key === OPERATORS.ISI && item.disable && row.resolution?.length > 1;
    const capella = row?.key === OPERATORS.CAPELLA && item.disable && row.resolution?.length > 1;
    const head = row?.key === OPERATORS.HEAD && item.disable && row.resolution?.length > 1;
    const sensor = (row?.key === OPERATORS.GOKTURK || row?.key === OPERATORS.GHG || row?.key === OPERATORS.ECURS) && item.disable && row.resolution?.length > 1;

    return row.prevent || disableAT21 || disableSTE || umbra || disableSynspective || isi || capella || head || sensor;
  }

  resetSorting() {
    if (this.sort) {
      this.sort.sort({ id: '', start: 'asc', disableClear: false });
    }
  }

  openEula(task: any, operator: any) {
    this.operatorsEula = {
      name: operator.displayName,
      operatorKey: operator?.key,
      resolution: operator?.resolution,
      startDate: task?.openWindow,
      endDate: task?.closeWindow,
      purpose: task?.purposeOption,
    };

    const dialogRef = this.dialog.open(AgreeComponent, {
      width: '65%',
      backdropClass: 'blurred',
      disableClose: true,
      data: {
        operator: this.operatorsEula,
        type: 'save',
        user: this.currentUser,
      },
    });
  }

  selections(row: any) {
    this.tooltipDisabled = false;
    if (row.key === OPERATORS.SPIRE) {
      this.detailsText = 'No cancelation is permitted once refresh is ordered';
    } else if (row.key === OPERATORS.CLYDE) {
      this.detailsText = "The sensor can be canceled anytime after two months with one month's" + '\n' + "notice on the minimum subscription of three months. Similarly," + '\n' + "all subscriptions require one month's notice.";
    } else {
      for (var i = 0; i < row.cancelPolicy.length; i++) {
        this.detailsText +=
          'Cancelation ' +
          row.cancelPolicy[i].key +
          ' : ' +
          row.cancelPolicy[i].value +
          '\n';
      }
    }
  }

  getAxelPrice(size, pricing) {
    if (size < 100) {
      return pricing[0].unitPrice;
    }

    else {
      for (const range of pricing) {
        if (size >= range.minArea && (range.maxArea === undefined || size <= range.maxArea)) {
          return range.unitPrice;
        }
      }
    }
    return null;
  }


  convertCostToPoints(cost: any, includeLabel: boolean = true, row: any = {}): string | number {
    cost = parseFloat(cost);

    if (!this.currentUser.pointsEligible) {
      if (this.dem_calculation) {
        cost += parseFloat(row?.demTotalPrice ?? 0);
      }
      if (this.enable_indices_calculation && row?.indices) {
        cost += parseFloat(row?.TotalIndicesPrice ?? 0);
      }
      if (this.enable_sr_calculation && row?.super_resolution) {
        cost += parseFloat(row?.super_resolution[0]?.cost ?? 0);
      }
      return `${this.currencySign} ${this.roundNumber(cost, 2)}`;
    }

    const conversionFactor = this.getConversionFactor();
    let points = Math.round(cost * conversionFactor);

    if (this.dem_calculation) {
      points += parseFloat(row?.demTotalPoint ?? 0);
    }
    if (this.enable_indices_calculation && row?.indices) {
      cost += parseFloat(row?.TotalIndicesPoint ?? 0);
    }
    if (this.enable_sr_calculation && row?.TotalSRPoint) {
      points += parseFloat(row?.TotalSRPoint ?? 0);
    }
    return includeLabel ? `${points} Points` : points;
  }

  private getConversionFactor(): number {
    const parts = this.currentUser.sensorConversionFactor.split(':');
    return parseFloat(parts[1]) / parseFloat(parts[0]);
  }

  roundNumber(value: number, decimals: number): string {
    if (Number.isInteger(value)) {
      return value.toString();
    }
    return value.toFixed(decimals).replace(/\.?0+$/, '');
  }

  async looksChange(look: any, indx: number, row: any) {
    this.AVAILABLESENSORS[indx].numberOfLooks = look;
    if (row.key === OPERATORS.UMBRA) {
      let sceneSize = this.AVAILABLESENSORS[indx].selectedModes[0].value;
      let looks = this.AVAILABLESENSORS[indx].numberOfLooks ? this.AVAILABLESENSORS[indx].numberOfLooks :
        this.AVAILABLESENSORS[indx].selectedResolution.numberOfLooks[0];

      row.latency[0].cost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      row.latency[0].unitcost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[0].name, indx, row, 1);
    }

  }
  calculateDemTotal(row: any[], idx: any) {
    if (!row || !row.length) {
      this.AVAILABLESENSORS[idx].demTotalPrice = 0;
      this.AVAILABLESENSORS[idx].demTotalPoint = 0;
      return;
    }

    // Calculate total price and points for the entire row
    const demTotalPrice = row.reduce((total, curr) => total + Number(curr.cost), 0);
    const demTotalPoint = this.currentUser.pointsEligible ?
      row.reduce((total, curr) => total + Number(curr.point), 0) : 0;

    // Push totals into this.AVAILABLESENSORS[idx]
    this.AVAILABLESENSORS[idx].demTotalPrice = demTotalPrice;
    this.AVAILABLESENSORS[idx].demTotalPoint = demTotalPoint;
  }
  formatGroupedDem(val: any[]) {
    if ((val || []).length > 0) {
      const uniqueProviders = new Set<string>(
        val
          .filter(item => item && typeof item === 'object')
          .map(item => item.actionProvider)
      );

      let result = Array.from(uniqueProviders).join(', ');
      if (result.length > 10) {
        result = result.slice(0, 10) + '...';
      }

      return result || undefined;
    }
    return;
  }

  formatGroupedDemTooltip = (val: any[]): any => {
    if (val?.length) {
      return val
        .filter(item => typeof item === 'object' && item !== null)
        .map(item => {
          return item.valueAddedDataProduct === 'indices'
            ? `${item.actionProvider}`
            : `${item.actionProvider} ${item.providerResolution}`;
        })
        .join(', ');
    }
    return '';
  };

  formatVAR(arr: any[]) {
    if ((arr || []).length === 0)
      return {};

    const groupedDemOptions = arr.reduce((acc, curr) => {
      // Check if group already exists, if not, initialize it as an empty array
      if (!acc[curr.actionProvider]) {
        acc[curr.actionProvider] = [];
      }
      // Push current item into the corresponding group array
      acc[curr.actionProvider].push(curr);
      return acc;
    }, {});
    return groupedDemOptions;
  }

  formatvalueAddedOption(sensor: any, mode: any, resolution: any) {
    if (sensor.valueAddedOption) {
      const DxM = sensor.valueAddedOption.filter((i) => this.actionProviders.includes(i.actionProvider));
      DxM.sort((a, b) => {
        const actionA = a.actionProvider || '';
        const actionB = b.actionProvider || '';
        return actionA.localeCompare(actionB);
      });

      const options = DxM.filter((i) => i.sensorResolution === resolution.resolution_value || i.sensorResolution === resolution.value);
      return this.toggleValueAddedOptions(options)
    }
    return [];
  }
  updatevalueAddedOption(newValue: any[], row: any, idx: number) {
    const updatedValue = (newValue || []).filter((item, index, arr) => {
      const existingIndex = arr.findIndex(existingItem =>
        existingItem.actionProvider === item.actionProvider &&
        existingItem.ProviderName === item.ProviderName &&
        existingItem.sensorResolution === item.sensorResolution
      );
      return existingIndex === index;
    });

    row.valueAddedOption = updatedValue;
    this.calculateDemTotal(row.valueAddedOption, idx);
  }
  selectDxMOption(dem_item: any, row: any, group: any, idx: number): void {
    if (row.key === 'ISI' && row.selectedModes[0].key === 'Pan-sharpened') {
      this.AVAILABLESENSORS[idx].selectedMode = row?.collection_mode[0].key;
      this.AVAILABLESENSORS[idx].selectedModes = [];
      this.AVAILABLESENSORS[idx].modeSelected = [];
      this.AVAILABLESENSORS[idx].modeResetValue = [];

      this.AVAILABLESENSORS[idx].selectedModes.push(row?.collection_mode[0]);
      this.AVAILABLESENSORS[idx].modeSelected.push(row?.collection_mode[0]);
    }
    if (!this.AVAILABLESENSORS[idx].valueAddedOption) {
      this.AVAILABLESENSORS[idx].valueAddedOption = [];
    }

    // Check if the dem_item is already selected
    const isSelected = row.valueAddedOption.some(item =>
      item.actionProvider === dem_item.actionProvider &&
      item.ProviderName === dem_item.ProviderName &&
      item.sensorResolution === dem_item.sensorResolution &&
      item.providerResolution === dem_item.providerResolution
    );

    if (isSelected) {
      // If already selected, unselect it by removing from valueAddedOption
      row.valueAddedOption = row.valueAddedOption.filter((item: any) => item !== dem_item);
    } else {
      // If not selected, remove any previously selected item from the same group
      row.valueAddedOption = row.valueAddedOption.filter((item: any) =>
        item.actionProvider !== group.actionProvider
      );

      // Add the newly selected item
      row.valueAddedOption.push(dem_item);
    }

    // Update the AVAILABLESENSORS with the new selection
    this.AVAILABLESENSORS[idx].valueAddedOption = row.valueAddedOption;

    // Reset VADP and update the valueAddedOption
    this.resetVADP(row.valueAddedOption, idx);
    this.updatevalueAddedOption(row.valueAddedOption, row, idx);
  }

  filterValueAddedData(sensor: any) {
    if (sensor?.valAddedArray) {
      const options = (sensor?.valAddedArray || []).filter((i) => i.sensorResolution === sensor.selectedResolutionValue || i.sensorResolution === sensor.selectedResolution.value)
      return this.toggleValueAddedOptions(options);
    }
    return [];
  }
  toggleValueAddedOptions(data: any) {
    let resultMap: { actionProvider: string, items: { providerName: string, actionProvider: string, providerResolution: string, cost: string, point: string, deliveryTime: string, sensorResolution: string }[] }[] = [];

    data.forEach(item => {
      let actionProvider = item.actionProvider;
      item.point = this.convertCostToPoints(item.cost, false);

      let existingactionProvider = resultMap.find(entry => (entry || []).actionProvider === actionProvider);
      if (existingactionProvider) {
        existingactionProvider.items.push(item);
      } else {
        resultMap.push({
          actionProvider: actionProvider,
          items: [item]
        });
      }
    });
    return resultMap;
  }
  resetValueAddedDropdown(indx, row) {
    if (row.sensor === 'Stereo' || row.sensor === 'Tristereo') {
      this.AVAILABLESENSORS[indx].demTotalPrice = 0;
      this.AVAILABLESENSORS[indx].demTotalPoint = 0;
      this.AVAILABLESENSORS[indx].valueAddedOption = [];
      this.AVAILABLESENSORS[indx].vadp = [];
      this.AVAILABLESENSORS[indx].valueAddedAction = this.filterValueAddedData(this.AVAILABLESENSORS[indx]);
    }
  }

  // Function to handle collection duration change
  async collectionChange(data: any, idx: number, item: any, row: any) {
    // Update the collection duration of the selected signal
    if (data?.value?.value) {
      // Update the collection duration of the specific signal in the row
      const selectedSignal = row.signal_of_interest.find(signal => signal.value === item.value);
      if (selectedSignal) {
        selectedSignal.collectionDuration = data.value.value;
      }

      // Update the collection duration in AVAILABLESENSORS array
      const availableSignal = this.AVAILABLESENSORS[idx]?.signalsOfInterest;
      if (availableSignal && availableSignal.length > 0) {
        const signal = availableSignal.find(signal => signal.value === item.value);
        if (signal) {
          signal.collectionDuration = data.value.value;
        }

        // Update selected signals display text
        const signalsSelected = availableSignal.filter(sensor => {
          return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
        });
        this.AVAILABLESENSORS[idx].signalOfInterest = signalsSelected;
        this.AVAILABLESENSORS[idx].selectedSignals = signalsSelected.map(signal => signal.value).join(', ');
      }
    }
  }

  // Function to handle collection duration change
  async audioChange(event: MatCheckboxChange, item: any, idx: number, row: any) {
    if (this.AVAILABLESENSORS[idx].selectedSignals) this.AVAILABLESENSORS[idx].selectedSignals = '';
    // Update the collection duration of the selected signal
    const selectedSignal = row.signal_of_interest.find(signal => signal.value === item.value);
    if (selectedSignal) {
      selectedSignal.isAudio = event.checked;
      if (event.checked) {
        selectedSignal.collectionDuration = 60;
      } else selectedSignal.collectionDuration = selectedSignal.collection_duration[0].value;
    }

    const availableSignal = this.AVAILABLESENSORS[idx]?.signalsOfInterest;
    if (availableSignal && availableSignal.length > 0) {
      const signal = availableSignal.find(signal => signal.value === item.value);
      if (signal) {
        signal.isAudio = event.checked;
        if (event.checked) {
          signal.collectionDuration = 60;
        } else signal.collectionDuration = signal.collection_duration[0].value;
      }

      // Update selected signals display text
      const signalsSelected = availableSignal.filter(sensor => {
        return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
      });
      this.AVAILABLESENSORS[idx].signalOfInterest = signalsSelected;
      this.AVAILABLESENSORS[idx].selectedSignals = signalsSelected.map(signal => signal.value).join(', ');
    }
  }

  async signalChange(event: any, idx: number, mySelect?: MatSelect) {
    event.value = event.value.filter(item => item !== undefined);

    let selectedSignals = event.value;
    this.rfSignals = true;

    // If selected signals array is empty, reset and exit
    if (selectedSignals.length === 0) {
      this.AVAILABLESENSORS[idx].signalOfInterest = [];
      this.AVAILABLESENSORS[idx].signalsOfInterest = [];
      this.rfSignals = false;
      return;
    }

    // Keep the existing `signalOfInterest` values without resetting
    if (!this.AVAILABLESENSORS[idx].signalOfInterest || !Array.isArray(this.AVAILABLESENSORS[idx].signalOfInterest)) {
      this.AVAILABLESENSORS[idx].signalOfInterest = [];
      this.AVAILABLESENSORS[idx].signalsOfInterest = [];
    }

    const previousSelectedSignals = [...this.AVAILABLESENSORS[idx].signalsOfInterest];

    // Check selection validity
    if (this.checkSelection(selectedSignals, idx)) {
      selectedSignals = this.processSignals(previousSelectedSignals, selectedSignals);
      this.AVAILABLESENSORS[idx].signalsOfInterest = selectedSignals;

    } else {
      // Invalid selection: remove the newly selected item and call signalChange again
      selectedSignals = previousSelectedSignals;
      this.AVAILABLESENSORS[idx].signalsOfInterest = selectedSignals;

      // Set a timeout to refresh UI
      setTimeout(() => {
        if (mySelect) {
          mySelect.value = selectedSignals;
          this.signalChange({ value: selectedSignals }, idx, mySelect);
        }
      }, 0);
      return;
    }

    // Update selected signals for display    
    const signalsSelected = this.AVAILABLESENSORS[idx].signalsOfInterest.filter(sensor => {
      return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
    });
    this.AVAILABLESENSORS[idx].signalOfInterest = signalsSelected;
    this.AVAILABLESENSORS[idx].selectedSignals = signalsSelected.map(signal => signal.value).join(', ');
  }

  processSignals(previousSignals: any[], newSignals: any[]): any[] {
    return newSignals.map(newSignal => {
      const matchingPrevSignal = previousSignals.find(prevSignal => prevSignal.value === newSignal.value);

      // Preserve the collectionDuration from the previous selection if it exists
      if (matchingPrevSignal && matchingPrevSignal.collectionDuration) {
        newSignal.collectionDuration = matchingPrevSignal.collectionDuration;
      } else if (newSignal.collection_duration && newSignal.collection_duration.length > 0 && !newSignal.collectionDuration) {
        // If no previous duration exists, use the default
        newSignal.collectionDuration = newSignal.collection_duration[0].value;
      }

      newSignal.checked = true;
      return newSignal;
    });
  }

  checkSelection(selectedItems: any[], index: number): boolean {
    let selectedSignals = selectedItems.filter(sensor => {
      return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
    });
    // Define categories
    const gps = ['GPS L1', 'GPS L2', 'GPS L5'];
    const vhf = ['VHF'];
    const uhf = ['UHF'];
    const lBand = ['L-band (ADS-B)', 'L-band (GNSS)', 'L-band (SATCOM)'];

    // Separate signals into categories
    const selectedGPS = selectedSignals.filter(s => gps.includes(s.value));
    const selectedVHF = selectedSignals.filter(s => vhf.includes(s.value));
    const selectedUHF = selectedSignals.filter(s => uhf.includes(s.value));
    const selectedLBand = selectedSignals.filter(s => lBand.includes(s.value));

    // Rule: Only one GPS signal can be selected at a time
    if (selectedGPS.length > 1) {
      const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      return false;
    }

    // Check for invalid combinations
    if (selectedGPS.length > 0) {
      if (selectedSignals.length > selectedGPS.length) {
        const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        return false;
      }
    } else {
      // Validate non-GPS selections
      if (selectedVHF.length + selectedUHF.length + selectedLBand.length > 3) {
        const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        return false;
      }
      if (selectedVHF.length > 1 || selectedUHF.length > 1 || selectedLBand.length > 1) {
        const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        return false;
      }
    }

    return true;
  }

  onSelectOpened(index: number) {
    if (this.AVAILABLESENSORS[index].signalOfInterest)
      this.initialSelection = [...this.AVAILABLESENSORS[index].signalOfInterest];
  }

  onSelectClosed(index: number) {
    const currentSelection = this.AVAILABLESENSORS[index].signalOfInterest;

    const isSelectionChanged = JSON.stringify(this.initialSelection) !== JSON.stringify(currentSelection);

    if (isSelectionChanged) {
      this.AVAILABLESENSORS[index].isAudio = false;
      const isVHFUHF = this.AVAILABLESENSORS[index].signalOfInterest?.some(item => (item.value === 'VHF'));

      if (isVHFUHF) {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          width: '50%',
          backdropClass: 'blurred',
          disableClose: true,
          data: { message: OPERATORS.LBAND }
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result === 'yes') {
            this.AVAILABLESENSORS[index].collectionDuration = 60;
            this.AVAILABLESENSORS[index].isAudio = true;
          } else {
            this.AVAILABLESENSORS[index].collectionDuration = this.AVAILABLESENSORS[index].collection_duration[0].value;
            this.AVAILABLESENSORS[index].isAudio = false;
          }
        });
      }
    }
  }

  costToPoints(cost: number): string {
    if (this.currentUser.pointsEligible) {
      const [part1, part2] = this.currentUser.sensorConversionFactor.split(':').map(parseFloat);
      const conversionFactor = part2 / part1;
      const points = Math.round(cost * conversionFactor);
      return `${points} Points`;
    } else {
      return `$${cost}`;
    }
  }

  onDxMCheckboxChange(row: any, idx: number, type: any): void {
    if (!row.isDxMSelected) {
      if (!this.AVAILABLESENSORS[idx].vadp) this.AVAILABLESENSORS[idx].vadp = [];
      this.AVAILABLESENSORS[idx].vadp.push(type);
      this.AVAILABLESENSORS[idx].isDxMSelected = true;
    } else {
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) => item !== type);
      this.AVAILABLESENSORS[idx].isDxMSelected = false;
    }
  }

  onSRCheckboxChange(row: any, idx: number, type: any): void {
    if (!row.isSRSelected) {
      if (!this.AVAILABLESENSORS[idx].vadp) this.AVAILABLESENSORS[idx].vadp = [];
      this.AVAILABLESENSORS[idx].vadp.push(type);
      this.AVAILABLESENSORS[idx].isSRSelected = true;
    } else {
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) => item !== type);
      this.AVAILABLESENSORS[idx].isSRSelected = false;
    }
  }
  resetVADP(row, idx) {
    // Remove items with actionProvider in the specified list
    this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) =>
      !this.actionProviders.includes(item.actionProvider)
    );

    row.forEach((i) => {
      this.AVAILABLESENSORS[idx].vadp.push(i)
    })
  }
  // Get selected values for a specific row
  getSelectedVadp(row: any): any[] {
    if (!row.vadp) {
      return [];
    }

    // Initialize arrays for special and regular values
    const specialValue = "SR";
    const vadpValue = "DxM";
    const indicesValue = "Indices";
    const filteredVadp = row.vadp.filter(item => item === specialValue);
    const regularItems = row.vadp.filter(item => item !== specialValue && (item || {}).actionProvider === 'Super Resolution');
    const DxMItems = row.vadp.filter(item => this.actionProviders.includes((item || {}).actionProvider));
    const IndicesItems = row.vadp.filter((item) => item.valueAddedDataProduct === 'indices');

    // Combine special value and regular items
    const updatedVadp = [specialValue, ...regularItems, vadpValue, ...DxMItems, indicesValue, ...IndicesItems];

    return updatedVadp;
  }
  setIndices(sensor:any, selectedResolution:any) {

    let options = [];
    if(sensor.valueAddedOption) {
      options = sensor.valueAddedOption.filter((i) => i.valueAddedDataProduct === 'indices');
      options.forEach((item: any) => {
        item.point = this.convertCostToPoints(item.cost, false);
      })
    }
    options = options.filter((i:any) => i.sensorResolution == selectedResolution.resolution_value || i.sensorResolution == selectedResolution.value
    || i.sensorResolution == selectedResolution?.originalResolution);
    return options;
  }
  onIndicesCheckboxChange(row: any, idx: number, type: any): void {
    if (!row.isIndicesSelected) {
      if (!this.AVAILABLESENSORS[idx].vadp) this.AVAILABLESENSORS[idx].vadp = [];
      this.AVAILABLESENSORS[idx].vadp.push(type);
      this.AVAILABLESENSORS[idx].isIndicesSelected = true;
    } else {
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) => item !== type);
      this.AVAILABLESENSORS[idx].isIndicesSelected = false;
    }
  }
  selectIndicesOption(obj: any, row: any, idx: number): void {
    if (!row.indices) {
      row.indices = [];
    }

    // Check if the actionProvider is already selected
    const isSelected = row.indices.some(item => item === obj);

    if (isSelected) {
      // If the resolution is already selected, unselect it by removing it from indices
      row.indices = row.indices.filter(item => item !== obj);
      obj.isIndices = false;

      // Remove it from AVAILABLESENSORS[idx].vadp as well
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => item !== obj);
    } else {
      // If the resolution is not selected, clear any previous selections and select the new one
      row.indices = [];
      row.indices.push(obj);
      obj.isIndices = true;

      // Remove all items with isIndices === true from AVAILABLESENSORS[idx].vadp
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => !item.isIndices);
      this.AVAILABLESENSORS[idx].vadp.push(obj);
    }
    this.calculateIndicesTotal(row.indices, idx);
  }
  calculateIndicesTotal(row: any[], idx: any) {
    if (!row || !row.length) {
      this.AVAILABLESENSORS[idx].TotalIndicesPrice = 0;
      this.AVAILABLESENSORS[idx].TotalIndicesPoint = 0;
      return;
    }

    // Calculate total price and points for the entire row
    const TotalIndicesPrice = row.reduce((total, curr) => total + Number(curr.cost), 0);
    const TotalIndicesPoint = this.currentUser.pointsEligible ?
      row.reduce((total, curr) => total + Number(curr.point), 0) : 0;

    // Push totals into this.AVAILABLESENSORS[idx]
    this.AVAILABLESENSORS[idx].TotalIndicesPrice = TotalIndicesPrice;
    this.AVAILABLESENSORS[idx].TotalIndicesPoint = TotalIndicesPoint;
  }
  displayVadpSelectTooltip(row:any)
  {
    if((row?.valueAddedOption && row?.valueAddedOption.length > 0) || row?.valueAddedAction.length > 0) {
      return '';
    }
    if((row.sensor === 'Stereo' || row.sensor === 'Tristereo') && (row.key == OPERATORS.AT21 || (row.key == OPERATORS.KOMPSAT &&  row.name != 'KOMPSAT-5'))) {
      if(row?.selectedResolution?.value == 'Bundle:0.5m PAN/2m MS' && row?.valueAddedAction.length == 0)
        return '';
      return 'Please select either panchromatic or bundle data products for value-added products.';
    }

    if (row.sensor === 'MSI' && row?.indicesArray?.length === 0 && (row.key === OPERATORS.AXELSPACE || 
      row.key === OPERATORS.AT21 || row.key === OPERATORS.STE || row.name === 'KOMPSAT-3'
    )) {
      return 'Indices products require multi-spectral data. Please choose the relevant resolution product meeting the requirement.';
    }
    else
    return '';
  }

  closeSelectDropdown(select: MatSelect) {
    select.close();
  }

  isOperatorWithSensor(row: any): boolean {
    const validOperators = [OPERATORS.CAPELLA, OPERATORS.UMBRA, OPERATORS.KOMPSAT, OPERATORS.STE, OPERATORS.ECURS];
    return validOperators.includes(row.key) && (row.sensor === 'SAR' || row.sensor === 'InSAR');
  }

  getTooltipText(row: any) {
    if(row.selectedModes[0].defaultPolarization) return 'Default polarisation is ' + row.selectedModes[0].defaultPolarization;
    else {
      switch (row.key) {
        case OPERATORS.CAPELLA:
          return 'Look Angle'+ '\n' + 'Default polarisation is ' + polarization[row.key];
        case OPERATORS.UMBRA:
          return 'Grazing Angle' + '\n' + 'Default polarisation is ' + polarization[row.key];
        default:
          return 'Default polarisation is ' + polarization[row.key];
      }
    }
  }

  selectSROption(resolution: any, row: any, idx: number): void {
    if (!row.super_resolution) {
      row.super_resolution = [];
    }

    // Check if the resolution is already selected
    const isSelected = row.super_resolution.some(item => item === resolution);

    if (isSelected) {
      // If the resolution is already selected, unselect it by removing it from super_resolution
      row.super_resolution = row.super_resolution.filter(item => item !== resolution);
      resolution.isSR = false;

      // Remove it from AVAILABLESENSORS[idx].vadp as well
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => item !== resolution);
    } else {
      // If the resolution is not selected, clear any previous selections and select the new one
      row.super_resolution = [];
      row.super_resolution.push(resolution);
      resolution.isSR = true;

      // Remove all items with isSR === true from AVAILABLESENSORS[idx].vadp
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => !item.isSR);
      this.AVAILABLESENSORS[idx].vadp.push(resolution);
    }
    this.calculateSRTotal(row.super_resolution, idx);
  }
  calculateSRTotal(row: any[], idx: any) {
    if (!row || !row.length) {
      this.AVAILABLESENSORS[idx].TotalSRPrice = 0;
      this.AVAILABLESENSORS[idx].TotalSRPoint = 0;
      return;
    }

    // Calculate total price and points for the entire row
    const TotalSRPrice = row.reduce((total, curr) => total + Number(curr.cost), 0);
    const TotalSRPoint = this.currentUser.pointsEligible ?
      row.reduce((total, curr) => total + Number(curr.point), 0) : 0;

    // Push totals into this.AVAILABLESENSORS[idx]
    this.AVAILABLESENSORS[idx].TotalSRPrice = TotalSRPrice;
    this.AVAILABLESENSORS[idx].TotalSRPoint = TotalSRPoint;
  }
  setSuperResolutions(sensor) {
    let options: any = [];
    if (sensor.valueAddedOption) {
      options = sensor.valueAddedOption.filter((i) => i.actionProvider === 'Super Resolution');
      options.forEach(item => {
        item.point = this.convertCostToPoints(item.cost, false);
      })
    }
    return options;
  }

  calculateUnitcost(indx, latencyIndx) {
    if (this.AVAILABLESENSORS[indx].ONAFactor == undefined) this.AVAILABLESENSORS[indx].ONAFactor = 1;
    if (this.AVAILABLESENSORS[indx].holdbackFactor == undefined) this.AVAILABLESENSORS[indx].holdbackFactor = 1;
    if (this.AVAILABLESENSORS[indx].cloudFactor == undefined) this.AVAILABLESENSORS[indx].cloudFactor = 1;

    const holdbackFactor = this.AVAILABLESENSORS[indx].holdbackFactor - 1;
    const cloudFactor = this.AVAILABLESENSORS[indx].cloudFactor - 1;
    const ONAFactor = this.AVAILABLESENSORS[indx].ONAFactor - 1;
    const resolutionFactor = this.AVAILABLESENSORS[indx].selectedResolution?.multiplier ?? 1;

    const baseCost = this.AVAILABLESENSORS[indx].latency[latencyIndx].unitcost * resolutionFactor;
    const holdbackValue = baseCost * holdbackFactor;
    const ccValue = baseCost * cloudFactor;
    const onaValue = baseCost * ONAFactor;

    this.AVAILABLESENSORS[indx].selectedCost = +baseCost + +holdbackValue + +ccValue + +onaValue;
    this.AVAILABLESENSORS[indx].unitcost = this.AVAILABLESENSORS[indx].selectedCost;

    if (
      this.AVAILABLESENSORS[indx].userCost > 0 &&
      this.AVAILABLESENSORS[indx].selectedCost > 0
    ) {
      this.AVAILABLESENSORS[indx].selectedCost =
        this.AVAILABLESENSORS[indx].selectedCost *
        this.AVAILABLESENSORS[indx].userCost;
    }
  }

  getProductTooltip(row: any) {
    if (row.selectedModes && row.selectedModes[0]) {
      const captureMode = row.selectedModes[0].captureMode;
      const productLevel = row.selectedModes[0].productLevel;
      const isProductLevel = row.selectedModes[0].isProductLevel;
      const isCaptureMode = row.selectedModes[0].isCaptureMode;

      const tooltipText = isProductLevel && isCaptureMode
        ? `${captureMode} (${productLevel})` :
        isProductLevel && !isCaptureMode ? `${productLevel}`
          : captureMode;

      // Show tooltip only if the text length is greater than 10
      return tooltipText;
    }
  }
  setSignalOfInterest(signalOfInterest) {

    if (this.currentUser.pointsEligible) {
      signalOfInterest = signalOfInterest?.map((signal: any) => ({
        ...signal,
        point: this.convertCostToPoints(signal.cost, false),
        ...(signal.audioCost && { audioPoint: this.convertCostToPoints(signal.audioCost, false) }),
      }));
    }
    return signalOfInterest;
  }

  goNext(mySelect: MatSelect, event: Event, i, row): void {
    this.restoreMatSelectClose();
    event.stopPropagation(); // Prevent propagation
    mySelect.close(); // Close the dropdown
  }

  close(mySelect: MatSelect, event: Event): void {
    this.restoreMatSelectClose();
    event.stopPropagation(); // Prevent propagation
    mySelect.close(); // Close the dropdown
  }

  onOpenedChange(isOpen: boolean) {
    if (isOpen) {
      this.preventMatSelectClose();
    }
  }

  // Override the close behavior of MatSelect to prevent closing
  private preventMatSelectClose() {
    if (this.mySelects && !this.originalClose) {
      // Save the original close method
      this.originalClose = this.mySelects.close;

      // Override the close method
      this.mySelects.close = () => {
      };
    }
  }

  // Restore the original close behavior
  private restoreMatSelectClose() {
    if (this.mySelects && this.originalClose) {
      this.mySelects.close = this.originalClose;
      this.originalClose = null;
    }
  }

  shouldDisableModes(row) {
    let capella = (row.prevent && row?.key != OPERATORS.CAPELLA) || (this.capellaEnable && row.sensor != 'SAR');

    return capella;
  }


  getBandInfo(row: any) {
    this.openMenu();  
    let bandInfo = [];
    if (row.key === OPERATORS.WYVERN) {
      const key = `${row.key}_${row.numberOfBands}`;
      bandInfo = SENSOR_BAND_DATA[key] || [];
    } else if (row.sensorBands) bandInfo = row.sensorBands;
    else bandInfo = SENSOR_BAND_DATA[row.key] || [];
    this.bandInfoTooltip = bandInfo;
    this.selectedOperator = row.key;   
  }

  openMenu() {
    // Add a custom class to the menu panel when it opens
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      overlayContainer.classList.add('custom-menu-overlay');
    }
  }

  onMenuClosed(): void {
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      overlayContainer.classList.remove('custom-menu-overlay');
    }
  }

  selectBandOption(band: any, indx: number, row: any) {
    if (band) {
      this.AVAILABLESENSORS[indx].numberOfBands = band.no_of_bands;
      this.AVAILABLESENSORS[indx].sensorTooltip = band.tooltip;
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onSelectionChange(event: any, row: any,  idx:any): void {
    this.calculateSRTotal(row.super_resolution, idx);
  }

    OpenAdvance(row: any, index: any) {  
      const dialog = this.dialog.open(AdvanceSettingsComponent, {
        minWidth: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { selectedRow: row, type: 'advance', openFrom: 'preview' },
      });
    }
}
