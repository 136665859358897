import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { OPERATORS } from 'src/app/services/constants';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';
var geoParse = require('wellknown');


@Component({
  selector: 'app-operator-popup',
  templateUrl: './operator-popup.component.html',
  styleUrls: ['./operator-popup.component.scss']
})
export class OperatorPopupComponent implements OnInit {
  siteName: any;
  dataForm: FormGroup | any;
  formGroup: any = FormGroup;
  refreshFormGroup: FormGroup | any;
  isHistoricalChecked: boolean = false;
  name: string = '';
  OPERATOR: any;
  capellaFormGroup: any = FormGroup;
  satVuFormGroup: any = FormGroup;
  usersFormGroup: any = FormGroup;
  firstInput: number;
  secondInput: number;
  maxValue: boolean;
  wholeNumber: boolean;
  error: boolean;
  refreshError: boolean = true;
  focusedInput: 'start' | 'end' | null = null;
  pointArray: any = [];
  satVuCheckboxValid : boolean = false;
  nightMode:boolean = false;
  dayMode:boolean = false;
  darkTheme: boolean;
  tooltipImagePath: string;
  noOfUsers: any = [];
  selectedValue: any;
  userCount: boolean = false;
  noOfUsers21AT = [
    {value : '1', key: '1'},
    {value : '2 - 3', key: '1.2'},
    {value : '4 - 10', key: '1.4'}
  ];
  noOfUsersKompsat = [
    {value : '1 - 5', key: '1'},
    {value : '6 - 10', key: '1.2'},
    {value : '11 - 25', key: '1.35'}
  ];
  noOfUsersISI = [
    {value : '1', key: '1'},
    {value : '2 - 5', key: '1.1'},
    {value : '> 5', key: '1.2'}
  ];
  noOfUsersBlacksky = [
    {value : 'Commercial Single Organisation', key: '1'},
    {value : 'Commercial Multi Organisation (2-5)', key: '1.5'},
    {value : 'Commercial Multi Organisation (>5)', key: '2'},
    {value : 'Commercial Public Release', key: '3'},
    {value : 'Single Govt Agency', key: '1'},
    {value : 'All Domestic Agencies', key: '1.5'},
    {value : 'Foreign Partners', key: '2'},
    {value : 'Govt Public Release', key: '3'},
  ];
  
  noOfUsersCapella = [
    {value : '1', key: '1'},
    {value : '2 - 5', key: '1.25'},
    {value : '> 5', key: '1.5'}
  ];

  noOfUsersEcurs = [
    {value : 'Dual (1-2)', key: '1'},
    {value : 'Group (2-5)', key: '1.1'},
    {value : 'Enterprise (>5)', key: '1.2'}
  ];

  ImageWidth = [
    5,6,7,8,9,10
  ];

  noOfUsersGoturk = [
    {value : 'Academic', key: '0.8'},
    {value : '1 - 5', key: '1'},
    {value : '6 - 10', key: '1.15'},
    {value : '11 - 30', key: '1.25'},
    {value : '> 30', key: '1.5'}
  ];

  fetchError: boolean = true;
  historyFormGroup: FormGroup | any;
  activeUser: any;
  recurrent: boolean = false;
  fetchMessage: string;
  siteData: any = [];

  constructor(private dialogRef: MatDialogRef<OperatorPopupComponent>, private apiService: ApiService, private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: DataService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
    });
  }

  onInputFocus(inputName: 'start' | 'end') {
    this.focusedInput = inputName;
  }

  onInputBlur(inputName: 'start' | 'end') {
    this.focusedInput = null;
  }

  ngOnInit(): void {
    this.OPERATOR = OPERATORS;
    this.pointArray = this.data?.target; 
    if (this.data?.type === 'imageWidth') {
      this.usersFormGroup = this.formBuilder.group({
        imageWidth: new FormControl()
      });
    } else if (this.data.operatorKey === OPERATORS.WYVERN) {
      this.usersFormGroup = this.formBuilder.group({
        numOfBands: new FormControl()
      });
    } else if (this.data?.type === 'bandFilter') {
      this.usersFormGroup = this.formBuilder.group({
        bandType: new FormControl()
      });
    }
    else if ((this.data.operatorKey === OPERATORS.AT21 || this.data.operatorKey === OPERATORS.KOMPSAT || this.data.operatorKey === OPERATORS.ISI || this.data.operatorKey === OPERATORS.BLACKSKY || this.data.operatorKey === OPERATORS.CAPELLA
      || this.data.operatorKey === OPERATORS.GOKTURK || this.data.operatorKey === OPERATORS.ECURS) && this.data?.type === 'uplift') {
     this.userCount = true;
     if (this.data.operatorKey === OPERATORS.AT21) this.noOfUsers = this.noOfUsers21AT;
     else if (this.data.operatorKey === OPERATORS.KOMPSAT) this.noOfUsers = this.noOfUsersKompsat;
     else if (this.data.operatorKey === OPERATORS.ISI) this.noOfUsers = this.noOfUsersISI;
     else if (this.data.operatorKey === OPERATORS.BLACKSKY) this.noOfUsers = this.noOfUsersBlacksky;
     else if (this.data.operatorKey === OPERATORS.GOKTURK) this.noOfUsers = this.noOfUsersGoturk;
     else if (this.data.operatorKey === OPERATORS.CAPELLA) this.noOfUsers = this.noOfUsersCapella;
     else if (this.data.operatorKey === OPERATORS.ECURS) this.noOfUsers = this.noOfUsersEcurs;          
    } else this.userCount = false;
    
    if (this.data.operatorKey === OPERATORS.GHG || this.data.operatorKey === OPERATORS.LBAND) {
      this.formGroup = new FormGroup({
        sitename: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(25), Validators.pattern(/^[a-zA-Z0-9\s]+$/)]),
        daysCount: new FormControl(''),
      });

      this.setData();

      this.fetchError = false;
      this.fetchMessage = '';
      if (this.data.operatorKey === OPERATORS.LBAND) {
        this.recurrent = true;
        let maximumCollections: number = 1;
        if (this.data.frequency === 0) maximumCollections = 2;
        else if (this.data.frequency === 1 &&
          (this.data.repeatFrequency === 'Weekly' || this.data.repeatFrequency === 'Monthly'))
          maximumCollections = this.data?.numberOfRepeats * 2;
        else maximumCollections = this.data?.differenceInDays * 2;

        this.formGroup.valueChanges.subscribe(() => {
          const fetchValue = this.formGroup.get('daysCount').value;
          if ((fetchValue < 1 || !this.isPositiveWholeNumber(fetchValue))) {
            this.fetchError = true;
            this.fetchMessage = '';
          } else if (fetchValue > maximumCollections) {
            this.fetchError = true;
            this.fetchMessage = 'The maximum number of collections possible for the requested time of interest (TOI) is ' + maximumCollections+'.';
          } else  this.fetchError = false;
        });
      }
  
    } else if (this.data.operatorKey === OPERATORS.SATVU) {
      this.satVuFormGroup = this.formBuilder.group({
        dayMode: new FormControl(),
        nightMode: new FormControl()
      }, { validator: this.atLeastOneCheckedValidator });
    } else if (this.userCount) {
      this.usersFormGroup = this.formBuilder.group({
        users: new FormControl()
      });
    } else if(this.data.operatorKey === OPERATORS.SPIRE) {
      this.refreshFormGroup = new FormGroup({
        refreshCount: new FormControl(''),
        isHistorical: new FormControl(false),
        fetchCount: new FormControl('')
      });
  
      this.refreshFormGroup.valueChanges.subscribe(() => {
        this.refreshError = false;
        const refreshValue = this.refreshFormGroup.get('refreshCount').value;
        const fetchValue = this.refreshFormGroup.get('fetchCount').value;

        if ((refreshValue < 1 || !this.isPositiveWholeNumber(refreshValue))) {
          this.refreshError = true;
        }
        if ((fetchValue < 1 || !this.isPositiveWholeNumber(fetchValue))) {
          this.fetchError = true;
        } else  this.fetchError = false;       
      });
    } else if(this.data.operatorKey === OPERATORS.CLYDE) {
      this.historyFormGroup = new FormGroup({
        daysCount: new FormControl(''),
        isHistorical: new FormControl(false)
      });

      this.historyFormGroup.valueChanges.subscribe(() => {
        const fetchValue = this.historyFormGroup.get('daysCount').value;
        if ((fetchValue < 1 || !this.isPositiveWholeNumber(fetchValue))) {
          this.fetchError = true;
        } else  this.fetchError = false;
      });
    }
    else {
      this.capellaFormGroup = new FormGroup({
        collectionStart: new FormControl('', [Validators.required, Validators.min(0), Validators.max(24), Validators.pattern(/^[0-9]\d*$/)]),
        collectionEnd: new FormControl('', [Validators.required, Validators.max(24), Validators.pattern(/^[0-9]\d*$/)]),
      });

      this.capellaFormGroup.valueChanges.subscribe(() => {
        this.maxValue = false;
        this.wholeNumber = false;
        const startValue = this.capellaFormGroup.get('collectionStart').value;
        const endValue = this.capellaFormGroup.get('collectionEnd').value;
        this.firstInput = startValue;

        if (endValue > 24) this.maxValue = true;
        else this.maxValue = false;

        if ((endValue && !this.isPositiveWholeNumber(endValue)) || (startValue && !this.isPositiveWholeNumber(startValue))) {
          this.wholeNumber = true;
        } else this.wholeNumber = false;

        if ((startValue && startValue >= endValue)) {
          this.capellaFormGroup.get('collectionEnd').setErrors({ 'range': true });
          this.error = true;
        } else {
          this.error = false;
          this.capellaFormGroup.get('collectionEnd').setErrors(null);
        }

        if (!endValue) {
          this.capellaFormGroup.get('collectionEnd').setErrors({ 'range': true });
          this.error = true;
        }
      });
    }
    this.authService.user.subscribe((user) => (this.activeUser = user));
  }

  onCheckboxChange(event: any) {
    this.isHistoricalChecked = event.checked;
  }

  isPositiveWholeNumber(value: number): boolean {    
    return value !== null && value >= 1 && value % 1 === 0;
  }

  selectRecord(operatorPopup: any) {
    this.formGroup.get('sitename').setValue(operatorPopup?.sitename);
  }

  onNoClick(): void {
    this.dialogRef.close('close');
  }


  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }

  get fields() {
    return this.formGroup.controls;
  }

  isSaveButtonDisabled() {
    return this.pointArray.some((_, index) => {
      const siteName = document.getElementById('sitename-' + index) as HTMLInputElement;
      return !siteName || siteName.value.trim().length < 1;
    });
  }

  save() {
    const siteData = this.pointArray.map((point, index) => {
      const siteNameControlName = `sitename-${index}`;
      return {
        siteId: index + 1,
        siteName: this.formGroup.get(siteNameControlName).value,
        target: JSON.stringify(geoParse(point)),
      };
    });

    const siteDetails = {
      siteData: siteData,
      numberOfCollections: this.formGroup.get('daysCount').value,
    };
    this.dialogRef.close(siteDetails);
  }

  submit() {
    this.dialogRef.close(this.capellaFormGroup.value);
  }

  atLeastOneCheckedValidator(control: AbstractControl): ValidationErrors | null {
    const dayModeControl = control.get('dayMode');
    const dayMode = dayModeControl ? dayModeControl.value : null;
    const nightModeControl = control.get('nightMode');
    const nightMode = nightModeControl ? nightModeControl.value : null;

    if (!dayMode && !nightMode) {
      return { atLeastOneChecked: true };
    }
    return null;
  }

  satVuSave() {
    this.dialogRef.close(this.satVuFormGroup.value);
  }

  selectUsers() {
    this.dialogRef.close(this.usersFormGroup.value.users);
  }

  selectRefreshes() {
    this.refreshFormGroup.value.isHistorical = this.isHistoricalChecked;
    this.dialogRef.close(this.refreshFormGroup.value);       
  }

  selectValue(option) {
    this.selectedValue = option.value;
  }

  saveHistoricalDates() {
    if(this.data.operatorKey == OPERATORS.CLYDE) {
      this.historyFormGroup.value.isHistorical = this.isHistoricalChecked;
      this.dialogRef.close(this.historyFormGroup.value);  
    } else {
      this.historyFormGroup.value.isHistorical = this.isHistoricalChecked;
      this.dialogRef.close(this.historyFormGroup.value);   
    }
  }
  convertCostToPoints(cost: number): string {
    if (this.activeUser.pointsEligible) {
      const [part1, part2] = this.activeUser.sensorConversionFactor.split(':').map(parseFloat);
      const conversionFactor = part2 / part1;
      const points = Math.round(cost * conversionFactor);
      return `${points} Points`;
    } else {
      return `$${cost}`;
    }
  }

  onSave() {
    this.dialogRef.close(this.usersFormGroup.value.imageWidth);
  }

  setData() {
    if (this.data?.selectedRow.noOfCollections) {
      this.formGroup.get('daysCount').setValue(this.data.selectedRow.noOfCollections);
    }
  
    if (this.data?.selectedRow.siteData) {
      this.siteData = this.data.selectedRow.siteData;
    }
  
    // Dynamically add form controls for each site
    this.pointArray.forEach((_, index) => {
      const siteNameControlName = `sitename-${index}`;
      const siteName = this.getSiteName(index);
  
      // Add control only if it doesn't already exist
      if (!this.formGroup.contains(siteNameControlName)) {
        this.formGroup.addControl(
          siteNameControlName,
          new FormControl(siteName, [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(25),
            Validators.pattern(/^[a-zA-Z0-9\s]+$/),
          ])
        );
      }
    });
  
    // Trigger change detection to avoid ExpressionChangedAfterItHasBeenCheckedError
    this.cdr.detectChanges();
  }

  getSiteName(index: number): string {
    const site = this.siteData.find(s => s.siteId === index + 1);    
    return site ? site.siteName : '';
  }

  saveBands() {
    this.dialogRef.close(this.usersFormGroup.value.numOfBands);
  }

  submitBands() {
    this.dialogRef.close(this.usersFormGroup.value.bandType);
  }

}
