export const OPERATORS = {
    "HEAD": "Head",
    "CAPELLA": "Capella",
    "SATELLOGIC": "Satellogic",
    "AT21": "21AT",
    "UMBRA": "Umbra",
    "STE": "STE",
    "KOMPSAT": "KOMPSAT",
    "USL": "USL",
    "ISI" : "ISI",
    "AXELSPACE" : "AxelSpace",
    "GHG" : "GHG",
    "ORORATECH": "Ororatech",
    "SATVU": "SatVu",
    "BLACKSKY": "Blacksky",
    "WYVERN" : "Wyvern",
    "SPIRE": "Spire",
    "CLYDE": "ClydeSpace",
    "SYNSPECTIVE": "Synspective",
    "GOKTURK": "Gokturk",
    "LBAND": "Spire-RF",
    "ECURS": "ECURS",
    "ESPER": "Esper",
    "OSK": "OSK"
}

export const STATUS_MAP = {
    completed: 'done',
    approved: 'done',
    ordered: 'done',
    archived: 'done',
    restored: 'done',
    delivering: 'wip',
    pending: 'wip',
    awaiting: 'wip',
    'cancel requested': 'wip',
    rejected: 'canceled',
    canceled: 'canceled',
    failed: 'canceled'
  };

  export const CONFIG_VALUES = {
   "AxelCustomerId" : "659b53fd474336f361dfeb06",
   "numberFormat": '1.2-2',
  };

  export const AT21_RESPONSE_TIME = {
    'standard': 45,
    'priority': 25,
    'rush': 1
  }

  export const supportEmail = "support@eartheye.space"

  export const BANK_DETAILS = {
    'accountHolder' : 'EARTHEYE SPACE TECH PTE LTD',
    'accountNumber' : '261-021273-178',
    'SWIFTcode' : 'HSBCSGSG',
    'bankName' : 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED SINGAPORE',
    'bankAddress' : 'MARINA BAY FINANCIAL CENTRE TOWER 2 48-01 10 MARINA BOULEVARD SINGAPORE 018983'
  }

  export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?!gmail|yahoo|outlook|hotmail)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.([a-zA-Z]{2,})$/; 

  export const STRIPE_CHARGE = 3.9;

  export const PAYPAL_CHARGE = 4.1;

  export const STRIPE_FEE = 0.95749; //(1-(3.9%+ (9%*3.9%)))

  export const PAYPAL_FEE = 0.95531; //(1-(4.1%+ (9%*4.1%)))

  export const RF_RADIUS = 1000; //In km
  
  export const OPTICAL_EMBARGO_AMOUNT = 1000;

  export const SAR_EMBARGO_AMOUNT = 2000;

  export const STEREO_EMBARGO_AMOUNT = 5000;

  export const HSI_EMBARGO_AMOUNT = 1000;

  export const Video_EMBARGO_AMOUNT = 6000;

  export  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  export const polarization = {
    "Capella": 'HH',
    "KOMPSAT": 'HH',
    "Umbra" : 'VV',
    "STE" : 'HH,VV,HV,VH',
    "ECURS" : 'VV',
  }

  export const CAPELLA_MODES = {
    'spotlight_ultra': 'POI',
    'spotlight': 'POI',
    'spotlight_wide': 'POI',
    'stripmap_20': 'POI',
    'stripmap_50': 'POI',
    'stripmap_100': 'AOI'
  }

  export const SENSOR_BAND_DATA = {
    Head: [
      { name: 'B0', startWavelength: 450, endWavelength: 800, gsd: 5, characteristics: 'Panchromatic Band' },
      { name: 'B1', startWavelength: 403, endWavelength: 423, gsd: 5, characteristics: 'Sensitive band to yellow substances' },
      { name: 'B2', startWavelength: 433, endWavelength: 453, gsd: 5, characteristics: 'Coastal band' },
      { name: 'B3', startWavelength: 450, endWavelength: 515, gsd: 5, characteristics: 'Blue band' },
      { name: 'B4', startWavelength: 525, endWavelength: 600, gsd: 5, characteristics: 'Green band' },
      { name: 'B5', startWavelength: 630, endWavelength: 680, gsd: 5, characteristics: 'Red band' },
      { name: 'B6', startWavelength: 784.5, endWavelength: 899.5, gsd: 5, characteristics: 'Leaf Area Index (LAI)/Near Infrared' },
      { name: 'B7', startWavelength: 485, endWavelength: 495, gsd: 10, characteristics: 'Chlorophyll and other pigments' },
      { name: 'B8', startWavelength: 615, endWavelength: 625, gsd: 10, characteristics: 'Sediment transport' },
      { name: 'B9', startWavelength: 650, endWavelength: 680, gsd: 10, characteristics: 'Chlorophyll absorption peak spectrum' },
      { name: 'B10', startWavelength: 698.75, endWavelength: 718.75, gsd: 10, characteristics: 'Chlorophyll fluorescence reference spectrum' },
      { name: 'B11', startWavelength: 732.5, endWavelength: 747.5, gsd: 10, characteristics: 'Red edge spectrum' },
      { name: 'B12', startWavelength: 773, endWavelength: 793, gsd: 10, characteristics: 'Leaf area index spectrum (LAI)' },
      { name: 'B13', startWavelength: 855, endWavelength: 875, gsd: 20, characteristics: 'Sensitive to cholorophyll, biomass, LAI' },
      { name: 'B14', startWavelength: 660, endWavelength: 670, gsd: 20, characteristics: 'Chlorophyll, sediment transport, sediment' },
      { name: 'B15', startWavelength: 677.5, endWavelength: 685, gsd: 20, characteristics: 'Chlorophyll fluorescence peak spectrum, red edge spectrum' },
      { name: 'B16', startWavelength: 750, endWavelength: 757.5, gsd: 20, characteristics: 'O2 absorption reference spectrum' },
      { name: 'B17', startWavelength: 758.75, endWavelength: 762.5, gsd: 20, characteristics: 'O2 absorption spectrum' },
      { name: 'B18', startWavelength: 935, endWavelength: 955, gsd: 20, characteristics: 'Water vapor correction' },
      { name: 'B19', startWavelength: 1000, endWavelength: 1040, gsd: 20, characteristics: 'Atmosphere and aerosol correction' },

    ],

    Wyvern_23: [
      { "sl": 1, "bandName": "Band_503nm", "commonName": "Green" },
      { "sl": 2, "bandName": "Band_510nm", "commonName": "Green" },
      { "sl": 3, "bandName": "Band_520nm", "commonName": "Green" },
      { "sl": 4, "bandName": "Band_535nm", "commonName": "Green" },
      { "sl": 5, "bandName": "Band_550nm", "commonName": "Green" },
      { "sl": 6, "bandName": "Band_570nm", "commonName": "Green" },
      { "sl": 7, "bandName": "Band_585nm", "commonName": "Green" },
      { "sl": 8, "bandName": "Band_600nm", "commonName": "Green" },
      { "sl": 9, "bandName": "Band_615nm", "commonName": "Red" },
      { "sl": 10, "bandName": "Band_635nm", "commonName": "Red" },
      { "sl": 11, "bandName": "Band_650nm", "commonName": "Red" },
      { "sl": 12, "bandName": "Band_660nm", "commonName": "Red" },
      { "sl": 13, "bandName": "Band_670nm", "commonName": "Red" },
      { "sl": 14, "bandName": "Band_680nm", "commonName": "Red-Edge" },
      { "sl": 15, "bandName": "Band_690nm", "commonName": "Red-Edge" },
      { "sl": 16, "bandName": "Band_700nm", "commonName": "Red-Edge" },
      { "sl": 17, "bandName": "Band_712nm", "commonName": "Red-Edge" },
      { "sl": 18, "bandName": "Band_722nm", "commonName": "Red-Edge" },
      { "sl": 19, "bandName": "Band_735nm", "commonName": "Red-Edge" },
      { "sl": 20, "bandName": "Band_750nm", "commonName": "Red-Edge" },
      { "sl": 21, "bandName": "Band_765nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 22, "bandName": "Band_782nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 23, "bandName": "Band_800nm", "commonName": "Near Infrared (NIR)" }
    ],

    Wyvern_31: [
      { "sl": 1, "bandName": "Band_445nm", "commonName": "Blue" },
      { "sl": 2, "bandName": "Band_464nm", "commonName": "Blue" },
      { "sl": 3, "bandName": "Band_480nm", "commonName": "Blue" },
      { "sl": 4, "bandName": "Band_490nm", "commonName": "Blue" },
      { "sl": 5, "bandName": "Band_503nm", "commonName": "Green" },
      { "sl": 6, "bandName": "Band_510nm", "commonName": "Green" },
      { "sl": 7, "bandName": "Band_519nm", "commonName": "Green" },
      { "sl": 8, "bandName": "Band_534nm", "commonName": "Green" },
      { "sl": 9, "bandName": "Band_550nm", "commonName": "Green" },
      { "sl": 10, "bandName": "Band_569nm", "commonName": "Green" },
      { "sl": 11, "bandName": "Band_585nm", "commonName": "Green" },
      { "sl": 12, "bandName": "Band_600nm", "commonName": "Green" },
      { "sl": 13, "bandName": "Band_614nm", "commonName": "Red" },
      { "sl": 14, "bandName": "Band_634nm", "commonName": "Red" },
      { "sl": 15, "bandName": "Band_650nm", "commonName": "Red" },
      { "sl": 16, "bandName": "Band_659nm", "commonName": "Red" },
      { "sl": 17, "bandName": "Band_669nm", "commonName": "Red" },
      { "sl": 18, "bandName": "Band_679nm", "commonName": "Red-Edge" },
      { "sl": 19, "bandName": "Band_689nm", "commonName": "Red-Edge" },
      { "sl": 20, "bandName": "Band_700nm", "commonName": "Red-Edge" },
      { "sl": 21, "bandName": "Band_712nm", "commonName": "Red-Edge" },
      { "sl": 22, "bandName": "Band_722nm", "commonName": "Red-Edge" },
      { "sl": 23, "bandName": "Band_734nm", "commonName": "Red-Edge" },
      { "sl": 24, "bandName": "Band_749nm", "commonName": "Red-Edge" },
      { "sl": 25, "bandName": "Band_764nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 26, "bandName": "Band_781nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 27, "bandName": "Band_799nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 28, "bandName": "Band_814nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 29, "bandName": "Band_832nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 30, "bandName": "Band_849nm", "commonName": "Near Infrared (NIR)" },
      { "sl": 31, "bandName": "Band_869nm", "commonName": "Near Infrared (NIR)" }
    ]
  };