import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { AddbalanceComponent } from './addbalance/addbalance.component';
import { UploadphotoComponent } from './uploadphoto/uploadphoto.component';
import { ToasterService } from 'src/app/services/snackbar.service';
import { countries } from 'country-list-json';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { CountrycheckComponent } from '../taskcost/countrycheck/countrycheck.component';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
import { QuotationPopupComponent } from '../userregister/quotation-popup/quotation-popup.component';
import { EMAIL_REGEX } from 'src/app/services/constants';

interface Country {
  value: any;
  viewValue: string;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  isShown: boolean = false;
  isShownremember: boolean = false;
  currentUser: any;
  userDetails: any;
  userid: any;
  currencySign = environment.payCurrencySign;
  userPhoto: any;
  userAddress: any;
  editable = false;
  show = false;
  msg: any;
  newmsg: any;
  oldmsg: any;
  userForm: FormGroup | any;
  addressForm: FormGroup | any;
  targetForm: FormGroup | any;
  awsForm: FormGroup | any;
  azureForm: FormGroup | any;
  sftpForm: FormGroup | any;
  validationMessages: any;
  pswrdMatched: boolean;
  userPhotoFile: any | Blob;
  userImgUrl: string;
  inputAddress: boolean;
  payUrl: any;
  displayStyle = 'none';
  amount: number;
  click: boolean = false;
  balance: boolean = false;
  disableTextbox = true;
  newPassword: boolean = true;
  showPassword: boolean = true;
  address: any;
  address1: any = '';
  city: any = '';
  address2: any = '';
  zip_code: any = '';
  countries: any;
  photo: string;
  addressCheck = new FormControl();
  passwordCheck = new FormControl();
  awsCheck = new FormControl();
  currentUsers: any;
  isShownS3: boolean = false;
  totalTasking: any;
  locations = [
    { name: 'Amazon S3', value: 0, displayValue: 'Amazon S3' },
    { name: 'Azure Blob', value: 1, displayValue: 'Azure Blob'},
    { name: 'Vault (Australia)', value: 2, displayValue: 'Vault (Australia)'},
    { name: 'SFTP', value: 3, displayValue: 'Secure FTP'},
  ];
  demoView: boolean = false;
  allocationCheck = new FormControl();
  displayedColumns: string[] = ['sensorType', 'sensorName', 'taskingTier', 'totalTasks'];

  @ViewChild('dispname', { static: true }) dispname: ElementRef;
  @ViewChild('email', { static: true }) email: ElementRef;
  taskId: string | null;
  awsType: boolean = false;
  azureType: boolean = false;
  targetSaved: number;
  isawsForm: boolean = true;
  isazureForm: boolean = true;
  isDisplay: boolean = false;
  dataSource: MatTableDataSource<any>;
  darkTheme: boolean;
  issftpForm: boolean = true;
  sftpType: boolean = false;
  deletedImagePath: string;
  isInIframe: boolean;
  payment: boolean;
  panelOpenState = true;
  isFirst = true;
  disableName: boolean = true;
  disableEmail: boolean = true;
  invalidEmail: boolean = false;
  noChange: boolean = true;
  emailId: any;
  displayName: any;
  exist: boolean = false;

  constructor(
    private matdialog: MatDialog,
    private authService: AuthService,
    private userSevice: UserService,
    private fb: FormBuilder,
    private toastService: ToasterService,
    private userService: UserService,
    private router: Router,
    private apiService: ApiService,
    private localStorageService: DataService,
    private themeService: ThemeService,
  ) {
    if (environment.demoView === 'true') {
      this.demoView = true;
    } else {
      this.demoView = false;
    }
    this.isInIframe = this.themeService.isInIframe();
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.deletedImagePath = this.darkTheme
        ? '../../../assets/images/deleted-dark.png'
        : '../../../assets/images/deleted.png';
    });
  }

  async ngOnInit() {

    this.apiService.getAllocation().subscribe((res: any) => {
      if (res?.allocations[0]?.sensor.length > 0) {
        let tableData = res.allocations[0].sensor;
        const addedData = tableData.filter(data => data.isAllocated === true);
        const totalTasks = addedData.reduce((total, data) => total + data.totalTasks, 0);
        this.totalTasking = totalTasks;
      }
    });
    this.countries = countries;
    if (localStorage.getItem('taskData')) {
      this.payment = true;
    } else this.payment = false;

    this.userForm = this.fb.group({
      oldPassword: [
        '',
        Validators.compose([
          Validators.required,
          // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
        ]),
      ],
      newPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern('^(?=.*[A-Z])(?=.*[!@#$%^&_+=]).*$'),
        ]),
      ],
      confirmPassword: [''],
    });

    this.addressForm = this.fb.group({
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      country: [''],
      zip_code: ['']
    });

    this.targetForm = this.fb.group({
      location: ['', [Validators.required]],
    });

    this.awsForm = this.fb.group({
      accessKey: ['', [Validators.required]],
      secretKey: ['', [Validators.required]],
      region: ['', [Validators.required]],
      bucketName: ['', [Validators.required]],
    });

    this.azureForm = this.fb.group({
      azureAccountName: ['', [Validators.required]],
      azureContainer: ['', [Validators.required]],
      azureSasToken: ['', [Validators.required]],
    });

    this.sftpForm = this.fb.group({
      sftpHostname: ['', [Validators.required]],
      sftpUsername: ['', [Validators.required]],
      sftpPassword: ['', [Validators.required]],
      port: [22, [Validators.required]],
      sftpRegion: ['', [Validators.required]]
    });

    this.getUser();

    this.awsForm.valueChanges.subscribe(() => {
      this.isawsForm = false;
    });
    this.azureForm.valueChanges.subscribe(() => {
      this.isazureForm = false;
    });
    this.sftpForm.valueChanges.subscribe(() => {
      this.issftpForm = false;
    });
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value.trim();
    if (value !== '' && parseInt(value) < 0) {
      inputElement.value = '';
      this.sftpForm.patchValue({
        port: ''
      });
    }
  }

  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space") {
      event.preventDefault();
    }
  }

  get sign() {
    return this.addressForm.controls;
  }
  get f() {
    return this.userForm.controls;
  }

  get aws() {
    return this.awsForm.controls;
  }

  get azure() {
    return this.azureForm.controls;
  }

  get sftp() {
    return this.sftpForm.controls;
  }

  get target() {
    return this.targetForm.controls;
  }

  getUser() {
    this.authService.user.subscribe(user => this.currentUsers = user);
    this.userService.personalInfo(this.currentUsers?.id).subscribe(async (res: any) => {
      this.currentUsers = res;
      if (!this.currentUsers?.verified) {
        this.router.navigate(['login']);
        this.authService.logout();
      }
      else {
        this.userDetails = this.currentUsers;
        this.address1 = this.userDetails?.address1;
        this.city = this.userDetails?.city;
        if (this.userDetails?.address2)
          this.address2 = ", " + this.userDetails?.address2;
        if (this.userDetails?.zip_code)
          this.zip_code = ", " + this.userDetails?.zip_code;
        this.address = this.address1 + this.address2 + ", " + this.city + this.zip_code;
        if (this.userDetails.photo !== undefined && !this.userDetails.photo.includes('eeusersapi'))
          this.photo = environment.usersServer + "/data/" + this.userDetails.photo;
        this.selectRecord(this.userDetails);
      }
    });
  }

  selectRecord(userDetails: any) {
    this.addressForm.get('address1').setValue(userDetails?.address1);
    this.addressForm.get('address2').setValue(userDetails?.address2);
    this.addressForm.get('city').setValue(userDetails?.city);
    this.addressForm.get('country').setValue(userDetails?.country);
    this.addressForm.get('zip_code').setValue(userDetails?.zip_code);
    this.awsForm.get('secretKey').setValue(userDetails?.secretKey);
    this.awsForm.get('accessKey').setValue(userDetails?.accessKey);
    this.awsForm.get('region').setValue(userDetails?.region);
    this.awsForm.get('bucketName').setValue(userDetails?.bucketName);
    this.azureForm.get('azureAccountName').setValue(userDetails?.azureAccountName);
    this.azureForm.get('azureContainer').setValue(userDetails?.azureContainer);
    this.azureForm.get('azureSasToken').setValue(userDetails?.azureSasToken);
    this.sftpForm.get('sftpHostname').setValue(userDetails?.sftpHostname);
    this.sftpForm.get('sftpUsername').setValue(userDetails?.sftpUsername);
    this.sftpForm.get('sftpPassword').setValue(userDetails?.sftpPassword);
    this.sftpForm.get('port').setValue(userDetails?.port);
    this.sftpForm.get('sftpRegion').setValue(userDetails?.sftpRegion?.[0] || null);

    if (userDetails?.storageLocation) {
      this.targetForm.get('location').setValue(userDetails?.storageLocation);
      const targetFilter = this.locations.filter(el => el.name === userDetails.storageLocation);
      this.targetSaved = targetFilter[0]?.value;
      this.storageType(targetFilter[0]?.value);
    }
    this.isawsForm = true;
    this.isazureForm = true;
    this.issftpForm = true;
  }

  toggleDisable() {
    this.disableTextbox = !this.disableTextbox;
    this.dispname.nativeElement.focus();
  }
  toggleShow() {
    this.isShown = !this.isShown;
  }
  toggleShowaddress() {
    this.isShownremember = !this.isShownremember;
    this.selectRecord(this.userDetails);
  }

  toggleS3() {
    this.isShownS3 = !this.isShownS3;
    this.selectRecord(this.userDetails);
  }

  OpenPopup() {
    const dialogRef = this.matdialog.open(AddbalanceComponent, {
      // width: !this.payment || this.isInIframe ? '37%' : '30%',
      backdropClass: 'blurred', disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.msg === 'OK') {
        this.balance = true;
      }
    });
  }

  quotationAmount = 0;
  quotation = false;
  requestQuotation() {
    this.quotationAmount = 0;

      const dialogRef = this.matdialog.open(QuotationPopupComponent,
      {
        backdropClass: 'blurred',
        disableClose: true,
        width: '60%',
        data: {message: 'profile'}
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.type === 1) {
          this.quotation = true;
          this.quotationAmount = result.quotationAmount;
          this.authService.addQuotationToTopup(this.quotationAmount, this.currentUsers?.id).subscribe();
        }
        else
          this.quotation = false;
      });
  }


  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  public toggleconfirmPasswordVisibility(): void {
    this.newPassword = !this.newPassword;
  }

  Openupload() {
    const dialogRef = this.matdialog.open(UploadphotoComponent, { width: '40%', backdropClass: 'blurred', disableClose: true});
    backdropClass: 'backdropBackground' // This is the "wanted" line
    dialogRef.afterClosed().subscribe((result) => {
      if (result.msg === 'OK') {
        this.click = true;
        window.location.reload();
      }

    });
  }
  keyPress(event: any) {
    this.newmsg = '';
  }
  keyPressIn(event: any) {
    this.oldmsg = '';
  }
  submit() {
    const oldPassword = this.userForm.value.oldPassword;
    const password = this.userForm.value.newPassword;
    if (oldPassword === password) {
      this.newmsg = 'Old Password and new password must not be same.';
      this.oldmsg = '';
      return;
    } else {
      const pswrdObj = { oldPassword, password };
      this.userSevice.changePswrd(this.currentUsers.id, pswrdObj).subscribe(
        async (res) => {
          this.msg = res;
          this.newmsg = '';
          const toast: any = {
            type: 'success',
            title: '',
            msg: `Password Updated Successfully`,
          };
          this.passwordCheck.setValue(false);
          this.toastService.openSnackBar(toast, 'Ok');
          this.isShown = !this.isShown;
        },
        (err) => {
          this.oldmsg = 'Old password is wrong';
          this.newmsg = '';
        }
      );
    }
  }
  chkOldNew() {
    if (this.userForm.value.oldPassword === this.userForm.value.password) {
      this.msg = 'Old Password and new password should not be same.';
      return;
    }
  }

  changeAddress() {
    const address1 = this.addressForm.value.address1?.trim();
    const address2 = this.addressForm.value.address2?.trim();
    const city = this.addressForm.value.city?.trim();
    const country = this.addressForm.value.country?.trim();
    const zip_code = this.addressForm.value.zip_code;

    const addressObj = { address1, address2, city, country, zip_code };
    this.userSevice.changeAddress(this.currentUsers.id, addressObj).subscribe(
      async (res) => {
        this.msg = res;
        const toast: any = {
          type: 'success',
          title: '',
          msg: `Address Updated Successfully`,
        };
        this.addressCheck.setValue(false);
        this.toastService.openSnackBar(toast, 'Ok');
        this.isShownremember = !this.isShownremember;
        if (this.currentUsers?.id) {
          this.userService.personalInfo(this.currentUsers?.id).subscribe((res: any) => {
            this.userDetails = res;
            this.address1 = this.userDetails?.address1;
            this.city = this.userDetails?.city;
            if (this.userDetails?.address2)
              this.address2 = ", " + this.userDetails?.address2;
            if (this.userDetails?.zip_code)
              this.zip_code = ", " + this.userDetails?.zip_code;
            this.address = this.address1 + this.address2 + ", " + this.city + this.zip_code;
            this.selectRecord(this.userDetails);
          });
        }
      },
      (err) => {
        this.msg = err.error;
      }
    );
  }

  updateDetails(field: string, fieldVal: any) {
    if (fieldVal === this.userDetails.displayname || !fieldVal) {
      this.displayName = null;
      this.noChange = true;
      if (this.emailId != null) this.noChange = false;
      return;
    } else {
      this.displayName = fieldVal;
      this.noChange = false;
    }
  }

  saveStorage(formType: any) {
    const trimmedValue = {};
    const azureTrimmedvalues = {};
    const sftpTrimmedvalues = {};

    if (formType === 'awsForm') {
      this.azureForm.reset();
      this.sftpForm.reset();
    } else if (formType === 'azureForm') {
      this.awsForm.reset();
      this.sftpForm.reset();
    } else if (formType === 'sftpForm') {
      this.azureForm.reset();
      this.awsForm.reset();
    }

    Object.keys(this.awsForm.value).forEach((key) => {
      const value = this.awsForm.value[key];
      trimmedValue[key] = typeof value === 'string' ? value.trim() : value;
    });
    Object.keys(this.azureForm.value).forEach((key) => {
      const value = this.azureForm.value[key];
      azureTrimmedvalues[key] = typeof value === 'string' ? value.trim() : value;
    });
    // Object.keys(this.sftpForm.value).forEach((key) => {
    //   const value = this.sftpForm.value[key];      
    //   sftpTrimmedvalues[key] = typeof value === 'string' ? value.trim() : value;
    // });

    this.awsForm.setValue(trimmedValue);
    this.azureForm.setValue(azureTrimmedvalues);
    // this.sftpForm.setValue(sftpTrimmedvalues);

      const storageObj = {
        ...this.targetForm.value,
        ...this.awsForm.value,
        ...this.azureForm.value,
        ...this.sftpForm.value
    };

    this.isawsForm = true;
    this.isazureForm = true;
    this.issftpForm = true;

    this.userSevice
      .updateStorage(this.currentUsers.id, storageObj)
      .subscribe(async (res) => {
        if (res === 'success') {
          const toast: any = {
            type: 'success',
            title: '',
            msg: `Target Data Delivery Updated Successfully`,
          };
          this.toastService.openSnackBar(toast, 'Ok');
          this.awsCheck.setValue(false);
          this.isShownS3 = !this.isShownS3;
          this.userService.personalInfo(this.currentUsers?.id).subscribe((res: any) => {
            this.userDetails = res;
          });
          if (localStorage.getItem('storage')) {
            this.taskId = localStorage.getItem('storageId')
            this.router.navigate(['/payment'], {
              queryParams: { taskId: this.taskId, type: 'cart' },
            });
          }
        } else {
          const dialogRef = this.matdialog.open(CountrycheckComponent, {
            width: '40%', backdropClass: 'blurred', disableClose: true,
            data: { message: "target", info: res }
          });
        }
      }
      );
  }

  storageType(type: any) {
    this.awsType = false;
    this.azureType = false;
    this.sftpType = false;
    this.isawsForm = true;
    this.isazureForm = true;
    this.issftpForm = true;

    if (this.targetSaved === 0) {
      this.azureForm.reset();
      this.sftpForm.reset();
    } else if (this.targetSaved === 1) {
      this.awsForm.reset();
      this.sftpForm.reset();
    } else if (this.targetSaved === 3) {
      this.awsForm.reset();
      this.azureForm.reset();
    }

    if (type === 0) this.awsType = true;
    else if (type === 1) this.azureType = true;
    else if (type === 3) this.sftpType = true;
  }

  toggleAllocation() {
    this.isDisplay = !this.isDisplay;
    this.getData();
  }

  getData() {
    this.apiService.getAllocation().subscribe((res: any) => {
      if (res?.allocations[0]?.sensor.length > 0) {
        let tableData = res.allocations[0].sensor
        const addedData = tableData.filter(data => data.isAllocated === true);
        this.dataSource = new MatTableDataSource<any>(addedData);
      }
    });
  }

  toggleDisplayName() {
    this.disableName = !this.disableName;
    this.dispname.nativeElement.focus();
    this.dispname.nativeElement.value = this.userDetails.displayname;
  }

  toggleEmailEdit() {
    this.disableEmail = !this.disableEmail;
    this.email.nativeElement.focus();
    this.exist = false;
    this.invalidEmail = false;
    this.email.nativeElement.value = this.userDetails.email;
    this.noChange = true;
  }

  updateEmail(fieldVal: any) {
    this.exist =  false;
    if (!EMAIL_REGEX.test(fieldVal)) {
      this.invalidEmail = true;
      return;
    } else {
      this.invalidEmail = false;
    }

    if (fieldVal === this.userDetails.email) {
      this.emailId = null;
      this.noChange = true;
      if (this.displayName != null) this.noChange = false;
      return;
    } else {
      this.emailId = fieldVal;
      this.noChange = false;
    }
  }

  editPersonalSettings() {
    this.noChange = true;
    let email = this.emailId?.toLowerCase() ?? '';
    this.userSevice
      .updateField(this.currentUsers.id, email, this.displayName)
      .subscribe((res) => {
        if (res.toString().includes('Update')) {
          this.disableEmail = true;
          this.disableName = true;
          const toast: any = {
            type: 'success',
            title: '',
            msg: `Changes Updated Successfully`,
          };
          this.toastService.openSnackBar(toast, 'Ok');
          if (this.currentUsers?.id) {
            this.localStorageService.triggerRefreshUserDetails(this.currentUsers?.id);
            this.userService.personalInfo(this.currentUsers?.id).subscribe((res: any) => {
              this.userDetails = res;
              this.dispname.nativeElement.value = this.userDetails.displayname;
              this.email.nativeElement.value = this.userDetails.email;
            });
          }
        } else {
          this.exist = true;
        }
      });
      this.emailId = null;
      this.displayName = null;
  }

  cancelChanges() {
    this.dispname.nativeElement.value = this.userDetails.displayname;
    this.email.nativeElement.value = this.userDetails.email;
    this.noChange = true;
    this.emailId = null;
    this.displayName = null;
  }
}
