<div *ngIf="product === 'collection'" class="table-container">
    <div class="header-cell">Metadata</div>
    <div style="padding-top: 5px;">
        <table>
            <tr *ngFor="let entry of getObjectEntries(metadata)" class="key">
                <td class="data">{{ entry[0] }}</td>
                <td class="data" style="width: 22px; padding-left: 5px;">:</td>
                <td class="data" style="max-width: 300px !important;">{{ entry[1] }}</td>
            </tr>
        </table>
    </div>
</div>


<div *ngIf="product === 'band' && metadata?.length > 0" class="custom-tooltip">
  <div class="header-cell" style="padding-bottom: 9px;
    margin-top: -8px;">Band Information</div>
    <table *ngIf="operator === operators.HEAD">
      <thead>
        <tr>
          <th>Bands</th>
          <th>Starting Wavelength (nm)</th>
          <th>Ending Wavelength (nm)</th>
          <th>GSD (m)</th>
          <th>Spectral Characteristics</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of metadata">
          <td>{{ data.name }}</td>
          <td>{{ data.startWavelength }}</td>
          <td>{{ data.endWavelength }}</td>
          <td>{{ data.gsd }}</td>
          <td>{{ data.characteristics }}</td>
        </tr>
      </tbody>
    </table>

    <table *ngIf="operator === operators.WYVERN">
      <thead>
        <tr>
          <th>No.</th>
          <th>Band Name</th>
          <th>Common Name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of metadata">
          <td>{{ data.sl }}</td>
          <td>{{ data.bandName }}</td>
          <td>{{ data.commonName }}</td>
        </tr>
      </tbody>
    </table>

    <table *ngIf="operator === operators.ESPER">
      <thead>
        <tr>
          <th>Band Name</th>
          <th>Central Wavelength (nm)</th>
          <th>FWHM (nm)</th>
          <th>SNR</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of metadata">
          <td>{{ data.bandName }}</td>
          <td>{{ data.centralWavelength }}</td>
          <td>{{ data.FWHM }}</td>
          <td>{{ data.SNR }}</td>
        </tr>
      </tbody>
    </table>

    <table *ngIf="operator === operators.OSK">
      <thead>
        <tr>
          <th>Band Name</th>
          <th>Central Wavelength (nm)</th>
          <th>Common Band Name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of metadata">
          <td>{{ data.bandName }}</td>
          <td>{{ data.centralWavelength }}</td>
          <td>{{ data.commonBandName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div *ngIf="product === 'legend'" class="table-container">
    <div class="header-cell">Vessel Classification</div>
    <div style="padding-top: 5px;" *ngIf="operator === operators.SPIRE">
        <table>
            <tr *ngFor="let data of metadata" class="key">
                <td class="data" style="text-transform: capitalize; width: 170;">{{ formatKey(data.type) }}</td>
                <td class="data" style="width: 40px; padding-left: 5px;"> - </td>
                <td class="data" style="max-width: 300px !important;">
                  <mat-icon [ngStyle]="{ color: data.color }" 
                  [ngClass]="(refreshDataType)  ? 'material-icons-outlined' : ''">
                    navigation
                </mat-icon>
              </td>
            </tr>
        </table>
    </div>
</div> 
