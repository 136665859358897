import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AuthService } from 'src/app/services/auth.service';
import { TaskService } from 'src/app/services/task.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CancelinvoiceComponent } from './cancelinvoice/cancelinvoice.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
import * as moment from 'moment';
import { PAYPAL_FEE, STRIPE_FEE } from 'src/app/services/constants';
import { UtilsService } from 'src/app/services/utils.service';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  displayedColumns: string[] = [
    'orderplace',
    'projectname',
    'taskname',
    'orderno',
    'amount',
    'invoice',
    'receipt',
    'creditNote',
  ];
  dataSource = new MatTableDataSource<any>();
  filteredData: any = [];
  color = '#1059a9';
  currentUser: any;
  activeUser: any;

  tasktabs: any;
  tasksCount: any;
  userid: any;
  tableDataLength: any;
  today: Date;
  todayDate: Date = new Date();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  stDate: Date | null;
  endDate: Date | null;
  tabType: any = 'all';
  hideColumn: boolean = false;
  taxType: string;
  gstpercent: number = 0;
  dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  orderReport: any;
  darkTheme: boolean;
  invoiceImagePath: string;
  receiptsImagePath: string;
  isInIframe: boolean;
  currentIndex: number = 0;
  balanceRecords: number = 0;
  newInvoice: boolean;
  onlineCharge: number = 0;
  totalGST: number = 0;
  receipt: boolean = false;
  grandTotal: number = 0;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private authService: AuthService,
    private taskService: TaskService,
    private router: Router,
    private apiService: ApiService,
    public dialog: MatDialog,
    private userSevice: UserService,
    public datepipe: DatePipe,
    private localStorageService: DataService,
    private themeService: ThemeService,
    private utilsService: UtilsService 
  ) {

    this.isInIframe = this.themeService.isInIframe();
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.invoiceImagePath = this.darkTheme ? '../../../assets/images/invoice-dark.png' : '../../../assets/images/invoice.png';
      this.receiptsImagePath = this.darkTheme ? '../../../assets/images/receipts-dark.svg' : '../../../assets/images/receipts.svg';
    })
  }

  @ViewChild(MatSort) sort = new MatSort();
  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    const castUser = this.authService.castCurrentUser.subscribe(
      async (res: any) => {
        this.currentUser = await res;
        if (!this.currentUser?.verified) {
          this.router.navigate(['login']);
          this.authService.logout();
        } else {
          this.userid = this.currentUser.id;
          if (this.currentUser.userType === 'admin')
            this.userid = this.userid + 'admin';
          this.filteredData = [];
          this.balanceRecords = 0;
          this.tableDataLength = 0;
          this.getOrderData(this.tabType, this.currentIndex);
        }
        castUser.unsubscribe();
      }
    );
    this.authService.user.subscribe((user) => (this.activeUser = user));
    localStorage.removeItem('taskData');
  }

  getOrderData(orderstatus: any, currentIndex: number) {
    let orderObject = { status: orderstatus, pageIndex: currentIndex };
    this.taskService.getOrders(this.userid, orderObject).subscribe(
      (result: any) => {
        this.balanceRecords = result.balance;
        this.tableDataLength =
          result.orders.length > 0 ? result.orders.length : 0;
        if (result.orders.length > 0) {
          this.populateData(result.orders);
        }
      },
      (err) => {
        return false;
      }
      );
  }

  populateData(orders: any) {
    orders.forEach(
      (order: {
        orderdetail: any[];
        transactionStatus: boolean;
        orderstatus: string;
        partialPayment: boolean;
        refundApproved: boolean
      }) => {
        const oprtrs = Array();
        order.orderdetail.forEach((oprtr: string, index: any) => {
          let oprtData;
          try {
            oprtData = JSON.parse(oprtr);
          } catch (e) {
            oprtData = oprtr;
          }
          oprtrs.push(oprtData);
        });
        order.orderdetail = oprtrs;
        if (!order?.partialPayment || (order?.partialPayment && order?.refundApproved)) {
          if (order.transactionStatus == true) {
            order.orderstatus = 'success';
          } else {
            order.orderstatus = 'Failed';
          }
          this.filteredData = this.filteredData.concat(order);
        }
      }
    );
    this.currentIndex = this.filteredData.length;
    this.dataSource = new MatTableDataSource(this.filteredData); 
  }

  datewiseOrder(type: any, e: MatDatepickerInputEvent<Date>) {
    if (type === 'start') this.stDate = e.value;
    if (type === 'end') this.endDate = e.value;
    this.filteredData = [];
    this.currentIndex = 0;
    if (this.stDate && this.endDate) {
      let stDate = moment(this.stDate).utcOffset(0, true).format();
      let endDate = moment(this.endDate).utcOffset(0, true).add(86399, 'seconds').format();
      let orderObject = {start: stDate, end: endDate, tabType: this.tabType, pageIndex: this.currentIndex}
      this.taskService.getOrders(this.currentUser.id, orderObject)
        .subscribe((orders: any) => {
          this.balanceRecords = orders.balance;
          this.currentIndex = orders.total;
          this.populateData(orders.orders);
        });
    }
  }

  genOrderReport() {
    this.orderReport = [];
    let stDate = this.stDate;
    let endDate = this.endDate;
    for (const [idx, orders] of this.filteredData.entries()) {
      this.orderReport.push(orders);
      // if (!orders?.refundCheck) {
      // }
    }
    
    this.apiService.genReport(this.orderReport, stDate, endDate);
  }

  onItemChange(value: any) {
    this.range.reset();
    this.tabType = value;
    this.filteredData = [];
    this.balanceRecords = 0;
    this.currentIndex = 0;
    this.getOrderData(value, this.currentIndex);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  btnAction(order: any,type: string) {
    if (order.isSignedUrl) this.loadPdf(order,type);
    else this.genPdf(order);
  }

  loadPdf(order,type) {
    let orderObj = {
     orderId: order.id,
     type: type
    }
    this.apiService.getOrderSignedUrl(orderObj).subscribe((res: any) => {
      if (res.result) {
        let url = res.result;
        window.open(url, '_blank');
      }   
    });
  }

  cancelinvoice(order): void {
    const dialogRef = this.dialog.open(CancelinvoiceComponent, {
      width: '50%',
      backdropClass: 'blurred',
      disableClose: true,
      data: { taskname: order?.taskname },
    });
  }

  async genPdf(order: any) {
    this.receipt = false;
    this.onlineCharge = 0;
    this.totalGST = 0;
    this.grandTotal = 0;
    this.hideColumn = false;
    this.newInvoice = false;
    let reportType = 'Receipt',
      report = 'Receipt',
      logo,
      totalRefund: any, feedType: any, onlineFees: any;
    if (order.payoption === 'Pay later') {
      reportType = 'Invoice';
      report = 'Invoice';
    }
    let receiptType = 'Bill to';
    if (order.refundCheck) {
      receiptType = 'Refund to';
      this.hideColumn = true;
      reportType = 'Credit Note';
      report = 'Credit';
    }

    if (order.payoption === 'Pay later' && order?.isReIssued) {
     this.newInvoice = true
    }

    if (order?.taskStrategy != 'Tip-and-Cue' && order?.taskStrategy != 'Tip-and-Cue-Outcome') feedType = 'Information Feeds'
    else feedType = 'Tip-and-Cue (Observation)';

    if (order?.paymentMethod === 'PayPal') onlineFees = PAYPAL_FEE;
    else if (order?.paymentMethod === 'Stripe') onlineFees = STRIPE_FEE;

    this.taxType = 'VAT';
    const exs: any = [];
    const catData: any = [];
    const demData: any = [];
    let receiptNo = order.order_number;
    let subTotal = order.grand_total ? order.grand_total : order.orderamount;
    let valueAddedCost = 0;
    let feedCost = 0;
    let totalTaskCost = 0;
    let grandTotalWithCharges,paymentCharges;
    
    const totalTaskNumber = order?.totalTaskNumber ?? 0;
    const logoimge = '../assets/images/logo.png';

    if (this.currentUser?.id) {
      const castUser = this.userSevice
        .personalInfo(this.currentUser?.id)
        .subscribe(async (res: any) => {
          this.currentUser = res;
          // console.log(this.currentUser,order);
          
          if (
            this.currentUser?.channelPriceId === undefined &&
            this.currentUser?.country === 'Singapore'
          ) {
            this.gstpercent = environment.gst;
          } else if (this.currentUser?.channelPriceId != undefined) {
            this.gstpercent = this.currentUser?.tax[0]?.taxperc;
            this.taxType = this.currentUser?.tax[0]?.type;
          }
          let grandTotal = order.grand_gst_total ? order.grand_gst_total :
          (order.orderamount + (order.orderamount * this.gstpercent) / 100);
          
          let fullAddress, country;
          if (this.currentUser.address1) {
            fullAddress =
              this.currentUser?.address1 + ' , ' + this.currentUser?.address2;
            country = this.currentUser?.country;
          } else {
            fullAddress = 'Not available';
            country = 'Not available';
          }

          this.receipt = (report === 'Receipt' && order.payoption === 'Pay now' && order.paymentMethod); 
          this.calculateGST(order,this.currentUser);

          await this.toDataURL(logoimge).then((dataUrl) => {
            logo = dataUrl;
          });
          exs.push([
            this.currentUser?.allocated ? 
            {
              columns: [
                [
                  {
                    width: 50,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Item No.',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 130,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Sensor Name',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 120,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Resolution',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 50,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Tasked Area',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 100,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Task Subtotal',
                    style: 'tableHeader',
                  },
                ],
              ],
            }
            : this.newInvoice 
            ? {
              columns: [
                [
                  {
                    width: 20,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Item No.',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 80,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Old Invoice No. Replaced',
                    style: 'tableHeader',
                    marginLeft: -5,
                  },
                ],
                [
                  {
                    width: 160,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Sensor Name',
                    style: 'tableHeader',
                    marginRight: -8,
                  },
                ],
                [
                  {
                    width: 130,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Resolution',
                    style: 'tableHeader',
                    marginRight: -10,
                  },
                ],
                [
                  {
                    width: 100,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Unit Price',
                    style: 'tableHeader',
                    marginRight: -10,
                  },
                ],
              ],
            } :
            !this.hideColumn
              ? {
                columns: [
                  [
                    {
                      width: 50,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Item No.',
                      style: 'tableHeader',
                      marginLeft: -43,
                    },
                  ],
                  [
                    {
                      width: 160,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Sensor Name',
                      style: 'tableHeader',
                    },
                  ],
                  [
                    {
                      width: 120,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Resolution',
                      style: 'tableHeader',
                    },
                  ],
                  [
                    {
                      width: 100,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Unit Price ',
                      style: 'tableHeader',
                      marginRight: -43,
                    },
                  ],
                ],
              }
              : {
                columns: [
                  [
                    {
                      width: 20,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Item No.',
                      style: 'tableHeader',
                    },
                  ],
                  [
                    {
                      width: 80,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Credit Note For Receipt',
                      style: 'tableHeader',
                      marginLeft: -5,
                    },
                  ],
                  [
                    {
                      width: 160,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Sensor Name',
                      style: 'tableHeader',
                      marginRight: -8,
                    },
                  ],
                  [
                    {
                      width: 130,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Resolution',
                      style: 'tableHeader',
                      marginRight: -10,
                    },
                  ],
                  [
                    {
                      width: 100,
                      alignment: 'center',
                      fontSize: 10,
                      text: 'Unit Price ',
                      style: 'tableHeader',
                    },
                  ],
                ],
              },
          ]);
          catData.push([
            !this.receipt ?
            {
              columns: [
                [
                  {
                    width: 50,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Item No.',
                    style: 'tableHeader',
                    marginLeft: -83,
                  },
                ],
                [
                  {
                    width: 130,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Name',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 100,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Unit Price ',
                    style: 'tableHeader',
                    marginRight: -85,
                  },
                ],
              ],
            } : 
            {
              columns: [
                [
                  {
                    width: 30,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Item No.',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 130,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Name',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 60,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Unit Price ',
                    style: 'tableHeader',
                  },
                ],
              ],
            }
          ]);
          //Value added array
          demData.push([
            !this.receipt ? 
            {
              columns: [
                [
                  {
                    width: 50,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Item No.',
                    style: 'tableHeader',
                    marginLeft: -83,
                  },
                ],
                [
                  {
                    width: 130,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Name',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 100,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Unit Price ',
                    style: 'tableHeader',
                    marginRight: -85,
                  },
                ],
              ],
            } : 
            {
              columns: [
                [
                  {
                    width: 50,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Item No.',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 130,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Name',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    width: 100,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'Unit Price ',
                    style: 'tableHeader',
                  },
                ],
              ],
            },
          ]);
          let i = 0;

          order.orderdetail.forEach((sensor: any, index: any) => {
            if (sensor?.cost > 0 || sensor?.refundAmount > 0) {
              i++;
              const name = ((this.currentUser.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor.operator) + ' ' + sensor.sensor;
              let subcost,unitcost,additionalCharges,onlineCharges;
              const description = sensor.resolution != undefined ?
                sensor.resolution +
                ' ' +
                (sensor?.resolutionTooltip ? sensor?.resolutionTooltip : '') : "NA";

              const qnty = 1;
              totalRefund = +sensor.refundAmount;
              const orderNumber = sensor?.order_number;

              unitcost = this.currentUser?.pointsEligible ? 
              (this.hideColumn ? (sensor?.refundPointWithoutGST ? sensor.refundPointWithoutGST : sensor.refundPoint) : sensor?.point)
              : this.hideColumn ? (sensor?.refundAmountWithoutGST ? sensor.refundAmountWithoutGST : sensor.refundAmount) : sensor.cost;

              subcost = this.currentUser?.pointsEligible
              ? `${unitcost} Points`
              : this.dollarUS.format(unitcost);

              let amountInDollars = this.hideColumn ? sensor?.refundAmountWithoutGST : sensor.cost;

              additionalCharges = (amountInDollars) * (onlineFees / 100);
              onlineCharges = this.dollarUS.format(additionalCharges);

              if(this.currentUser.pointsEligible) {
                additionalCharges = this.utilsService.convertCostToPoints(additionalCharges,this.currentUser.sensorConversionFactor);
                onlineCharges = `${additionalCharges} Points`
              }

              let totalCostWithCharges = +unitcost + +additionalCharges;
              let totalCost = this.dollarUS.format(totalCostWithCharges);
              if (this.currentUser.pointsEligible) totalCost = `${totalCostWithCharges} Points`;
 
              exs.push([
                this.currentUser?.allocated ? 
                {
                  columns: [
                    [
                      {
                        width: 50,
                        alignment: 'center',
                        fontSize: 10,
                        text: i,
                        style: 'contents',
                      },
                    ],
                    [
                      {
                        width: 130,
                        alignment: 'center',
                        fontSize: 10,
                        text: name,
                        style: 'contents',
                      },
                    ],
                    [
                      {
                        width: 120,
                        alignment: 'center',
                        fontSize: 10,
                        text: description,
                        style: 'contents',
                      },
                    ],
                    [
                      {
                        width: 50,
                        alignment: 'center',
                        fontSize: 10,
                        text: sensor.taskedArea,
                        style: 'contents',
                      },
                    ],
                    [
                      {
                        width: 100,
                        alignment: 'center',
                        fontSize: 10,
                        text: sensor.taskNumber,
                        style: 'contents',
                      },
                    ],
                  ],
                }
                : this.newInvoice 
                ? {
                  columns: [
                    [
                      {
                        width: 20,
                        alignment: 'center',
                        fontSize: 10,
                        text: i,
                        style: 'contents',
                      },
                    ],
                    [
                      {
                        width: 80,
                        alignment: 'center',
                        fontSize: 10,
                        text: orderNumber,
                        style: 'contents',
                        marginRight: 3,
                      },
                    ],
                    [
                      {
                        width: 160,
                        alignment: 'center',
                        fontSize: 10,
                        text: name,
                        style: 'contents',
                        marginRight: 8,
                      },
                    ],
                    [
                      {
                        width: 130,
                        alignment: 'center',
                        fontSize: 10,
                        text: description,
                        style: 'contents',
                        marginRight: -10,
                      },
                    ],
                    [
                      {
                        width: 100,
                        alignment: 'center',
                        fontSize: 10,
                        text: subcost,
                        style: 'contents',
                        marginRight: -15,
                      },
                    ],
                  ],
                } :
                !this.hideColumn
                  ? {
                    columns: [
                      [
                        {
                          width: 50,
                          alignment: 'center',
                          fontSize: 10,
                          text: i,
                          style: 'contents',
                          marginLeft: -43,
                        },
                      ],
                      [
                        {
                          width: 160,
                          alignment: 'center',
                          fontSize: 10,
                          text: name,
                          style: 'contents',
                        },
                      ],
                      [
                        {
                          width: 120,
                          alignment: 'center',
                          fontSize: 10,
                          text: description,
                          style: 'contents',
                        },
                      ],
                      [
                        {
                          width: 100,
                          alignment: 'center',
                          fontSize: 10,
                          text: subcost,
                          style: 'contents',
                          marginRight: -43,
                        },
                      ],
                    ],
                  }
                  : {
                    columns: [
                      [
                        {
                          width: 20,
                          alignment: 'center',
                          fontSize: 10,
                          text: i,
                          style: 'contents',
                        },
                      ],
                      [
                        {
                          width: 80,
                          alignment: 'center',
                          fontSize: 10,
                          text: orderNumber,
                          style: 'contents',
                          marginRight: 3,
                        },
                      ],
                      [
                        {
                          width: 160,
                          alignment: 'center',
                          fontSize: 10,
                          text: name,
                          style: 'contents',
                        },
                      ],
                      [
                        {
                          width: 130,
                          alignment: 'center',
                          fontSize: 10,
                          text: description,
                          style: 'contents',
                          marginRight: -10,
                        },
                      ],
                      [
                        {
                          width: 100,
                          alignment: 'center',
                          fontSize: 10,
                          text: subcost,
                          style: 'contents',
                        },
                      ],
                    ],
                  },
              ]);
            }
          });

          order.mlTasks.forEach((task: any, index) => {
            let taskPrice,additionalCharges,taskCost,onlineCharges;
            taskPrice = task.price;
            if (this.receipt) {
              additionalCharges = (taskPrice) * (onlineFees / 100);
              taskCost = +taskPrice + +additionalCharges;
              onlineCharges = this.dollarUS.format(additionalCharges);
              feedCost +=  +taskPrice + +additionalCharges;
            }

            if (this.currentUser?.pointsEligible) {
              additionalCharges = this.utilsService.convertCostToPoints(additionalCharges,this.currentUser.sensorConversionFactor);
              onlineCharges = `${additionalCharges} Points`;
              taskCost = +task.point + +additionalCharges;
            }

            index = index + 1;
            catData.push([
              !this.receipt ?
              {
                columns: [
                  [
                    {
                      width: 50,
                      alignment: 'center',
                      fontSize: 10,
                      text: index,
                      style: 'contents',
                      marginLeft: -83,
                    },
                  ],
                  [
                    {
                      width: 130,
                      alignment: 'center',
                      fontSize: 10,
                      text: (this.currentUser?.obfuscated && task?.obfuscateName) ? task.obfuscateName : task.name,
                      style: 'contents',
                    },
                  ],
                  [
                    {
                      width: 100,
                      alignment: 'center',
                      fontSize: 10,
                      text: this.currentUser?.pointsEligible ? task.point + ' Points' : this.dollarUS.format(task.price),
                      style: 'contents',
                      marginRight: -85,
                    },
                  ],
                ],
              } : 
              {
                columns: [
                  [
                    {
                      width: 30,
                      alignment: 'center',
                      fontSize: 10,
                      text: index,
                      style: 'contents',
                    },
                  ],
                  [
                    {
                      width: 130,
                      alignment: 'center',
                      fontSize: 10,
                      text: (this.currentUser?.obfuscated && task?.obfuscateName) ? task.obfuscateName : task.name,
                      style: 'contents',
                    },
                  ],
                  [
                    {
                      width: 60,
                      alignment: 'center',
                      fontSize: 10,
                      text: this.currentUser?.pointsEligible ? task.point + ' Points' : this.dollarUS.format(task.price),
                      style: 'contents',
                    },
                  ],
                ],
              },
            ]);
          });

          /** Value Added Options */
          (order.valueAddedOption || []).sort((a, b) => {
            const actionA = a.actionProvider || '';
            const actionB = b.actionProvider || '';
            return actionA.localeCompare(actionB);
          });

          let valueAddedProducts = [
            ...(order.valueAddedOption || []),
            ...(order.indices || []),
            ...(order.super_resolution || [])
          ];
          (valueAddedProducts || []).forEach((item: any, index) => {
            let taskPrice,additionalCharges,cost,onlineCharges;
            taskPrice = item.totalareacost;
            if (this.receipt) {
              additionalCharges = (taskPrice) * (onlineFees / 100);
              cost = +taskPrice + +additionalCharges;
              onlineCharges = this.dollarUS.format(additionalCharges);
              valueAddedCost +=  +taskPrice + +additionalCharges;
            }

            if (this.currentUser?.pointsEligible) {
              additionalCharges = this.utilsService.convertCostToPoints(additionalCharges,this.currentUser.sensorConversionFactor);
              onlineCharges = `${additionalCharges} Points`;
              cost = +item.point + +additionalCharges;
              if(item?.valueAddedDataProduct === 'indices') {
                item.point = item.totalareapoints;
              }
            }

            index = index + 1;
            demData.push([
              !this.receipt ?
              {
                columns: [
                  [
                    {
                      width: 50,
                      alignment: 'center',
                      fontSize: 10,
                      text: index,
                      style: 'contents',
                      marginLeft: -83,
                    },
                  ],
                  [
                    {
                      width: 130,
                      alignment: 'center',
                      fontSize: 10,
                      text: item?.valueAddedDataProduct === 'indices' ? 
                        item.actionProvider + ' ' + ' ( ' + 
                        ((this.currentUser?.obfuscated && item?.obfuscateName) ? item.obfuscateName : item.sensor) + ' ' + item?.sensorType + ' (' + 
                        item.sensorResolution + ') )'
                      :
                        item.actionProvider + ' ' + 
                        item.providerResolution + ' ( ' + 
                        ((this.currentUser?.obfuscated && item?.obfuscateName) ? item.obfuscateName : item.name) + ' ' + item?.sensor_name + ' (' + 
                        item.resolution + ') )',
                      style: 'contents',
                    },
                  ],
                  [
                    {
                      width: 100,
                      alignment: 'center',
                      fontSize: 10,
                      text: this.currentUser?.pointsEligible ? item.totalareapoints + ' Points' :
                      item.actionProvider === 'Super Resolution' && order?.refundCheck === true ?
                      this.dollarUS.format(item.totalareacost)
                      :
                      this.dollarUS.format(item.totalareacost),
                      style: 'contents',
                      marginRight: -85,
                    },
                  ],
                ],
              } : 
              {
                columns: [
                  [
                    {
                      width: 50,
                      alignment: 'center',
                      fontSize: 10,
                      text: index,
                      style: 'contents',
                    },
                  ],
                  [
                    {
                      width: 130,
                      alignment: 'center',
                      fontSize: 10,
                      text: item?.valueAddedDataProduct === 'indices' ? 
                          item.actionProvider + ' ' + ' ( ' + 
                          ((this.currentUser?.obfuscated && item?.obfuscateName) ? item.obfuscateName : item.sensor) + ' ' + item?.sensorType + ' (' + 
                          item.sensorResolution + ') )'
                        :
                        item.actionProvider + ' ' + 
                        item.providerResolution + ' ( ' + 
                        ((this.currentUser?.obfuscated && item?.obfuscateName) ? item.obfuscateName : item.name) + ' ' + item?.sensor_name + ' (' + 
                        item.resolution + ') )',
                      style: 'contents',
                    },
                  ],
                  [
                    {
                      width: 80,
                      alignment: 'center',
                      fontSize: 10,
                      text: this.currentUser?.pointsEligible ? item.totalareapoints + ' Points' : this.dollarUS.format(item.totalareacost),
                      style: 'contents',
                    },
                  ],
                ],
              },
            ]);
          });

          let docDefinition: any = {
            header: {
              margin: [35, 40, 40, 40],
              columns: [
                {
                  image: logo,
                  width: 140,
                },
              ],
            },
            content: [
              {
                text: reportType,
                fontSize: 20,
                alignment: 'right',
                color: '#000',
              },
              {
                margin: [0, 40, 0, 0],
                text: 'Eartheye Space Technologies Pte Ltd',
                fontSize: 12,
                bold: true,
                alignment: 'left',
                color: '#000',
              },
              {
                text: '1 North Bridge Road',
                style: 'CompanyAddress',
              },
              {
                text: '#18-03, High Street Centre',
                style: 'CompanyAddress',
              },
              {
                text: 'Singapore 179094',
                style: 'CompanyAddress',
              },
              {
                columns: [
                  [{ text: receiptType + ': ', style: 'CustomerLeft' }],
                  [
                    {
                      text:
                        'Date: ' +
                        this.datepipe.transform(
                          new Date(order.createdAt).toDateString(),
                          'dd-MM-yyyy'
                        ),

                      style: 'OrderRight',
                    },
                  ],
                ],
              },
              {
                columns: [
                  [
                    {
                      text: this.currentUser.name,
                      style: 'CustomerLeft1',
                      margin: [0, 6, 0, 0],
                    },
                  ],
                ],
              },
              {
                columns: [
                  [
                    {
                      text: this.currentUser?.company,
                      style: 'Customer',
                      marginTop: 2,
                      marginBottom: 2,
                    },
                  ],
                  [
                    {
                      text: report + ' No: ' + receiptNo,
                      style: 'OrderRight1',
                      margin: [0, -15, 0, 10],
                    },
                  ],
                ],
              },
              {
                columns: [
                  [
                    {
                      text: [
                        { text: this.currentUser?.address1, style: 'Customer' },
                      ],
                    },
                  ],
                ],
              },
              {
                columns: [
                  [
                    {
                      text: [
                        { text: this.currentUser?.address2, style: 'Customer' },
                      ],
                    },
                  ],
                ],
              },
              {
                columns: [
                  [{ text: country, style: 'Customer', margin: [0, 3, 0, 10] }],
                ],
              },
              {
                columns: [
                  [
                    this.hideColumn
                      ? {}
                      : {
                        text: ' Order No: ' + order.id,
                        style: 'OrderRight1',
                        margin: this.currentUser?.address2 ?  [0, -67, 0, 10] : [0, -55, 0, 10],
                      },
                  ],
                ],
              },
              order.orderdetail?.length === 0 && order.mlTasks?.length > 0 && order.receiptNoIS
                ? {
                  columns: [
                    [
                      {
                        text:
                          ' Receipt No: ' +
                          order.receiptNoIS,
                        style: 'OrderRight1',
                        margin: [0, -67, 0, 10],
                      },
                    ],
                  ],
                }
                : {},

              order.orderdetail?.length > 0
                ? {
                  style: 'tableExample',
                  table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [...exs],
                  },
                }
                : {},

              // here
              order.mlTasks?.length > 0
                ? {
                  margin: [0, 25, 0, 0],
                  text: feedType,
                  fontSize: 12,
                  bold: true,
                  alignment: 'left',
                  color: '#000',
                }
                : {},
              order.mlTasks?.length > 0
                ? {
                  style: 'tableExample',
                  table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [...catData],
                  },
                }
                : {},
                //Value added array
                valueAddedProducts?.length > 0
                ? {
                  margin: [0, 25, 0, 0],
                  text: 'Value Added Data Products',
                  fontSize: 12,
                  bold: true,
                  alignment: 'left',
                  color: '#000',
                }
                : {},
                valueAddedProducts.length > 0
                ? {
                  style: 'tableExample',
                  table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [...demData],
                  },
                }
                : {},

              {
                columns: [
                  [
                    (this.hideColumn && (order.refundAmountWithoutGST === null || order.refundAmountWithoutGST === undefined) && (order.refundPointWithoutGST === null || order.refundPointWithoutGST === undefined)) || this.currentUser?.allocated
                      ? {}
                      : {
                        text: this.currentUser?.pointsEligible
                        ? `Subtotal: ${this.hideColumn ? order?.refundPointWithoutGST : this.convertCostToPoints(subTotal)} Points`
                      : `Subtotal: ${this.dollarUS.format(this.hideColumn ? order?.refundAmountWithoutGST : subTotal)}`,
                        style: 'OrderRight1',
                      },
                  ],
                ],
              },
              {
                columns: [
                  [
                    (this.hideColumn && (order.refundAmountWithoutGST === null || order.refundAmountWithoutGST === undefined) && (order.refundPointWithoutGST === null || order.refundPointWithoutGST === undefined)) || this.currentUser?.allocated
                      ? {}
                      : {
                        text:
                          this.taxType + ` (${this.gstpercent}%): ` + 
                          (this.currentUser?.pointsEligible ? 
                          `${this.totalGST} Points`
                          : this.dollarUS.format(this.totalGST)),
                        style: 'OrderRight1',
                      },
                  ],
                ],
              },
              {
                columns: [
                  [
                    {
                      text:  this.currentUser?.allocated ? this.hideColumn ? `Task Total: ${order.refundTaskNumber}` :  `Task Total: ${totalTaskNumber}`  : this.currentUser?.pointsEligible
                      ? `Total: ${this.hideColumn ? order?.refundPoint : this.convertCostToPoints(grandTotal)} Points`
                      : `Total: ${this.dollarUS.format(this.hideColumn ? order?.refundAmount : grandTotal)}`,
                      style: 'OrderRight1',
                    },
                  ],
                ],
              },
              {
                columns: [
                  [
                    !this.receipt
                    ? {}
                    :
                    {
                      text: this.currentUser?.pointsEligible
                      ? `${order?.paymentMethod} Charge: ${this.onlineCharge} Points`
                      : `${order?.paymentMethod} Charge: ${this.dollarUS.format(this.onlineCharge)}`,
                      style: 'OrderRight1',
                    },
                  ],
                ],
              },
              {
                columns: [
                  [
                    !this.receipt
                    ? {}
                    :
                    {
                      text: this.currentUser?.pointsEligible
                      ? `Grand Total: ${this.convertCostToPoints(this.grandTotal)} Points`
                      : `Grand Total: ${this.dollarUS.format(this.grandTotal)}`,
                      style: 'OrderRight1',
                    },
                  ],
                ],
              },
            ],
            styles: {
              CompanyAddress: {
                fontSize: 12,
                bold: false,
                alignment: 'left',
                color: '#000',
                margin: [0, 3, 0, 0],
              },
              OrderRight: {
                fontSize: 12,
                bold: false,
                alignment: 'right',
                color: '#000',
                margin: [0, 50, 0, 0],
              },
              CustomerLeft: {
                fontSize: 12,
                bold: false,
                alignment: 'left',
                color: '#000',
                margin: [0, 50, 0, 0],
              },
              OrderRight1: {
                fontSize: 12,
                bold: false,
                alignment: 'right',
                color: '#000',
                margin: [10, 8, 0, 0],
              },
              CustomerLeft1: {
                fontSize: 12,
                bold: false,
                alignment: 'left',
                color: '#000',
                margin: [0, 5, 0, 0],
              },
              CreditAlign: {
                fontSize: 12,
                bold: false,
                alignment: 'center',
                color: '#000',
                margin: [0, 5, 0, 0],
              },
              Notes: {
                fontSize: 18,
                bold: true,
                alignment: 'left',
                color: '#000',
                margin: [0, 5, 0, 0],
              },
              BottomPrice: {
                fontSize: 13,
                bold: false,
                alignment: 'left',
                color: '#000',
                margin: [0, 5, 0, 0],
              },
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              tableExample: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                marginTop: 6,
                marginBottom: 6,
                color: 'black',
              },
              contents: {
                marginTop: 6,
                marginBottom: 6,
              },
              Customer: {
                fontSize: 12,
                bold: false,
                alignment: 'left',
                color: '#000',
              },
            },
          };

          if (this.isInIframe) {
            delete docDefinition.header;
            pdfMake.createPdf(docDefinition).download(receiptNo + '.pdf');
          } else {
            pdfMake.createPdf(docDefinition).open();
          }
          castUser.unsubscribe();
        });
    }
  }
  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  convertCostToPoints(cost:number)
  {
      const parts = this.currentUser.sensorConversionFactor.split(':');
      const conversionFactor =  parseFloat(parts[1]) / parseFloat(parts[0]);
      const points =  Math.round(cost * conversionFactor);      
      return points;
  }

  

  exportToCSV() {
    this.orderReport = [];
    for (const [idx, orders] of this.filteredData.entries()) {
      this.orderReport.push(orders);
    }

    const fileName = 'order report.csv';
    const columnNames = this.currentUser?.allocated ? 
    ['Item No.', 'Order Id', 'Order/Credit Note Date', 'Description', 'Task Number', 'Order Status']
    : ['Item No.', 'Order Id', 'Order/Credit Note Date', 'Description', 'Amount', 'Order Status'];
    const header = columnNames.join(',');
  
    let csv = header + '\r\n';
    this.userSevice
    .personalInfo(this.currentUser?.id)
    .subscribe(async (res: any) => {
      this.currentUser = res;
      

    this.orderReport.forEach((order, index) => {
      const reportType = order.payoption === 'Pay later' ? 'Invoice' : order.refundCheck ? 'Credit Note' : 'Receipt';
      const status = order.orderstatus.charAt(0).toUpperCase() + order.orderstatus.slice(1);
      const orderAmount = this.dollarUS.format(order?.refundAmount ? order.refundAmount : order.final_invoice_amount ? order.final_invoice_amount : order.orderamount);
      const orderPoint = order?.refundPoint ? order.refundPoint : order.final_invoice_points ? order.final_invoice_points : order.orderPoint;
      const taskNumber = order?.refundTaskNumber ? order.refundTaskNumber : order?.totalTaskNumber;
      const amount = this.currentUser?.pointsEligible ? `${orderPoint} Points` : orderAmount;
  
  
      // Enclose values in double quotes to avoid issues with commas in fields
      const row = [
        `"${index + 1}"`,
        `"${order.id}"`,
        `"${this.datepipe.transform(new Date(order.createdAt).toDateString(), 'dd/MM/yyyy')}"`,
        `"${reportType}"`,
         this.currentUser?.allocated ? `"${taskNumber}"`  : `"${amount}"`,
        `"${status}"`
      ].join(',');
  
      csv += row + '\r\n';
    });
  
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
  }
  
  calculateGST(order,user) {
    let onlineFees = 0;
    let currentUser = user;
    
    if (order?.paymentMethod === 'PayPal') onlineFees = PAYPAL_FEE;
    else if (order?.paymentMethod === 'Stripe') onlineFees = STRIPE_FEE;

    let gstAmount = this.utilsService.calculateGST(order.orderamount,currentUser);
    this.totalGST = (order.grand_gst !== null && order.grand_gst !== undefined) ? order.grand_gst :
    this.currentUser?.country != 'Singapore' ? 0 :
    currentUser?.pointsEligible ? 
    this.utilsService.convertCostToPoints(gstAmount,currentUser.sensorConversionFactor)
    : gstAmount;

    let charges = this.utilsService.calculateAdditionalCharges((order.orderamount + this.totalGST),onlineFees);

    this.onlineCharge = order.transactionCharge ? order.transactionCharge : currentUser?.pointsEligible ? 
    this.utilsService.convertCostToPoints(charges,currentUser.sensorConversionFactor)
    : charges;

    this.grandTotal = currentUser?.pointsEligible ? 
    (order.final_invoice_points ? order.final_invoice_points : this.utilsService.convertCostToPoints((+order.orderamount + +this.totalGST + +this.onlineCharge),currentUser.sensorConversionFactor))
    : (order.final_invoice_amount ? order.final_invoice_amount : (+order.orderamount + +this.totalGST + +this.onlineCharge))
  }
}
