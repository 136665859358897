import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { TaskService } from "./task.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status)) {
                let path = ['login','verifyUser','findUser','register','forgot','resetPassword','addfb','addRequestDemo','verifyEmail','changePhoto']
                const isPattPresent = path.some(item => request.url.includes(item));
                this.authService.logout();
                if (!isPattPresent) {
                    this.router.navigate(['login']).then(() => {
                        window.location.reload(); // Reload after navigation
                    });
                }
            }
            return throwError(() => err);
        }))
    }
}